import React from "react";
import { connect } from "react-redux";
import {
  Button,
  Label,
  Nav,
  NavItem,
  NavLink,
  Input,
  UncontrolledTooltip,
} from "reactstrap";
import {
  getClientConnectByCompany,
  getClientTeamMembers,
  editClientTeamMemberStatus,
  companyGetAssistantTeams,
  companyDeleteAssistantTeam,
  companyResendLoginInvitation,
} from "../../../http/http-calls";
import {
  errorHandler,
  deepClone,
  formatDateHideYearIfSame,
  showToast,
  getDropdownColor,
  getFullName,
} from "../../../helper-methods";
import { showLoader, hideLoader } from "../../../redux/actions/loader-data";

import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import AddClientinSigningCompanyModal from "../components/addClient-insigningCompany-modal";
import ScreenWidthHOC from "./ScreenWidthHOC";
import AddEditTeamMembers from "../components/Modals/Company/AddEditTeamMembers";
import Swal from "sweetalert2";
import ClientDetailsTabComponent from "../components/ClientDetailsTabComponent";
import SvgIcons from "../components/SvgIcons";
import CustomPagination from "../components/CustomPagination";

class ClientDetails extends React.Component {
  state = {
    client: null,
    teamMembers: {
      data: [],
      totalCount: 0,
    },
    assistantTeams: [],
    isEditable: false,
    activeTab: "clientDetailsTab",
    loading: {
      requestLoading: false,
    },
    clientMembersCardHeaderKeys: [
      { id: "id", label: "id" },
      { id: "name", label: "Name" },
    ],
    addUserModal: {
      isOpen: false,
      data: null,
    },
    teamMemberModal: {
      isOpen: false,
      data: null,
    },
    isOpenCollapse: "",
    tableConfig: {
      skip: 0,
      limit: 10,
      pageNumber: 1,
    },
    filters: {
      search: "",
    },
  };

  _manageLoading = (key, value) => {
    const { loading } = this.state;
    loading[key] = value;
    this.setState({ loading });
  };

  _handleCollapse = (key) => {
    let { isOpenCollapse } = deepClone(this.state);

    isOpenCollapse = isOpenCollapse === key ? "" : key;

    this.setState({
      isOpenCollapse,
    });
  };

  _getClientConnectByCompany = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const { id } = this.props.match.params;

        const res = await getClientConnectByCompany({ clientId: id });

        this.setState({ client: res?.client || {} });
        this._getClientTeamMembers(this.state.tableConfig);
        resolve(res?.client || {});
      } catch (error) {
        reject(error);
      }
    });
  };

  _getClientTeamMembers = async (tableConfig) => {
    try {
      const { id } = this.props.match.params;
      const { filters } = deepClone(this.state);

      const payload = {
        ...tableConfig,
        clientId: id,
      };

      if (filters?.search) {
        payload["search"] = filters?.search;
      }

      const res = await getClientTeamMembers(payload);
      this.setState({
        teamMembers: {
          data: res?.users || [],
          totalCount: res?.totalCount,
        },
        // isEditable: res?.client?.isEditable || false,
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  _getCompanyAssistantTeams = async () => {
    try {
      const { id } = this.props.match.params;
      const res = await companyGetAssistantTeams({
        clientId: id,
      });

      this.setState({ assistantTeams: res?.assistantTeams || [] });
    } catch (err) {
      errorHandler(err);
    }
  };

  componentDidMount = async () => {
    try {
      this.props.showLoader("Loading...");

      await this._getClientConnectByCompany();
      await this._getCompanyAssistantTeams();

      this.props.hideLoader();
    } catch (error) {
      this.props.hideLoader();
      errorHandler(error);
      this.props.history.goBack();
    }
    HeaderEventEmitter.subscribe("back-button", () => {
      this.props.history.goBack();
    });
  };

  _toggle = (tab) => {
    const { activeTab } = deepClone(this.state);

    if (activeTab !== tab) {
      this.setState({ activeTab: tab });
    }

    if (tab === "clientDetailsTab") {
      this._getCompanyAssistantTeams();
    }
  };

  _paginate = (pageNumber = 1, pageSize = 10) => {
    const { tableConfig } = deepClone(this.state);

    tableConfig["skip"] = (pageNumber - 1) * pageSize;
    tableConfig["limit"] = pageSize;
    tableConfig["pageNumber"] = pageNumber;

    this.setState({ tableConfig });

    this._getClientTeamMembers(tableConfig);
  };

  _onToggleAddUserModal = (bool, data) => {
    let { addUserModal } = JSON.parse(JSON.stringify(this.state));
    addUserModal.isOpen = !addUserModal.isOpen;
    addUserModal.data = data;
    this.setState({
      addUserModal,
    });
  };

  _onToggleTeamMemberModal = (isOpen = false, data = null) => {
    let { teamMemberModal } = deepClone(this.state);

    // if (client?.connectStatus !== "accepted") {
    //   errorHandler({
    //     reason: "Please connect with the client to add new team",
    //   });
    //   return;
    // }

    teamMemberModal.isOpen = isOpen;
    teamMemberModal.data = data;

    this.setState({
      teamMemberModal,
    });
  };

  _calculatePermissionCount = (permissionSettings) => {
    if (!permissionSettings) return 0;

    return Object.values(permissionSettings)
      .flatMap(Object.values)
      .reduce(
        (count, permission) => (permission === true ? count + 1 : count),
        0
      );
  };

  _userStatusUpdate = (data) => {
    this._manageLoading("requestLoading", true);

    editClientTeamMemberStatus(data.id, {
      clientId: this.state.client?._id,
    })
      .then((res) => {
        showToast("Status has been updated", "success");
        const { teamMembers } = deepClone(this.state);
        const user = teamMembers?.data?.find((user) => user.id === data.id);
        user["isActive"] = res?.user?.isActive || false;
        this.setState({ teamMembers });
        this._manageLoading("requestLoading", false);
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("requestLoading", false);
      });
  };

  _cardHeaderFormat = (cell, row, header) => {
    switch (header) {
      case "name": {
        return row ? (
          <>
            <div className="tableUserInfo">
              {row?.name ? getFullName(row?.name) : "N/A"}
            </div>

            <Button
              onClick={() => this._onToggleAddUserModal(true, row)}
              color="link"
              // disabled={!this.state.isEditable}
            >
              <SvgIcons type={"pencil"} />
            </Button>
            <Button
              color="link"
              className="remove resendInvitation"
              onClick={() => this._resendClientInvitation(row)}
              id="email_enevlope_open_card"
            >
              <SvgIcons type={"resendEmail"} />
              {/* Resend Invitation */}
            </Button>
            <UncontrolledTooltip
              placement="bottom"
              target="email_enevlope_open_card"
            >
              Resend invitation
            </UncontrolledTooltip>
          </>
        ) : (
          "N/A"
        );
      }
      default: {
        return cell;
      }
    }
  };

  _cardDataFormat = (row) => {
    return (
      <ul className="cardInfo">
        <li>
          <div className="dataWrap">
            <Label>Email</Label>
            <div style={{ fontWeight: 600 }}>
              {row?.email ? (
                <a href={`mailto:${row?.email}`}>{row?.email}</a>
              ) : null}
            </div>
          </div>
          <div className="dataWrap">
            <Label>Phone</Label>
            <div style={{ fontWeight: 600 }}>{row?.phone}</div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Permission</Label>
            <div style={{ fontWeight: 600 }}>
              {row?.permissionSettings
                ? this._calculatePermissionCount(row?.permissionSettings)
                : 0}
              /6
            </div>
          </div>
          <div className="dataWrap">
            <Label>Added By</Label>
            <div style={{ fontWeight: 600 }}>
              {" "}
              {row?._createdBy || row?._createdByClient
                ? row?._createdBy?.companyName ||
                  row?._createdByClient?.name?.full
                : "N/A"}
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Last Login</Label>
            <div style={{ fontWeight: 600 }}>
              {row?.lastLogin
                ? formatDateHideYearIfSame(row?.lastLogin)
                : "Not Logged In Yet!"}
            </div>
          </div>
          <div className="dataWrap">
            <Label>Status</Label>
            <div style={{ fontWeight: 600 }}>
              <div className="customSelectWrap" style={{ width: 100 }}>
                <Input
                  type="select"
                  name="status"
                  className={`status ${getDropdownColor(
                    row?.isActive ? "active" : "inactive"
                  )}`}
                  disabled={
                    this.state.loading.requestLoading
                    // || !this.state.isEditable
                  }
                  value={row?.isActive ? "active" : "inactive"}
                  onChange={(event) => this._userStatusUpdate(row)}
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </Input>
                <div className="downArrow">
                  <i className="fa fa-chevron-down"></i>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    );
  };

  _deleteCompanyAssistantTeamAlert = (assistantTeamId, index) => {
    if (!assistantTeamId) {
      errorHandler();
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete this team.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          clientId: this.state.client?.id,
        };
        this._deleteCompanyAssistantTeam(assistantTeamId, payload, index);
      }
    });
  };

  _deleteCompanyAssistantTeam = async (assistantTeamId, payload, index) => {
    const { assistantTeams } = deepClone(this.state);
    let deletedItem;
    try {
      deletedItem = assistantTeams.splice(index, 1); // deleting item beforehand, for better ux

      this.setState({ assistantTeams });

      await companyDeleteAssistantTeam(assistantTeamId, payload);
    } catch (err) {
      assistantTeams.splice(index, 0, deletedItem[0]);
      this.setState({ assistantTeams }); // setting deleted item again, if api fails
      errorHandler(err);
    }
  };

  _resendClientInvitation = async (teamMember) => {
    console.log("team,", teamMember);
    try {
      const { id } = this.props.match.params;

      const payload = { clientId: id, userId: teamMember._id };

      await companyResendLoginInvitation(payload);
      showToast("Invitation sent successfully", "success");
    } catch (err) {
      errorHandler(err);
    }
  };

  _filterOnChange = (type, value, isDelay = false) => {
    try {
      if (isDelay) clearTimeout(this.searchTimer);

      const { filters } = this.state;
      filters[type] = value;

      this.setState({ filters }, () => {
        if (isDelay) {
          this.searchTimer = setTimeout(() => {
            this._paginate(1, 10);
          }, 1000);
        } else {
          this._paginate(1, 10);
        }
      });
    } catch (err) {
      errorHandler(err);
    }
  };
  _handlePageChange = async (newPageNumber) => {
    this.setState(
      (prevState) => ({
        tableConfig: {
          ...prevState.tableConfig,
          pageNumber: newPageNumber,
        },
      }),
      () => {
        // Trigger data fetching based on new page number
        this._paginate(
          this.state.tableConfig.pageNumber,
          this.state.tableConfig.limit
        );
      }
    );
  };

  render() {
    const {
      activeTab,
      client,
      loading,
      addUserModal,
      teamMembers,
      clientMembersCardHeaderKeys,
      teamMemberModal,
      assistantTeams,
    } = this.state;

    const { isForMobile } = this.props;
    return (
      <>
        <div className="content">
          <div className="responsiveTitle">
            <div className="d-flex align-items-center">
              <Button
                color="link"
                className="backBtn"
                onClick={() => this.props.history.goBack()}
              >
                <img
                  src={require("../../../assets/img/arrowLeft.svg").default}
                  alt="backbutton"
                  height={14}
                />
              </Button>
              <h2>Client Details</h2>
            </div>
          </div>
          <div className="verticalTabs">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={`${
                    activeTab === "clientDetailsTab" ? "active" : ""
                  }`}
                  onClick={() => {
                    this._toggle("clientDetailsTab");
                  }}
                >
                  <img
                    src={require(`../../../assets/img/${
                      activeTab === "clientDetailsTab"
                        ? "basicdetails-blue"
                        : "basicdetails-black"
                    }.svg`)}
                    alt="basic details"
                  />
                  Client Details
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={() => {
                    this._toggle("ManageMember");
                  }}
                  className={`${activeTab === "ManageMember" ? "active" : ""}`}
                >
                  <img
                    src={require(`../../../assets/img/${
                      activeTab === "ManageMember"
                        ? "basicdetails-blue"
                        : "basicdetails-black"
                    }.svg`)}
                    alt="basic details"
                  />
                  Manage Member
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  onClick={() => {
                    this._toggle("customOrder");
                  }}
                  className={`${activeTab === "customOrder" ? "active" : ""}`}
                >
                  <img
                    src={require(`../../../assets/img/${
                      activeTab === "customOrder"
                        ? "basicdetails-blue"
                        : "basicdetails-black"
                    }.svg`)}
                    alt="basic details"
                  />
                  Custom Order Detail
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  onClick={() => {
                    this._toggle("CustomProduct");
                  }}
                  className={`${activeTab === "CustomProduct" ? "active" : ""}`}
                >
                  <img
                    src={require(`../../../assets/img/${
                      activeTab === "CustomProduct"
                        ? "basicdetails-blue"
                        : "basicdetails-black"
                    }.svg`)}
                    alt="basic details"
                  />
                  Custom Product Visibility
                </NavLink>
              </NavItem>
            </Nav>

            <ClientDetailsTabComponent
              activeTab={activeTab}
              client={client}
              loading={loading}
              reduxLoading={this?.props?.loadingData?.isVisible}
              assistantTeams={assistantTeams}
              isForMobile={isForMobile}
              teamMembers={teamMembers}
              clientMembersCardHeaderKeys={clientMembersCardHeaderKeys}
              _onToggleTeamMemberModal={this._onToggleTeamMemberModal}
              isOpenCollapse={this.state.isOpenCollapse}
              _handleCollapse={this._handleCollapse}
              _deleteCompanyAssistantTeamAlert={
                this._deleteCompanyAssistantTeamAlert
              }
              _onToggleAddUserModal={this._onToggleAddUserModal}
              _calculatePermissionCount={this._calculatePermissionCount}
              _userStatusUpdate={this._userStatusUpdate}
              _cardHeaderFormat={this._cardHeaderFormat}
              _cardDataFormat={this._cardDataFormat}
              _getClientConnectByCompany={this._getClientConnectByCompany}
              _resendClientInvitation={this._resendClientInvitation}
              _filterOnChange={this._filterOnChange}
              filters={this.state.filters}
              tableConfig={this.state.tableConfig}
              _handlePageChange={this._handlePageChange}
            ></ClientDetailsTabComponent>

            {addUserModal.isOpen ? (
              <AddClientinSigningCompanyModal
                isOpen={addUserModal.isOpen}
                data={addUserModal.data}
                resetTable={this._getClientTeamMembers}
                toggle={() => this._onToggleAddUserModal()}
                id={client?._id}
              />
            ) : null}
          </div>
        </div>

        {teamMemberModal?.isOpen && (
          <AddEditTeamMembers
            isOpen={teamMemberModal?.isOpen}
            data={teamMemberModal?.data}
            onSuccess={this._getCompanyAssistantTeams}
            toggle={() => this._onToggleTeamMemberModal()}
            clientId={client?._id}
            type="signingcompany"
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
    loadingData: state.loaderData ? state.loaderData : {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScreenWidthHOC(ClientDetails));
