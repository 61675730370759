import React, { useEffect, useState } from "react";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { RegexConfig } from "../../../../../config/RegexConfig";
import {
  errorHandler,
  getFullName,
  showToast,
} from "../../../../../helper-methods";
import {
  clientAddAssistantTeams,
  clientEditAssistantTeams,
  companyAddAssistantTeams,
  companyEditAssistantTeams,
  getAllListOfClientsAssistant,
  getAllListOfClientsAssistantClient,
} from "../../../../../http/http-calls";
import TeamMemberPermissions from "../../TeamMemberPermissions";
import TeamOrCCMembers from "../../TeamOrCCMembers";

const initialTeam = {
  teamName: "",
  teamId: "",
  members: [
    {
      type: "members",
      _user: "",
      memberObj: null,
      permissions: {
        noEmails: false,
        allEmails: false,
        chatLogEmails: false,
        statusUpdates: false,
        invoiceEmails: false,
        appointmentConfirmation: false,
        agentAssigned: false,
      },
      isDirty: {
        _user: false,
        permissions: false,
      },
      errors: {},
    },
  ],
};

const initialIsDirty = {
  teamName: false,
};

const AddEditTeamMembers = ({
  isOpen,
  data,
  onSuccess,
  toggle,
  clientId = "",
  type,
}) => {
  const [team, setTeam] = useState(JSON.parse(JSON.stringify(initialTeam))); // bcoz initalTeam is getting updated unexpectedly
  const [isDirty, setIsDirty] = useState(initialIsDirty);
  const [errors, setErrors] = useState({});
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [dropdownOptionsOriginal, setDropdownOptionsOriginal] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [loading, setLoading] = useState({
    submitLoading: false,
  });
  const [isFormDirty, setIsFormDirty] = useState(false);

  const _manageLoading = (key = "", value = false) => {
    setLoading((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const _resetState = () => {
    setTeam(initialTeam);
    setIsDirty(initialIsDirty);
    setErrors({});
    setSelectedMembers([]);
  };

  const _setForm = (data) => {
    if (!data) return;

    const newTeam = { ...team };
    newTeam["teamName"] = data?.teamName;
    newTeam["teamId"] = data?.uniqueId;

    const teamMember = [];

    const newSelectedMembers = [];

    data?.teamMembers?.forEach((each) => {
      const member = {};
      if (each?._user) {
        newSelectedMembers.push(each?._user?._id); // setting selected members id, to filter them out in the dropdown
        member["type"] = "members";
        member["_user"] = each?._user?._id;
        member["ccName"] = each?._user?.name?.full;
        member["ccEmail"] = each?.email;

        // memberObj
        // if (dropdownOptions?.length) {
        //   const memberObjFind = dropdownOptions?.find(
        //     (option) => option?._id === each?._user
        //   );

        //   const memberObj = {};

        //   memberObj["label"] = getFullName(memberObjFind?.name) || "";
        //   memberObj["value"] = memberObjFind || null;

        //   member["memberObj"] = memberObj;
        // }

        const memberObj = {};

        memberObj["label"] = getFullName(each?._user?.name) || "";
        memberObj["value"] = each?._user || null;

        member["memberObj"] = memberObj;
      } else {
        member["type"] = "ccMembers";
        member["ccName"] = each?.name?.full;
        member["ccEmail"] = each?.email;
      }

      member["permissions"] = {
        noEmails: each?.roles?.includes(1),
        allEmails: each?.roles?.includes(2),
        chatLogEmails: each?.roles?.includes(3),
        statusUpdates: each?.roles?.includes(4),
        invoiceEmails: each?.roles?.includes(5),
        appointmentConfirmation: each?.roles?.includes(6),
        agentAssigned: each?.roles?.includes(7),
      };

      member["isDirty"] = { _user: false, permissions: false };
      member["errors"] = {};

      teamMember.push(member);
    });

    newTeam["members"] = teamMember;

    setTeam(newTeam);
    setSelectedMembers(newSelectedMembers);
  };

  const _onChangeFormField = (field, value) => {
    const newTeam = { ...team };

    newTeam[field] = value;

    setTeam(newTeam);
    setIsFormDirty(true);
  };

  const _onBlurFormFields = (field, type = "", index = 0) => {
    const newTeam = { ...team };
    const newIsDirty = { ...isDirty };

    if (type === "members") {
      newTeam["members"][index]["isDirty"][field] = true;
      _validateMembers({ newTeam });
    } else {
      newIsDirty[field] = true;
      _validateFormfields({ newTeam, newIsDirty });
    }

    setIsFormDirty(true);
  };

  const _validateFormfields = ({ newTeam, newIsDirty }) => {
    return new Promise((resolve) => {
      let isFormValid = true;
      const newErrors = {};

      Object.keys(newTeam)?.forEach((key) => {
        if (newIsDirty[key]) {
          if (key === "teamName") {
            if (!newTeam?.teamName?.trim()?.length) {
              newErrors[key] = "*Team name is Required";
              isFormValid = false;
            } else if (
              newTeam?.teamName?.trim()?.length < 2 ||
              newTeam?.teamName?.trim()?.length > 50
            ) {
              newErrors[key] = "*Name should be of 2 to 50 characters ";
              isFormValid = false;
            } else {
              newErrors[key] = null;
              isDirty.teamName = false;
            }
          }
        }
      });

      setIsDirty((prev) => ({
        ...prev,
        ...newIsDirty,
      }));

      setErrors((prev) => ({
        ...prev,
        ...newErrors,
      }));

      resolve(isFormValid);
    });
  };

  const _validateMembers = ({ newTeam }) => {
    return new Promise((resolve) => {
      newTeam?.members?.forEach((each) => {
        if (each?.isDirty?._user && each?.type === "members") {
          if (!each?._user?.trim()?.length) {
            each.errors._user = "*Member is Required";
          } else {
            delete each.errors._user;
            each.isDirty._user = false;
          }
        }
        if (each?.isDirty?.ccName && each?.type === "ccMembers") {
          if (!each?.ccName?.trim()?.length) {
            each.errors.ccName = "*Name is Required";
          } else if (
            each?.ccName?.trim()?.length < 2 ||
            each?.ccName?.trim()?.length > 50
          ) {
            each.errors.ccName = "*Name should be of 2 to 50 characters";
          } else {
            delete each.errors.ccName;
            each.isDirty.ccName = false;
          }
        }
        if (each?.isDirty?.ccEmail && each?.type === "ccMembers") {
          if (!each?.ccEmail?.trim()?.length) {
            each.errors.ccEmail = "*Email is Required";
          } else if (
            each?.ccEmail?.trim()?.length &&
            !new RegExp(RegexConfig.email).test(each?.ccEmail)
          ) {
            each.errors.ccEmail = "*Please enter valid Email";
          } else if (
            each?.ccEmail?.trim()?.length &&
            !_checkForUniqueEmail(each?.ccEmail)
          ) {
            // errorHandler({ reason: "Email already used by another member" });
            each.errors.ccEmail = "*Team member found by this email";
          } else {
            delete each.errors.ccEmail;
            each.isDirty.ccEmail = false;
          }
        }
        if (each?.isDirty?.permissions) {
          if (
            Object.keys(each?.permissions)?.filter(
              (subEach) => each?.permissions[subEach] === true
            )?.length < 1
          ) {
            each.errors.permissions =
              "*At least 1 email permission is Required";
          } else {
            delete each.errors.permissions;
            each.isDirty.permissions = false;
          }
        }
      });

      setTeam(newTeam);

      let membersWithErrors = newTeam?.members?.filter((each) => {
        return Object.keys(each?.errors)?.length;
      });

      resolve(membersWithErrors?.length ? false : true);
    });
  };

  const _checkForUniqueEmail = (email) => {
    let isUnique = true;

    const sameMemberEmailFound = dropdownOptionsOriginal.find(
      (each) => each?.email === email
    );
    // const sameCCEmailFoundIndex = team?.members?.findIndex(
    //   (each) => each?.ccEmail === email
    // );

    if (sameMemberEmailFound) {
      isUnique = false;
    }

    return isUnique;
  };

  const _onChangeTeamMemberOrCCMember = (
    field,
    value,
    // type,
    index,
    subType = ""
  ) => {
    // console.log({ field }, { value }, { index }, { subType });
    const newTeam = { ...team };

    if (subType?.length) {
      // for permissions
      if (field === "noEmails" || field === "allEmails") {
        newTeam.members[index]["permissions"] = {
          noEmails: false,
          allEmails: false,
          chatLogEmails: false,
          statusUpdates: false,
          invoiceEmails: false,
          appointmentConfirmation: false,
          agentAssigned: false,
        };
      }
      // newTeam[type][index][subType][field] = value;
      newTeam["members"][index][subType][field] = value;
    } else {
      // newTeam[type][index][field] = value;
      if (field === "_user") {
        const newSelectedMembers = [...selectedMembers];

        const prevVal = newTeam?.members[index]["_user"];

        const indx = newSelectedMembers?.findIndex((each) => each === prevVal);

        if (indx > -1) {
          newSelectedMembers.splice(indx, 1);
        }

        newTeam["members"][index][field] = value?.value?._id;
        newTeam["members"][index]["ccName"] = getFullName(value?.value?.name);
        newTeam["members"][index]["ccEmail"] = value?.value?.email;
        newTeam["members"][index]["memberObj"] = value;

        if (value?.value?._id) {
          // if id exists, then only add it to arr
          newSelectedMembers.push(value?.value?._id);
        }

        setSelectedMembers(newSelectedMembers); // storing ids
      } else {
        newTeam["members"][index][field] = value;
      }
    }

    setTeam(newTeam);
    setIsFormDirty(true);
  };

  const _addTeamMember = (type = "members") => {
    const currLastTeamMemberIndex = team?.members?.length - 1;

    if (
      currLastTeamMemberIndex > -1 &&
      !team?.members[currLastTeamMemberIndex]?._user &&
      (!team?.members[currLastTeamMemberIndex]?.ccEmail ||
        !team?.members[currLastTeamMemberIndex]?.ccName)
    ) {
      const newTeam = { ...team };

      newTeam.members[currLastTeamMemberIndex].isDirty = {
        _user: true,
        ccName: true,
        ccEmail: true,
      };

      _validateMembers({ newTeam });

      // scrolling to bottom
      const modalBody = document.querySelector("#modalBody");
      modalBody.scrollTo(0, modalBody.scrollHeight);
      // errorHandler({ reason: "Please enter previous member details first" });
      return;
    }

    const newTeam = { ...team };

    const newMember = {
      type: type,
      ccName: "",
      ccEmail: "",
      permissions: {
        noEmails: false,
        allEmails: false,
        chatLogEmails: false,
        statusUpdates: false,
        invoiceEmails: false,
        appointmentConfirmation: false,
        agentAssigned: false,
      },
      isDirty: {
        ccName: false,
        ccEmail: false,
        permissions: false,
      },
      errors: {},
    };

    if (type === "members") {
      newMember["_user"] = "";
      newMember["memberObj"] = null;
      newMember["isDirty"]["_user"] = false;
    }

    newTeam?.members?.push(newMember);

    setTeam(newTeam);
    setIsFormDirty(true);
  };

  const _removeTeamMember = (index) => {
    const newTeam = { ...team };
    const newSelectedMembers = [...selectedMembers];

    const prevVal = newTeam?.members[index]["_user"];

    const indx = newSelectedMembers?.findIndex((each) => each === prevVal);

    if (indx > -1) {
      newSelectedMembers.splice(indx, 1);
    }

    newTeam?.members.splice(index, 1);

    setTeam(newTeam);
    setSelectedMembers(newSelectedMembers); // storing ids
    setIsFormDirty(true);
  };

  useEffect(() => {
    const newDropdownOptions = dropdownOptionsOriginal?.filter(
      (each) => !selectedMembers.includes(each?._id)
    );

    setDropdownOptions(newDropdownOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMembers]);

  useEffect(() => {
    if (data || dropdownOptionsOriginal?.length) {
      // dropdownOptions should be present as we need it to prefill selectedMembers array
      _setForm(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dropdownOptionsOriginal]);

  const _getListOfAllClientAssistants = async (clientId) => {
    const res = await getAllListOfClientsAssistant(clientId);
    setDropdownOptions(res?.users);
    setDropdownOptionsOriginal(res?.users);
  };

  const _getAllListOfClientsAssistantClient = async () => {
    const res = await getAllListOfClientsAssistantClient();
    setDropdownOptions(res?.users);
    setDropdownOptionsOriginal(res?.users);
  };

  useEffect(() => {
    if (clientId) {
      if (type === "signingcompany") {
        _getListOfAllClientAssistants(clientId);
      } else if (type === "client") {
        _getAllListOfClientsAssistantClient();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  const _closeModal = () => {
    _resetState();
    toggle();
  };

  const _createPayload = (data) => {
    return new Promise((resolve) => {
      console.log("data", data);
      const payload = {
        clientId: clientId,
        teamName: data?.teamName,
        uniqueId: data?.teamId,
        // teamMembers: [],
      };

      const teamMembers = [];

      data?.members?.forEach((each) => {
        const member = {};

        if (each?.type === "members") {
          member["_user"] = each?._user;
        }
        const [first, last] =
          each?.ccName !== undefined ? each?.ccName?.split(" ") : "";

        member["name"] = { first, last };
        member["email"] = each?.ccEmail;

        const permissionArr = [];
        Object.keys(each?.permissions)?.forEach((subEach) =>
          subEach === "noEmails" && each?.permissions[subEach]
            ? permissionArr.push(1)
            : subEach === "allEmails" && each?.permissions[subEach]
            ? permissionArr.push(2)
            : subEach === "chatLogEmails" && each?.permissions[subEach]
            ? permissionArr.push(3)
            : subEach === "statusUpdates" && each?.permissions[subEach]
            ? permissionArr.push(4)
            : subEach === "invoiceEmails" && each?.permissions[subEach]
            ? permissionArr.push(5)
            : subEach === "appointmentConfirmation" &&
              each?.permissions[subEach]
            ? permissionArr.push(6)
            : subEach === "agentAssigned" && each?.permissions[subEach]
            ? permissionArr.push(7)
            : null
        );

        member["roles"] = permissionArr;
        teamMembers.push(member);
      });

      payload["teamMembers"] = teamMembers;

      resolve(payload);
    });
  };

  const _onSubmit = async () => {
    try {
      if (!isFormDirty && team.teamId.trim().length > 0) {
        _closeModal();
        return;
      }

      _manageLoading("submitLoading", true);

      const newTeam = { ...team };
      const newIsDirty = { ...isDirty };
      newIsDirty["teamName"] = true;

      newTeam.members.forEach((each) => {
        each.isDirty = {
          _user: true,
          ccName: true,
          ccEmail: true,
          permissions: true,
        };
      });

      const isFormValid = await _validateFormfields({ newTeam, newIsDirty });
      const isMembersFormValid = await _validateMembers({ newTeam });

      if (!isFormValid || !isMembersFormValid) {
        // errorHandler({ reason: "Please enter required fields properly" });
        _manageLoading("submitLoading", false);
        return;
      }

      const payload = await _createPayload(newTeam);

      if (type === "signingcompany") {
        if (!data) {
          await companyAddAssistantTeams(payload);
        } else {
          await companyEditAssistantTeams(data?._id, payload);
        }
      } else if (type === "client") {
        if (!data) {
          await clientAddAssistantTeams(payload);
        } else {
          await clientEditAssistantTeams(data?._id, payload);
        }
      }

      onSuccess();
      _manageLoading("submitLoading", false);
      _closeModal();
      showToast(`Team ${data ? "updated" : "added"} successfully`, "success");
    } catch (err) {
      errorHandler(err);
      _manageLoading("submitLoading", false);
    }
  };

  return (
    <>
      {/* {console.log("team", team)} */}
      <Modal isOpen={isOpen} toggle={() => _closeModal()} centered scrollable>
        <ModalHeader toggle={() => _closeModal()}>
          {data ? "Edit" : "Add"} Team
        </ModalHeader>
        <ModalBody id="modalBody">
          <FormGroup className="floatingLabel">
            <Input
              placeholder=" "
              value={team?.teamName}
              onChange={(e) =>
                _onChangeFormField("teamName", e.target.value, "team")
              }
              onBlur={() => _onBlurFormFields("teamName")}
            />
            <Label>Team</Label>
            {errors?.teamName ? (
              <p className="validation-error">{errors?.teamName}</p>
            ) : null}
          </FormGroup>

          <FormGroup className="floatingLabel">
            <Input
              placeholder=" "
              value={team?.teamId}
              onChange={(e) =>
                _onChangeFormField("teamId", e.target.value, "team")
              }
              onBlur={() => _onBlurFormFields("teamId")}
            />
            <Label>Team Id</Label>
          </FormGroup>

          <div className="text-right">
            <Button
              className="readMore"
              color="link"
              onClick={() => _addTeamMember("ccMembers")}
            >
              Add CC Email
            </Button>
            <Button
              className="readMore"
              color="link"
              onClick={() => _addTeamMember("members")}
            >
              Add Member
            </Button>
          </div>

          {team?.members?.length ? (
            team?.members?.map((each, index) => (
              <>
                <div className="membersWrap">
                  <span>
                    {each?.type === "members" ? "Members" : "CC Members"}
                  </span>

                  {/* {(!data && team?.members?.length > 1) ||
                data ||
                !dropdownOptions?.length ? ( */}
                  <div>
                    <Button
                      color="link"
                      className="remove readMore"
                      onClick={() => _removeTeamMember(index)}
                    >
                      Remove
                    </Button>
                  </div>
                  {/* ) : null} */}
                </div>

                <TeamOrCCMembers
                  type={each?.type}
                  each={each}
                  index={index}
                  clientsList={dropdownOptions}
                  onChangeTeamMemberOrCCMember={_onChangeTeamMemberOrCCMember}
                  onBlurFormFields={_onBlurFormFields}
                  members={team?.members}
                />

                <TeamMemberPermissions
                  headingText="Email Permissions"
                  each={each}
                  index={index}
                  onChangeTeamMemberOrCCMember={_onChangeTeamMemberOrCCMember}
                  onBlurFormFields={_onBlurFormFields}
                />
              </>
            ))
          ) : (
            <div className="nodata">
              <img
                src={require("../../../../../assets/img/noData.svg").default}
                alt="no data"
              />
              <p>Please add team members</p>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" outline onClick={() => _closeModal()}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => _onSubmit()}
            disabled={loading?.submitLoading}
          >
            {data ? "Update" : "Add"}{" "}
            {loading?.submitLoading ? (
              <i className="fa fa-spinner fa-spin mr-2" />
            ) : null}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AddEditTeamMembers;
