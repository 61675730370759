import {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  Col,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ListGroupItem,
  ListGroup,
  CustomInput,
} from "reactstrap";
import usaStates from "../../../../config/usa_states_titlecase";
import { validateAddress } from "../../../../lib-frontend/form-validation";
import {
  googlePlaceDetails,
  googlePlaceSearch,
} from "../../../../helper-methods/googleService";
import { deepMergeObjects, showToast } from "../../../../helper-methods";
import { useSelector } from "react-redux";
import {
  InputField,
  SelectField,
} from "../../../general/components/common/FormField";

const createInitialFieldState = (
  value = "",
  isDirty = false,
  isValid = false,
  error = null,
  needToShow = true
) => ({
  value,
  isDirty,
  isValid,
  error,
  needToShow,
});

const initialObject = {
  addressLine1: createInitialFieldState(),
  addressLine2: createInitialFieldState(),
  city: createInitialFieldState(),
  county: createInitialFieldState(),
  state: createInitialFieldState(),
  zip: createInitialFieldState(),
  country: createInitialFieldState(),
  lat: createInitialFieldState("", false, true, null, false),
  lng: createInitialFieldState("", false, true, null, false),
};

// isRequired => Is we need to check the validation
// address => manual address field for auto fill
// googleAddress => google search address field for auto fill
// data => mainly used for pdf files upload data pass

const ClosingAddress = forwardRef((props, ref) => {
  const {
    fieldName,
    isManualAddressRequired,
    needDisableGoogleSearch,
    isRequired,
    googleAddress,
    placeholder,
    data,
    whichView = "Google", // Manual
    line1PlaceHolderText = null,
    line2PlaceHolderText = null,
    hideCountryInput = false, // true -> will not show Country input box
    setSeed, // used to refresh/ re-render component
    passData,
    lg = 6,
    md = 6,
    xl = 6,
    sm = 12,
    resetAddress,
  } = props;

  const { user } = useSelector((state) => state?.userData);

  const placeholders = {
    addressLine1: line1PlaceHolderText,
    addressLine2: line2PlaceHolderText,
    zip: "Zip",
    state: "State",
    city: "City",
    county: "County",
    country: "Country",
  };

  const [initialValues, setInitialValues] = useState(
    JSON.parse(JSON.stringify(initialObject))
  );
  const [isManualAddress, setIsManualAddress] = useState(false);

  const [googleAddressResult, setGoogleAddressResult] = useState([]);
  const [gooleSearch, setGoogleSearch] = useState({
    value: "",
    error: false,
  });

  const handleInputChange = (field, event) => {
    if (field === "gooleSearch") {
      _handleGoogleSearchChange(event.target.value);
    } else {
      _handleFieldChange(field, event.target.value);
    }
  };

  const _handleGoogleSearchChange = (value) => {
    setGoogleSearch({ value, error: false });
    _googlePlaceSearch(value);
  };

  const _handleFieldChange = (field, value) => {
    _updateInitialValues(field, value);

    if (_isClosingAddressField(field) && _isNotAgentUser()) {
      passData({
        [fieldName]: {
          ...initialValues,
          [field]: { value, error: "" },
        },
      });
    }
  };

  const _updateInitialValues = (field, value) => {
    setInitialValues((prevValues) => ({
      ...prevValues,
      [field]: {
        ...prevValues[field],
        value,
        error: "",
      },
    }));

    if (needDisableGoogleSearch && fieldName === "closingAddress") {
      setSeed();
    }
  };

  const _isClosingAddressField = (field) => {
    return (
      (field === "state" || field === "city" || field === "gooleSearch") &&
      fieldName === "closingAddress"
    );
  };

  const _isNotAgentUser = () => {
    return user?.type?.toLowerCase() !== "agent";
  };

  const _googlePlaceSearch = async (searchValue) => {
    try {
      const googleAddressResult = await googlePlaceSearch(searchValue);
      setGoogleAddressResult(googleAddressResult);
      // this.setState({ googleAddressResult, isAddressListShow });
    } catch (error) {
      console.error("Error fetching Google Place Search:", error);
    }
  };

  const getPlaceDetail = async (pid, type) => {
    try {
      const detail = await googlePlaceDetails(pid.place_id);

      if (detail && detail.postal) {
        const {
          address,
          city,
          county,
          state: detailState,
          country,
          postal,
          lat,
          lng,
        } = detail;

        const stateAbbreviation =
          usaStates.find((state) => state.name === detailState)?.abbreviation ||
          "";

        setGoogleSearch({
          value: pid.description,
          error: "",
        });
        const updatedFormfields = {
          ...initialValues,
          addressLine1: { ...initialValues.addressLine1, value: address },
          addressLine2: { ...initialValues.addressLine2, value: "" },
          city: { ...initialValues.city, value: city },
          county: { ...initialValues.county, value: county },
          state: { ...initialValues.state, value: stateAbbreviation },
          country: { ...initialValues.country, value: country || "" },
          zip: { ...initialValues.zip, value: postal },
          lat: { ...initialValues.lat, value: lat },
          lng: { ...initialValues.lng, value: lng },
        };

        setGoogleAddressResult([]);
        setInitialValues(updatedFormfields);
        if (resetAddress !== undefined) {
          resetAddress(updatedFormfields);
        }
        if (passData !== undefined) {
          passData({
            googleAddressResult: pid.description,
            [fieldName]: updatedFormfields,
          });
        }
        // console.log("updatedFormfields", updatedFormfields);
      } else {
        showToast("Invalid Address", "error");
      }
    } catch (error) {
      console.log("error >>", error);
      showToast("Error fetching place details", "error");
    }
  };

  const handleInputBlur = (fieldName) => {
    setInitialValues((prevValues) => {
      const formfields = { ...prevValues };
      formfields[fieldName].isDirty = true;
      const { updatedFormfields } = validateAddress(formfields);
      return updatedFormfields;
    });
  };

  const _markAllDirty = () => {
    let formfields = JSON.parse(JSON.stringify({ ...initialValues }));
    Object.keys(initialValues).forEach((fieldName) => {
      formfields[fieldName].isDirty = true;
    });
    const { updatedFormfields, isFormValid } = validateAddress(
      formfields,
      isRequired
    );
    setInitialValues(updatedFormfields);
    return { isFormValid, updatedFormfields };
  };

  const _handleSubmit = () => {
    const { isFormValid, updatedFormfields } = _markAllDirty();

    if (isManualAddress) {
      // this is a manual address
      return { isFormValid, [fieldName]: updatedFormfields };
    } else {
      // This is for google search validation

      // Is we need to check the validation
      const isGoogleSearchValid = _validateGoogleSearch();
      return {
        isFormValid: isGoogleSearchValid,
        [fieldName]: initialValues,
        googleAddressResult: gooleSearch?.value,
      };
    }
  };

  const _validateGoogleSearch = () => {
    if (isRequired && !gooleSearch?.value?.length) {
      setGoogleSearch((prevSearch) => ({
        ...prevSearch,
        error: true,
      }));
      return false;
    }

    setGoogleSearch((prevSearch) => ({ ...prevSearch, error: false }));
    return true;
  };

  const _getAddressData = () => {
    return {
      isFormValid: true,
      [fieldName]: initialValues,
      googleAddressResult: gooleSearch.value,
    };
  };

  useImperativeHandle(ref, () => ({
    _handleSubmit,
    _getAddressData,
  }));

  const _formatInitialValues = (data) => {
    console.log("_formatInitialValues", data);
    const obj = {
      addressLine1: {
        value:
          typeof data?.addressLine1 === "string"
            ? data?.addressLine1
            : data?.addressLine1?.value ||
              data?.line1?.value ||
              data?.street?.value ||
              data?.line1 ||
              data?.street ||
              "",
      },
      addressLine2: {
        value:
          typeof data?.addressLine2 === "string"
            ? data?.addressLine2
            : data?.addressLine2?.value ||
              data?.line2?.value ||
              data?.line2 ||
              "",
      },
      country: {
        value:
          typeof data?.country === "string"
            ? data?.country
            : data?.country?.value || "",
      },
      city: {
        value:
          typeof data?.city === "string" ? data?.city : data?.city?.value || "",
      },
      state: {
        value:
          typeof data?.state === "string"
            ? data?.state
            : data?.state?.value || "",
      },
      county: {
        value:
          typeof data?.county === "string"
            ? data?.county
            : data?.county?.value || "",
      },
      zip: {
        value:
          typeof data?.zip === "string" ? data?.zip : data?.zip?.value || "",
      },
    };

    // console.log(
    //   "JSON.parse(JSON.stringify(initialObject)) >>",
    //   JSON.parse(JSON.stringify(initialObject))
    // );
    // console.log("obj _formatInitialValues >>", obj);

    // Merge initialObject and obj using the spread operator
    const mergedObject = deepMergeObjects(
      JSON.parse(JSON.stringify(initialObject)),
      obj
    );

    // console.log("mergedObject >>", mergedObject);

    setInitialValues(mergedObject);
  };

  useEffect(() => {
    // this is for auto fill
    // console.log({ googleAddress });
    // google address auto fill
    if (googleAddress?.length) {
      setGoogleSearch((prev) => ({
        ...prev,
        error: false,
        value: googleAddress,
      }));
    } else {
      setGoogleSearch(() => ({
        error: false,
        value: "",
      }));
    }
  }, [googleAddress]);

  useEffect(() => {
    // manual address auto fill
    const autoFillManualAddress = () => {
      console.log("data closing address component >>", fieldName, data);
      if (data !== null) {
        // console.log("manual address data >>", data);
        if (whichView === "Manual") {
          setIsManualAddress(true);
        }
        if (data?.hasOwnProperty("googleAddressResult")) {
          _formatInitialValues(data[fieldName]);
        } else {
          _formatInitialValues(data);
        }
      } else {
        setInitialValues(initialObject);
      }
    };
    autoFillManualAddress();
    // eslint-disable-next-line
  }, [data]);

  const _toggleManual = () => {
    setIsManualAddress((prev) => !prev);
    setGoogleSearch({
      value:
        initialValues.addressLine1.value +
        " " +
        initialValues.city.value +
        " " +
        initialValues.state.value +
        " " +
        initialValues.zip.value,
      error: "",
    });
  };
  console.log("gooleSearch?.value >>", gooleSearch?.value);

  // console.log("initialValues >>", initialValues);

  return (
    <>
      {isManualAddressRequired ? (
        <Col sm={sm}>
          <CustomInput
            type="switch"
            className="mb-3"
            checked={isManualAddress}
            // onChange={() => setIsManualAddress((prev) => !prev)}
            onChange={() => _toggleManual()}
            id={`${fieldName}_Custom_Switch`}
            name={fieldName}
            label="Enter Address Manually"
            disabled={needDisableGoogleSearch}
          />
        </Col>
      ) : null}

      {isManualAddress ? (
        Object.keys(initialValues).map((key, index) => {
          return !hideCountryInput && key === "country" ? null : initialValues[
              key
            ].needToShow ? (
            <Col xl={xl} lg={lg} md={md} sm={sm} key={index}>
              {key === "state" ? (
                <SelectField
                  key={index}
                  name={key}
                  options={usaStates}
                  value={initialValues[key].value}
                  error={initialValues[key].error}
                  isDirty={initialValues[key].isDirty}
                  onChange={handleInputChange}
                  onBlur={handleInputBlur}
                  label="State"
                />
              ) : (
                <>
                  <FormGroup className="floatingLabel">
                    <InputField
                      key={index}
                      name={key}
                      value={initialValues[key].value}
                      placeholder={placeholders[key]}
                      type="text"
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                      disabled={needDisableGoogleSearch}
                    />
                  </FormGroup>
                  {initialValues[key].error?.length &&
                  initialValues[key].isDirty ? (
                    <span className={"validation-error"}>
                      {initialValues[key].error}
                    </span>
                  ) : null}
                </>
              )}
            </Col>
          ) : null;
        })
      ) : (
        <RenderSearchInput xl={xl} lg={lg} md={md} sm={sm}>
          <FormGroup className="floatingLabel withInputGroup">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <img
                    src={
                      require("../../../../assets/img/mapMarker_blue.svg")
                        .default
                    }
                    alt="map icon"
                    height={14}
                  />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="text"
                value={gooleSearch?.value}
                name="searchrchAddress"
                placeholder=" "
                autoComplete="off"
                onChange={(event) => handleInputChange("gooleSearch", event)}
                disabled={needDisableGoogleSearch}
              />
              <Label>{placeholder}</Label>
            </InputGroup>
            {gooleSearch?.error ? (
              <div className="validation-error">*Required</div>
            ) : null}
            {/* address suggestion list for input tag - borrower address */}
            {googleAddressResult && googleAddressResult.length ? (
              <ListGroup flush className="customSearchOptions">
                {googleAddressResult.map((addr, index) => {
                  return (
                    <ListGroupItem
                      key={addr.place_id || index}
                      className="cursorPointer"
                      onClick={() => getPlaceDetail(addr, "searchAddress")}
                    >
                      {addr.description}
                    </ListGroupItem>
                  );
                })}
              </ListGroup>
            ) : null}
          </FormGroup>
        </RenderSearchInput>
      )}
    </>
  );
});

const RenderSearchInput = ({ lg, md, sm, xl, children }) => {
  // Check if lg prop is within the range 6 to 12
  const isCol = lg >= 6 && lg <= 12;

  // Render Col component if lg is within range
  if (isCol) {
    return (
      <Col xl={xl} lg={lg} md={md} sm={sm}>
        {children}
      </Col>
    );
  } else {
    // Render a div if lg is out of range
    return <>{children}</>;
  }
};

export default memo(ClosingAddress);
