import React, { Suspense, lazy } from "react";
import {
  Input,
  Button,
  FormGroup,
  Badge,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import ReactDatetime from "react-datetime";
import {
  deepClone,
  showToast,
  formatDateAndTime,
  checkPermission,
  refreshFunc,
  deleteLocalStorageData,
  errorHandler,
  debounce,
  getDayFromDate,
  buildQueryString,
} from "../../../helper-methods";
import { connect } from "react-redux";
import {
  getAllSigningCompanyAgents,
  signingCompanyMarkFavouriteAgentById,
  getRating,
  uploadAgentCSV,
} from "../../../http/http-calls";
import { capitalize } from "@material-ui/core";
import usaStates from "../../../config/usa_states_titlecase";
import { DEFAULT_PROFILE_PICTURE } from "../../../config";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import StarRatings from "react-star-ratings";
import {
  googlePlaceDetails,
  googlePlaceSearch,
} from "../../../helper-methods/googleService";
import ScreenWidthHOC from "./ScreenWidthHOC";
import {
  agentsHeaderKeys,
  fee,
  jobCompleted,
  rating,
} from "../../../config/agentConfig";
import SvgIcons from "../components/SvgIcons";

const CustomTable = lazy(() => import("../components/CustomTable"));
const CustomCard = lazy(() => import("../components/CustomCard"));
const NewAgentModal = lazy(() => import("../components/new-agent-modal"));
class Agents extends React.Component {
  state = {
    agents: [],
    agentsTotalCount: 0,
    agentsHeaderKeys: agentsHeaderKeys,
    agentsCardHeaderKeys: [
      { id: "id", label: "id" },
      { id: "name", label: "Name" },
    ],
    loading: {
      statusChangeLoading: false,
      showTableLoading: false,
      bulkFile: false,
    },
    newAgentModal: {
      isOpen: false,
      data: null,
    },
    bulkUploadResponse: {
      isOpen: false,
      data: null,
      success: null,
    },
    viewRatingModal: {
      isOpen: false,
      data: null,
      mode: null,
    },
    filters: {
      type: "",
      jobCompleted: {},
      rating: {},
      state: "",
      availability: "",
      location: "",
      fee: "",
      search: "",
    },
    tableConfig: {
      skip: 0,
      limit: 10,
    },
    filterDropdownValues: {
      jobCompleted: jobCompleted,
      rating: rating,
      fee: fee,
    },
    selectedFile: {},
    isClassAdded: false,
    show: null,
    searchResult: [],
  };

  _onToggleNewAgentModal = () => {
    let { newAgentModal } = deepClone(this.state);
    newAgentModal.isOpen = !newAgentModal.isOpen;
    this.setState({
      newAgentModal,
    });
  };
  filterShowMobile = () => {
    this.setState((prevState) => ({
      isClassAdded: !prevState.isClassAdded,
    }));
  };

  _onToggleBulkInfoModal = () => {
    let { bulkUploadResponse } = deepClone(this.state);
    bulkUploadResponse.isOpen = !bulkUploadResponse.isOpen;
    bulkUploadResponse.data = null;
    bulkUploadResponse.success = null;
    this.setState({
      bulkUploadResponse,
      selectedFile: {},
    });
  };

  _filterOnChange = async (type, value) => {
    console.log(type, value);
    const { filters } = this.state;

    if (type === "availability")
      filters[type] = value ? new Date(value).toISOString() : "";
    else if (["jobCompleted", "rating", "fee"].includes(type))
      filters[type] = JSON.parse(value);
    else filters[type] = value;

    this.setState({ filters });

    if (type === "location") {
      if (value.trim().length > 0) {
        try {
          const res = await googlePlaceSearch(value);
          this.setState({ searchResult: res, show: type });
        } catch (err) {
          showToast("Errors");
        }
      } else {
        this.setState({ searchResult: [], show: null });
        this._paginate();
        this._persistFilter();
      }
    } else if (type === "search") {
      this.searchOnChange();
    } else {
      this._paginate();
      this._persistFilter();
    }
  };

  //reset filter
  _resetFilter = () => {
    this.setState(
      {
        filters: {
          type: "",
          jobCompleted: "",
          rating: "",
          state: "",
          availability: "",
          location: "",
          fee: "",
          search: "",
        },
      },
      () => {
        this._paginate();
        refreshFunc("company-agents-responsive");
        deleteLocalStorageData("agents");
      }
    );
  };

  // when  click on name then go to details page
  _redirectToAgentDetails = (data) => {
    // if (e) e.preventDefault();
    const { userData } = this.props;
    this.props.history.push(`/${userData.type}/agent-details/${data.id}`);
  };

  //loading management
  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  componentDidMount = () => {
    // this._getAllAgents();
    this._setFilterDataFromLocalStorage();
    HeaderEventEmitter.subscribe("reset-filter", () => {
      this._resetFilter();
      refreshFunc("company-agents");
    });
    HeaderEventEmitter.subscribe("add-agent", () => {
      this._onToggleNewAgentModal();
    });
    HeaderEventEmitter.subscribe("file-uploaded", () => { });
  };

  // get all agent list
  _getAllAgents = async () => {
    const { filters, tableConfig } = this.state;
    this._manageLoading("showTableLoading", true);

    if (filters.type && filters.type.length) {
      Object.assign(tableConfig, { workType: filters.type });
    } else {
      delete tableConfig.workType;
    }

    if (
      filters.location &&
      filters.location.length
      // !isNaN(filters.location)
    ) {
      Object.assign(tableConfig, { location: filters.location });
    } else {
      delete tableConfig.location;
    }

    if (filters.search && filters.search.length) {
      Object.assign(tableConfig, { search: filters.search });
    } else {
      delete tableConfig.search;
    }

    if (filters.fee && filters.fee.length) {
      Object.assign(tableConfig, { standardFee: filters.fee });
    } else {
      delete tableConfig.standardFee;
    }

    if (filters.availability) {
      Object.assign(tableConfig, {
        availability: getDayFromDate(filters.availability),
      });
    } else {
      delete tableConfig.availability;
    }

    if (filters.state) {
      Object.assign(tableConfig, { state: filters.state });
    } else {
      delete tableConfig.state;
    }

    delete tableConfig.sort;

    let params = {
      jobCompleted: {
        min: filters.jobCompleted.min,
        max: filters.jobCompleted.max,
      },
      rating: {
        min: filters.rating.min,
        max: filters.rating.max,
      },
      standardFee: { min: filters.fee.min, max: filters.fee.max },
      ...tableConfig,
    };

    const queryString = buildQueryString(params);

    try {
      const res = await getAllSigningCompanyAgents(queryString);
      this.setState({
        agents: res?.agents,
        agentsTotalCount: res?.totalCount,
      });
    } catch (error) {
      showToast(
        error && error.reason && error.reason.length
          ? error.reason
          : "Server error. Try again after sometime.",
        "error"
      );
    } finally {
      this._manageLoading("showTableLoading", false);
    }
  };

  //This function used for data format
  _dataFormat = (cell, row, header) => {
    switch (header) {
      case "workType": {
        return cell ? (
          <div style={{ width: 60 }}>{capitalize(cell)}</div>
        ) : (
          "N/A"
        );
      }
      case "name": {
        return cell ? (
          <>
            <div className="tableUserInfo">
              {row ? (
                !row.isFavourite ? (
                  <Button
                    color="link"
                    className="actionBtn"
                    disabled={!checkPermission("agents", "canUpdate")}
                    onClick={() => this._markAgentAsFavorite(row.id)}
                  >
                    <img
                      src={require("../../../assets/img/iconFav.svg").default}
                      alt="fav icon"
                    />
                  </Button>
                ) : (
                  <Button
                    color="link"
                    className="actionBtn"
                    onClick={() => this._markAgentAsFavorite(row.id)}
                  >
                    <img
                      src={
                        require("../../../assets/img/iconFavFill.svg").default
                      }
                      alt="fav icon"
                    />
                  </Button>
                )
              ) : (
                "N/A"
              )}
              <div className="userImage">
                <img
                  src={
                    row?.profilePicture
                      ? row.profilePicture
                      : DEFAULT_PROFILE_PICTURE
                  }
                  alt="Avatar"
                />
              </div>
              <div className="userContent">
                <span
                  className="signerName"
                  onClick={() => this._redirectToAgentDetails(row)}
                  disabled={!checkPermission("agents", "canUpdate")}
                >
                  {" "}
                  {capitalize(cell?.first + " " + cell?.last)}
                </span>
                <a href={`mailto:${row?.email}`}>
                  <Button
                    color="link"
                    className="actionBtn mr-2"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={row?.email}
                  >
                    <i className="fa fa-envelope-o" />
                  </Button>
                </a>
                <a href={`tel:${row?.phone}`}>
                  <Button
                    color="link"
                    className="actionBtn"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={row?.phone}
                  >
                    <i className="fa fa-phone" />
                  </Button>
                </a>
              </div>
            </div>
          </>
        ) : (
          "N/A"
        );
      }
      case "address": {
        return cell ? (
          cell?.city &&
            cell?.city !== "" &&
            cell?.state &&
            cell?.state !== "" ? (
            <>
              {cell?.city ? `${cell?.city}, ` : ""}
              {cell?.state ? `${cell?.state}, ` : ""}
            </>
          ) : (
            "N/A"
          )
        ) : (
          "N/A"
        );
      }
      case "closingCompleted": {
        return cell >= 0 ? (
          <>
            Total:{cell} 3mnth:
            {row?._jobLastThreeMonth ? row?._jobLastThreeMonth : 0}
          </>
        ) : (
          "N/A"
        );
      }

      case "totalRating": {
        return (
          <>
            {row?.totalRating ? (
              <div className="tableRating">
                <StarRatings
                  rating={row?.totalRating}
                  starRatedColor="#FED82F"
                  starHoverColor="#FED82F"
                  numberOfStars={5}
                  name="rating"
                />
              </div>
            ) : (
              "N/A"
            )}
          </>
        );
      }
      case "states": {
        return cell ? row.address.state : "N/A";
      }

      case "credential": {
        return (
          <>
            {row?.notaryLicense?.every(
              (item) => item?.isVerified !== "Approved"
            ) &&
              (!row?.ronCredentials ||
                row?.ronCredentials?.every(
                  (item) => item?.isVerified !== "Approved"
                )) &&
              row?.idVerification?.isVerified !== "Approved" &&
              row?.backgroundCheckReport?.isVerified !== "Approved" &&
              row?.eoInsurance?.isVerified !== "Approved" &&
              row?.nnaCertification?.isVerified !== "Approved" &&
              row?.titleProducerLicense?.isVerified !== "Approved" &&
              row?.mdTitleProducerLicense?.isVerified !== "Approved" &&
              row?.nyTotalCloser?.isVerified !== "Approved" &&
              row?.lssCertification?.isVerified !== "Approved" &&
              row?.w9Credentials?.isVerified !== "Approved" &&
              "N/A"}

            <div className="credentialWrap">
              {row?.idVerification?.isVerified === "Approved" && (
                <Badge color="success" className="outline" pill>
                  {" "}
                  ID{" "}
                </Badge>
              )}
              {row?.backgroundCheckReport?.isVerified === "Approved" && (
                <Badge color="success" className="outline" pill>
                  {" "}
                  BGC{" "}
                </Badge>
              )}
              {row?.eoInsurance?.isVerified === "Approved" && (
                <Badge color="success" className="outline" pill>
                  {" "}
                  E&O{" "}
                </Badge>
              )}
              {row?.nnaCertification?.isVerified === "Approved" && (
                <Badge color="success" className="outline" pill>
                  {" "}
                  NNA{" "}
                </Badge>
              )}
              {row?.lssCertification?.isVerified === "Approved" && (
                <Badge color="success" className="outline" pill>
                  {" "}
                  {/* LSS{" "} */}
                  NTC
                </Badge>
              )}
              {row?.w9Credentials?.isVerified === "Approved" && (
                <Badge color="success" className="outline" pill>
                  {" "}
                  W9{" "}
                </Badge>
              )}
              {row?.notaryLicense?.length > 0 &&
                row?.notaryLicense?.every(
                  (item) => item?.isVerified === "Approved"
                ) && (
                  <Badge color="success" className="outline" pill>
                    {" "}
                    NL{" "}
                  </Badge>
                )}
              {row?.ronCredentials?.length > 0 &&
                row?.ronCredentials?.every(
                  (item) => item?.isVerified === "Approved"
                ) && (
                  <Badge color="success" className="outline" pill>
                    {" "}
                    RON{" "}
                  </Badge>
                )}
            </div>
          </>
        );
      }

      case "isActive": {
        return row ? (
          <>
            <div className="customSelectWrap" style={{ width: 95 }}>
              {row?.isActive ? (
                <Badge color="success" className="outline" pill>
                  Active
                </Badge>
              ) : (
                <Badge color="warning" className="outline" pill>
                  Inactive
                </Badge>
              )}
            </div>
          </>
        ) : (
          "N/A"
        );
      }
      case "action": {
        return (
          <>
            {" "}
            {row ? (
              <Button
                color="link"
                className="actionBtn"
                onClick={() => this._redirectToAgentDetails(row)}
                disabled={!checkPermission("agents", "canUpdate")}
              >
                <i className="fa fa-eye" />
              </Button>
            ) : (
              "N/A"
            )}
          </>
        );
      }
      default: {
        return cell;
      }
    }
  };

  _paginate = (pageNumber = 1, pageSize = 10) => {
    // console.log("pageNumber, pageSize :", pageNumber, pageSize);
    const { tableConfig } = this.state;
    tableConfig.skip = (pageNumber - 1) * pageSize;
    tableConfig.limit = pageSize;
    this.setState({ tableConfig }, () => {
      this._persistFilter();
      this._getAllAgents();
    });
  };

  //This for mark as favorite or not
  _markAgentAsFavorite = async (id) => {
    this._manageLoading("showTableLoading", true);

    try {
      await signingCompanyMarkFavouriteAgentById(id);
      this._getAllAgents();
      // this._manageLoading("showTableLoading", false);
    } catch (error) {
      errorHandler(error);
      this._manageLoading("statusChangeLoading", false);
    }
  };

  _getrating = (id) => {
    const { closingDetails } = deepClone(this.state);

    if (closingDetails?._agent) {
      getRating(id)
        .then((res) => {
          this.setState({
            previousRating: res?.previousRating || null,
          });
        })
        .catch((error) => {
          errorHandler(error);
        });
    }
  };

  _persistFilter = () => {
    const { filters, tableConfig } = this.state;

    if (
      (filters &&
        (filters.type ||
          Object.keys(filters.rating).length !== 0 ||
          Object.keys(filters.jobCompleted).length !== 0 ||
          filters.state ||
          filters.fee ||
          filters.availability ||
          (filters.location && filters.location.length))) ||
      tableConfig.pageNumber !== 1
    ) {
      // console.log("232 ", filters, tableConfig);
      let data = { filters, tableConfig };
      localStorage.agents = JSON.stringify(data);
    } else {
      delete localStorage.agents;
    }
  };

  _setFilterDataFromLocalStorage = () => {
    if (localStorage && localStorage.agents) {
      try {
        const filters = JSON.parse(localStorage.agents);
        console.log({ filters });
        this.setState(
          { filters: filters.filters, tableConfig: filters.tableConfig },
          () => {
            this._getAllAgents();
          }
        );
      } catch (e) {
        this._getAllAgents();
      }
    } else {
      this._getAllAgents();
    }
  };

  _onFileSelect = (fieldName, e) => {
    if (e.target.files?.[0]) {
      this.setState(
        {
          isFormDirty: true,
          [fieldName]: {
            previewBlob: URL.createObjectURL(e.target.files[0]),
            uploadData: e.target.files[0],
          },
        },
        () => this._handleSubmit()
      );
    }
  };

  _handleSubmit = async () => {
    this._manageLoading("bulkFile", true);

    try {
      const formData = new FormData();
      formData.append("file", this.state.selectedFile?.uploadData);
      formData.append("firstName", "firstName");
      formData.append("lastName", "lastName");
      formData.append("email", "email");
      formData.append("phone", "phone");
      formData.append("workType", "workType");
      let response = await uploadAgentCSV(formData);
      this._manageLoading("bulkFile", false);
      // console.log("2222", response);
      if (typeof response.error === "string") {
        errorHandler(response.error);
      } else {
        !response.error && showToast("Uploaded Successfully", "success");
        this.setState({
          bulkUploadResponse: {
            isOpen: true,
            data: response?.reason || null,
            success: response.list || null,
          },
        });
      }
    } catch (error) {
      this._manageLoading("bulkFile", false);
      // console.log("444444", error);
      this.setState({
        bulkUploadResponse: {
          isOpen: true,
          data: error.reason,
          success: error.list || null,
        },
      });
    }
  };

  _rowClassName = (row, rowIdx) => {
    // console.log("q234", row);
    if (row?.isBlocked) {
      return "agentBlocked";
    }
    return "";
  };
  _cardHeaderFormat = (cell, row, header) => {
    // console.log("23456", row, cell, header);
    switch (header) {
      case "name": {
        return row ? (
          <>
            <div className="tableUserInfo">
              {row ? (
                !row?.isFavourite ? (
                  <Button
                    color="link"
                    className="actionBtn"
                    style={{ marginLeft: 0 }}
                    disabled={!checkPermission("agents", "canUpdate")}
                    onClick={() => this._markAgentAsFavorite(row.id)}
                  >
                    <img
                      src={require("../../../assets/img/iconFav.svg").default}
                      alt="fav icon"
                    />
                  </Button>
                ) : (
                  <Button
                    color="link"
                    className="actionBtn"
                    onClick={() => this._markAgentAsFavorite(row.id)}
                  >
                    <img
                      src={
                        require("../../../assets/img/iconFavFill.svg").default
                      }
                      alt="fav icon"
                    />
                  </Button>
                )
              ) : (
                "N/A"
              )}
              <div className="userImage">
                <img
                  src={
                    row?.profilePicture
                      ? row?.profilePicture
                      : DEFAULT_PROFILE_PICTURE
                  }
                  alt="Avatar"
                />
              </div>
              <div className="userContent">
                <span
                  className="signerName"
                  onClick={() => this._redirectToAgentDetails(row)}
                  disabled={!checkPermission("agents", "canUpdate")}
                >
                  {" "}
                  {capitalize(row?.name?.first + " " + row?.name?.last)}
                </span>
              </div>
              <a href={`mailto:${row?.email}`}>
                <Button
                  color="link"
                  className="actionBtn"
                  data-toggle="tooltip"
                  data-placement="top"
                  title={row?.email}
                >
                  <i className="fa fa-envelope-o" />
                </Button>
              </a>
              <a href={`tel:${row?.phone}`}>
                <Button
                  color="link"
                  className="actionBtn"
                  data-toggle="tooltip"
                  data-placement="top"
                  title={row?.phone}
                >
                  <i className="fa fa-phone" />
                </Button>
              </a>
            </div>
          </>
        ) : (
          "N/A"
        );
      }
      default: {
        return cell;
      }
    }
  };

  // Data show for mobile view
  _cardDataFormat = (row) => {
    return (
      <ul className="cardInfo">
        <li>
          <div className="dataWrap">
            <Label>Type </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.workType ? capitalize(row?.workType) : "N/A"}
            </div>
          </div>
          <div className="dataWrap">
            <Label>Location </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.address ? (
                row?.address?.city &&
                  row?.address?.city !== "" &&
                  row?.address?.state &&
                  row?.address?.state !== "" ? (
                  <>
                    {row?.address?.city ? `${row?.address?.city}, ` : ""}
                    {row?.address?.state ? `${row?.address?.state}, ` : ""}
                  </>
                ) : (
                  "N/A"
                )
              ) : (
                "N/A"
              )}
            </div>
          </div>{" "}
        </li>

        <li>
          <div className="dataWrap">
            <Label>Jobs Completed </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.closingCompleted >= 0 ? (
                <>
                  Total:{row?.closingCompleted} 3mnth:
                  {row?._jobLastThreeMonth ? row?._jobLastThreeMonth : 0}
                </>
              ) : (
                "N/A"
              )}
            </div>
          </div>

          <div className="dataWrap">
            <Label>Ratings </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {
                <>
                  {row?.totalRating ? (
                    <div className="tableRating">
                      <StarRatings
                        rating={row?.totalRating}
                        starRatedColor="#FED82F"
                        starHoverColor="#FED82F"
                        numberOfStars={5}
                        name="rating"
                      />
                    </div>
                  ) : (
                    "N/A"
                  )}
                </>
              }
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Credential </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {
                <>
                  {
                    // row?.notaryLicense?.isVerified !== "Approved" &&
                    row?.notaryLicense?.every(
                      (item) => item?.isVerified !== "Approved"
                    ) &&
                    (!row?.ronCredentials ||
                      row?.ronCredentials?.every(
                        (item) => item?.isVerified !== "Approved"
                      )) &&
                    row?.idVerification?.isVerified !== "Approved" &&
                    row?.backgroundCheckReport?.isVerified !== "Approved" &&
                    row?.eoInsurance?.isVerified !== "Approved" &&
                    row?.nnaCertification?.isVerified !== "Approved" &&
                    row?.titleProducerLicense?.isVerified !== "Approved" &&
                    row?.mdTitleProducerLicense?.isVerified !== "Approved" &&
                    row?.nyTotalCloser?.isVerified !== "Approved" &&
                    row?.lssCertification?.isVerified !== "Approved" &&
                    row?.w9Credentials?.isVerified !== "Approved" &&
                    "N/A"
                    // <span className="statusSuccess">Verified</span>
                  }

                  <div className="credentialWrap w-100">
                    {row?.idVerification?.isVerified === "Approved" && (
                      <Badge color="success" className="outline" pill>
                        {" "}
                        ID{" "}
                      </Badge>
                    )}
                    {row?.backgroundCheckReport?.isVerified === "Approved" && (
                      <Badge color="success" className="outline" pill>
                        {" "}
                        BGC{" "}
                      </Badge>
                    )}
                    {row?.eoInsurance?.isVerified === "Approved" && (
                      <Badge color="success" className="outline" pill>
                        {" "}
                        E&O{" "}
                      </Badge>
                    )}
                    {row?.nnaCertification?.isVerified === "Approved" && (
                      <Badge color="success" className="outline" pill>
                        {" "}
                        NNA{" "}
                      </Badge>
                    )}
                    {row?.lssCertification?.isVerified === "Approved" && (
                      <Badge color="success" className="outline" pill>
                        {" "}
                        NTC{" "}
                      </Badge>
                    )}
                    {row?.w9Credentials?.isVerified === "Approved" && (
                      <Badge color="success" className="outline" pill>
                        {" "}
                        W9{" "}
                      </Badge>
                    )}
                    {row?.notaryLicense?.length > 0 &&
                      row?.notaryLicense?.every(
                        (item) => item?.isVerified === "Approved"
                      ) && (
                        <Badge color="success" className="outline" pill>
                          {" "}
                          NL{" "}
                        </Badge>
                      )}
                    {row?.ronCredentials?.length > 0 &&
                      row?.ronCredentials?.every(
                        (item) => item?.isVerified === "Approved"
                      ) && (
                        <Badge color="success" className="outline" pill>
                          {" "}
                          RON{" "}
                        </Badge>
                      )}
                  </div>
                </>
              }
            </div>
          </div>
        </li>
        <li>
          <div className="dataWrap d-flex align-items-center justify-content-between">
            <Label>Status </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row ? (
                <>
                  <div className="customSelectWrap mr-0">
                    {row?.isActive ? (
                      <Badge color="success" className="outline" pill>
                        Active
                      </Badge>
                    ) : (
                      <Badge color="warning" className="outline" pill>
                        Inactive
                      </Badge>
                    )}
                  </div>
                </>
              ) : (
                "N/A"
              )}
            </div>
          </div>
        </li>
      </ul>
    );
  };

  getPlaceDetail = async (pid, type) => {
    const { formFields, filters } = deepClone(this.state);
    let detail = await googlePlaceDetails(pid.place_id);
    console.log(detail);

    if (detail) {
      let state = usaStates.filter((state) => state.name === detail.state)[0]
        ?.abbreviation;
      if (detail.postal) {
        filters["location"] =
          detail?.address +
          "," +
          detail?.city +
          "," +
          state +
          "," +
          detail?.postal +
          "," +
          detail?.country;
        this.setState({ filters }, () => {
          this._getAllAgents();
          this._persistFilter();
        });
      } else {
        showToast("Invalid Address", "error");
      }

      this.setState({ formFields, show: true });
    }
  };

  searchOnChange = debounce(() => {
    this._paginate();
  }, 1000);

  render() {
    const {
      filters,
      filterDropdownValues,
      agents,
      agentsHeaderKeys,
      loading,
      agentsTotalCount,
      tableConfig,
      isClassAdded,
      agentsCardHeaderKeys,
    } = this.state;
    const { isForMobile } = this.props;

    return (
      <>
        <div className="content">

          <div className="responsiveTitle">
            <h2>Agents</h2>

            <div className="rightSide">
              <Label className="uploadLink">
                <Input
                  type="file"
                  hidden
                  accept=".csv"
                  value=""
                  disabled={loading.bulkFile}
                  onChange={(e) => this._onFileSelect("selectedFile", e)}
                />
                {loading.bulkFile ? (
                  <i className="fa fa-spinner fa-spin" />
                ) : (
                  <>
                    <SvgIcons type="upload" />
                    Upload
                  </>
                )}
              </Label>
              {/* <Link
                to={`/signingcompany/bulk-upload?companyAgent`}
                className="mr-3"
              >
                Bulk upload
              </Link> */}

              {checkPermission("agents", "canAdd") && (
                <Button
                  className="floatingButton"
                  color="primary"
                  onClick={() => this._onToggleNewAgentModal()}
                >
                  New Agent
                </Button>
              )}
              <Button id="company-agents-responsive" color="link" onClick={this._resetFilter}>
                {/* <img
                  
                  src={require("../../../assets/img/refresh.svg").default}
                  alt="refresh"
                  height={14}
                /> */}
                <SvgIcons type="refresh" />
              </Button>

              <Button
                className="filterBarIcon"
                color="link"
                onClick={this.filterShowMobile}
              >
                <SvgIcons type="filterIcon" />
              </Button>
            </div>
          </div>

          {/* search filed for responsive will show below 767px */}
          <div className="mobileSearchFiled">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <img
                    src={require("../../../assets/img/searchIcon.svg").default}
                    alt="searchIcon"
                  />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                name="search"
                value={filters.search}
                onChange={(e) =>
                  this._filterOnChange("search", e.target.value, true)
                }
                placeholder="Search Agents"
              />
            </InputGroup>
          </div>

          {/* filter for tablet and web */}
          <div
            onClick={this.filterShowMobile}
            className={`mobileFilterView ${isClassAdded ? "show" : ""}`}
          ></div>
          <div
            className={`filterContainer  responsiveFilter ${isClassAdded ? "show" : ""
              }`}
          >
            <div className="filterIcon">
              <SvgIcons type="filterIcon" />
              Filter by
            </div>
            <div className="mobileTitle">
              <h2>Filter by</h2>
              <Button
                color="link"
                className="closeButton"
                onClick={this.filterShowMobile}
              >
                <img
                  src={require("../../../assets/img/close_grey.svg").default}
                  alt="close"
                  height={12}
                />
              </Button>
            </div>
            <div className="filterWrapper">
              <FormGroup className="searchTable">
                <Label>Search</Label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <img
                        src={
                          require("../../../assets/img/searchIcon.svg").default
                        }
                        alt="searchIcon"
                      />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="search"
                    value={filters.search}
                    onChange={(e) =>
                      this._filterOnChange("search", e.target.value, true)
                    }
                    placeholder="Search Agents"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <Label>Type</Label>
                <div className="custom-select-wrapper">
                  <Input
                    type="select"
                    name="type"
                    value={filters.type}
                    onChange={(e) =>
                      this._filterOnChange("type", e.target.value)
                    }
                  >
                    <option value="">All</option>
                    <option value="Notary">Notary</option>
                    <option value="Attorney">Attorney</option>
                  </Input>
                </div>
              </FormGroup>
              <FormGroup>
                <Label>Jobs</Label>
                <div className="custom-select-wrapper">
                  <Input
                    type="select"
                    name="jobCompleted"
                    value={JSON.stringify(filters.jobCompleted)}
                    onChange={(e) =>
                      this._filterOnChange("jobCompleted", e.target.value)
                    }
                  >
                    {filterDropdownValues.jobCompleted.map((job, index) => (
                      <option key={index} value={JSON.stringify(job.value)}>
                        {job.name}
                      </option>
                    ))}
                  </Input>
                </div>
              </FormGroup>
              <FormGroup>
                <Label>Rating</Label>
                <div className="custom-select-wrapper">
                  <Input
                    type="select"
                    name="rating"
                    value={JSON.stringify(filters.rating)}
                    onChange={(e) =>
                      this._filterOnChange("rating", e.target.value)
                    }
                    style={{ minWidth: 60 }}
                  >
                    {filterDropdownValues.rating.map((obj, index) => (
                      <option key={index} value={JSON.stringify(obj.value)}>
                        {obj.name}
                      </option>
                    ))}
                  </Input>
                </div>
              </FormGroup>
              <FormGroup>
                <Label>Availability</Label>
                <ReactDatetime
                  inputProps={{
                    className: "form-control",
                    placeholder: "Select",
                    value: formatDateAndTime(filters.availability),
                  }}
                  onChange={(e) => this._filterOnChange("availability", e._d)}
                  timeFormat={true}
                />
              </FormGroup>
              <FormGroup>
                <Label>Location</Label>
                <Input
                  type="text"
                  placeholder="Address"
                  value={filters.location}
                  onChange={(e) =>
                    this._filterOnChange("location", e.target.value)
                  }
                />
                {filters?.location?.trim()?.length > 0 &&
                  this.state.show === "location" ? (
                  <ListGroup flush className="customSearchOptions">
                    {" "}
                    {this.state.searchResult &&
                      this.state.searchResult.map((i) => {
                        return (
                          <ListGroupItem
                            style={{ cursor: "pointer" }}
                            onClick={() => this.getPlaceDetail(i)}
                          >
                            {i.description}
                          </ListGroupItem>
                        );
                      })}
                  </ListGroup>
                ) : null}
              </FormGroup>
              <FormGroup>
                <Label>Fee</Label>
                <div className="custom-select-wrapper">
                  <Input
                    type="select"
                    name="fee"
                    value={JSON.stringify(filters.fee)}
                    onChange={(e) =>
                      this._filterOnChange("fee", e.target.value)
                    }
                  >
                    {filterDropdownValues.fee.map((obj, index) => (
                      <option key={index} value={JSON.stringify(obj.value)}>
                        {obj.name}
                      </option>
                    ))}
                  </Input>
                </div>
              </FormGroup>
            </div>

            <div className="clearButton">
              <Button size="md" color="primary" onClick={this.filterShowMobile}>
                Close
              </Button>
            </div>
          </div>
          {isForMobile ? (
            <Suspense fallback={<></>}>
              <div className="hideDesktop">
                <CustomCard
                  isPageStartFromOne={true}
                  // pageNumber={tableConfig.pageNumber}
                  pageNumber={tableConfig.skip / tableConfig.limit + 1}
                  tableData={agents}
                  headerKeys={agentsCardHeaderKeys}
                  dataFormat={this._dataFormat}
                  totalCount={agentsTotalCount}
                  onPaginate={(pageNumber, pageSize) =>
                    this._paginate(pageNumber, pageSize)
                  }
                  rowSelection={false}
                  showTableLoading={loading.showTableLoading}
                  cardHeaderFormat={this._cardHeaderFormat}
                  cardDataFormat={this._cardDataFormat}
                />
              </div>
            </Suspense>
          ) : (
            <Suspense fallback={<></>}>
              <div className="hideMobile">
                {agentsHeaderKeys && agentsHeaderKeys.length && (
                  <CustomTable
                    striped
                    isPageStartFromOne={true}
                    tableData={agents}
                    headerKeys={agentsHeaderKeys}
                    dataFormat={this._dataFormat}
                    totalCount={agentsTotalCount}
                    pageNumber={tableConfig.skip / tableConfig.limit + 1}
                    rowSelection={false}
                    onPaginate={(pageNumber, pageSize) =>
                      this._paginate(pageNumber, pageSize)
                    }
                    showTableLoading={loading.showTableLoading}
                  />
                )}
              </div>
            </Suspense>
          )}

          {/* create agent modal */}
          <Suspense fallback={<></>}>
            <NewAgentModal
              isOpen={this.state.newAgentModal.isOpen}
              creatorData={this.state.newAgentModal.data}
              toggle={() => this._onToggleNewAgentModal()}
            ></NewAgentModal>
          </Suspense>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
  };
};

export default connect(mapStateToProps, null)(ScreenWidthHOC(Agents));
