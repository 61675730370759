import { store } from "../redux/store";

export const isUserAuthenticated = (path = "") => {
  const state = store.getState();

  let isAuth = false;

  if (state && state.userData && state.userData.token) {
    if (path && path.includes(state.userData.type)) {
      isAuth = true;
    }
  }

  return isAuth;
};

export const isCardAdded = (path = "") => {
  const state = store.getState();

  let isAuth = false;

  if (
    state &&
    state.userData &&
    state.userData.token &&
    state.userData.user.isCardAdded &&
    !state?.userData?.user?._subscription?.isExpired
  ) {
    if (path && path.includes(state.userData.type)) {
      isAuth = true;
    }
  }

  return isAuth;
};
