import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

// upgrade account modal
const UpgradeAccountModal = ({ isOpen, toggle }) => {
  const userData = useSelector((state) => state?.userData);
  const history = useHistory();

  // close function
  const _closeModal = (action) => {
    toggle();
  };

  // redirect to subscription
  const _redirect = (e) => {
    if (e) e.preventDefault();

    history.push(`/${userData?.type}/subscription`);
    _closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _closeModal()}
      scrollable
      centered
      className="upgradeAccountModal"
    >
      <ModalHeader toggle={() => _closeModal()}>
        CloseWise Paid Feature
      </ModalHeader>
      <ModalBody>
        <img
          src={require("../../../../assets/img/closewise.png")}
          alt="logo"
          className="companyLogo"
        />
        <div className="joinProfessonals">
          <h2>Join Our Paid Plans</h2>
          <p>
            Upgrade your account today and receive <br />
            <strong>14 day free trial</strong> + paid features
          </p>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          style={{ maxWidth: "unset", width: "auto" }}
          onClick={(e) => _redirect(e)}
          color="primary"
        >
          UPGRADE NOW
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UpgradeAccountModal;
