import React, { Suspense, lazy } from "react";
import { Elements } from "react-stripe-elements";
import {
  Badge,
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { BASE_URL } from "../../../config";
import { agentPayPeriodBreakdownHeaderKeys } from "../../../config/agentConfig";
import usaStates from "../../../config/usa_states_titlecase";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import {
  calculateTotalChargeWithFee,
  capitalize,
  convertIsoToUtc,
  deepClone,
  deleteLocalStorageData,
  errorHandler,
  formatAddressInSingleText,
  formatCurrencyValue,
  formatDateAsPerTimeZOne,
  getLoggedInUserId,
  getTimeZoneAbbr,
  openUrlOnNewTab,
  refreshFunc,
  showToast,
  structureQueryParams,
} from "../../../helper-methods";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import {
  companyPayAgent,
  fetchAllSigningcompanyPayPeriodBreakdown,
  payPeriodSigningcompanyAgentList,
  sendKycRequest,
  updateSigningCompanyPayPeriodBreakdown,
} from "../../../http/http-calls";
import ScreenWidthHOC from "./ScreenWidthHOC";
import { ShowKycTextModal } from "../components/Modals/Agents/ShowTextModal";
import SvgIcons from "../components/SvgIcons";

const SignerPopover = lazy(() => import("../components/common/popover"));
const AccountComponentModal = lazy(() =>
  import("../components/AccountComponentModal")
);
const CustomCard = lazy(() => import("../components/CustomCard"));
const CustomTable = lazy(() => import("../components/CustomTable"));
const PayAgentModal = lazy(() => import("../components/pay-agent-modal"));

// debouncing function
function debounce(fn, time) {
  let timeoutId;
  return wrapper;
  function wrapper(...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      timeoutId = null;
      fn(...args);
    }, time);
  }
}
class AgentPayPeriodBreakdownCompany extends React.Component {
  state = {
    dateRangeValue: null,
    filters: {
      agent: "",
      state: "",
      search: "",
      status: "",
    },
    agents: [],
    agentPayPeriodBreakdownList: [],
    agentPayPeriodBreakdownTotalCount: 0,
    agentPayPeriodBreakdownTotalDue: 0,
    agentPayPeriodBreakdownHeaderKeys: agentPayPeriodBreakdownHeaderKeys,
    agentPayPeriodBreakdownCardHeaderKeys: [
      { id: "id", label: "id" },
      { id: "name", label: "Name" },
    ],
    tableConfig: {
      skip: 0,
      limit: 100,
      pageNumber: 1,
    },
    payAgentModal: {
      isOpen: false,
      data: null,
    },
    payAchModal: {
      isOpen: false,
      data: null,
    },
    loading: {
      showTableLoading: false,
    },
    loadingModal: false,
    SelectedRows: [],
    selectedArray: [],
    note: "",
    isClassAdded: false,
    isOpenKycModal: false,
  };

  _onChangeDatePicker = (dateRangeValue) => {
    this.setState(
      { dateRangeValue, agentPayPeriodBreakdownTotalDue: 0 },
      () => {
        this._paginate();
        this._payPeriodSigningcompanyAgentList();
        this._persistFilter();
      }
    );
  };

  filterShowMobile = () => {
    this.setState((prevState) => ({
      isClassAdded: !prevState.isClassAdded,
    }));
  };

  // function to handle filter change and make subsequent api call to fetch filtered data
  _filterOnChange = (type, value, isDelay = false) => {
    const { filters } = deepClone(this.state);
    filters[type] = value;

    this.setState(
      {
        filters,
        SelectedRows: [],
        selectedArray: [],
        agentPayPeriodBreakdownTotalDue: 0,
      },
      () => {
        this.searchOnChange([
          this._paginate,
          this._payPeriodSigningcompanyAgentList,
          this._persistFilter,
        ]);
      }
    );
  };

  _filterForAgent = (type, value, isDelay = false) => {
    const { filters } = deepClone(this.state);
    filters[type] = value;

    this.setState({ filters, agentPayPeriodBreakdownTotalDue: 0 }, () => {
      if (isDelay) {
        this.searchOnChange([this._paginate]);
      } else {
        this._payPeriodSigningcompanyAgentList();
        this._paginate();
      }
    });
  };

  // function to reset filters, delete it from local storage and fetch data from api
  _resetFilter = () => {
    this.setState(
      {
        filters: {
          agent: "",
          state: "",
          search: "",
          status: "",
        },
        dateRangeValue: null,
      },
      () => {
        this._payPeriodSigningcompanyAgentList();
        this._fetchAllSigningcompanyPayPeriodBreakdown();
        deleteLocalStorageData("agentpayperiod");
      }
    );

    refreshFunc("company-accounting-agent-pay-period-breakdown-responsive");
  };

  componentDidMount = () => {
    this._payPeriodSigningcompanyAgentList();
    this._setFilterDataFromLocalStorage();

    HeaderEventEmitter.subscribe("reset-filter", () => {
      this._resetFilter();
      refreshFunc("company-accounting-agent-pay-period-breakdown");
    });
  };

  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);

    loading[loaderName] = value;

    this.setState({ loading });
  };

  _payPeriodSigningcompanyAgentList = async () => {
    try {
      this._manageLoading("showTableLoading", true);
      const { filters, dateRangeValue } = deepClone(this.state);

      const filterPayload = {};

      if (filters.state && filters.state.length)
        filterPayload["state"] = filters.state;

      if (dateRangeValue) {
        filterPayload["startDate"] = dateRangeValue[0];
        filterPayload["endDate"] = dateRangeValue[1];
      }

      if (filters.status && filters.status.trim().length)
        filterPayload["payStatus"] = filters.status.trim();

      if (filters.search && filters.search.length)
        filterPayload["search"] = filters.search;

      let payload = {
        filters: filterPayload,
      };
      let res = await payPeriodSigningcompanyAgentList(payload);
      this.setState({ agents: res?.agents || [] });
      this._manageLoading("showTableLoading", false);
    } catch (error) {
      errorHandler(error);
      this._manageLoading("showTableLoading", false);
    }
  };

  _fetchAllSigningcompanyPayPeriodBreakdown = async () => {
    try {
      this._manageLoading("showTableLoading", true);
      const { filters, tableConfig, dateRangeValue } = deepClone(this.state);

      const filterPayload = {};

      // creating filter payload
      if (filters.state && filters.state.length)
        filterPayload["state"] = filters.state;

      if (filters.agent && filters.agent.length)
        filterPayload["agentId"] = filters.agent;

      if (filters.status && filters.status.trim().length)
        filterPayload["payStatus"] = filters.status.trim();

      if (filters.search && filters.search.trim().length)
        filterPayload["search"] = filters.search.trim();

      if (dateRangeValue) {
        filterPayload["startDate"] = dateRangeValue[0];
        filterPayload["endDate"] = dateRangeValue[1];
      }
      tableConfig["filters"] = { ...filterPayload };

      let res = await fetchAllSigningcompanyPayPeriodBreakdown(tableConfig);

      this.setState({
        agentPayPeriodBreakdownList: res?.closings || [],
        agentPayPeriodBreakdownTotalCount: res?.totalCount || 0,
        agentPayPeriodBreakdownTotalDue: res?.totalDue || 0,
      });

      this._manageLoading("showTableLoading", false);
    } catch (error) {
      errorHandler(error);
      this._manageLoading("showTableLoading", false);
    }
  };

  _updateSigningCompanyPayPeriodBreakdown = async (action, note) => {
    const { filters, SelectedRows, dateRangeValue, payAgentModal } = deepClone(
      this.state
    );

    if (action === "email" || action === "duePayment" || action === "pay") {
      if (SelectedRows.length) {
        try {
          this._manageLoading("showTableLoading", true);

          const payload = {};

          if (filters.agent && filters.agent.length)
            payload["agentId"] = filters.agent;

          if (SelectedRows && SelectedRows.length > 0) {
            let SelectedRowsIds = SelectedRows.map((item) => item._id);
            payload["closingId"] = SelectedRowsIds;
          }
          payload["action"] = action;

          payload["payPeriodPaymentNotes"] =
            action === "pay" ? note : undefined;

          let res = await updateSigningCompanyPayPeriodBreakdown(payload);
          console.log(res);
          this._paginate();
          this._setSelectedRows();
          showToast(
            `${action
              .replace(/([a-z])([A-Z])/, "$1 $2")
              .charAt(0)
              .toUpperCase() +
            action.replace(/([a-z])([A-Z])/, "$1 $2").slice(1)
            } Done Successfully`,
            "success"
          );
          payAgentModal.isOpen && this._onTogglePayAgentModal();
          this._manageLoading("showTableLoading", false);
        } catch (error) {
          errorHandler(error);
          this._manageLoading("showTableLoading", false);
        }
      } else {
        showToast("Please Select Closings", "error");
      }
    } else if (action === "download") {
      if (SelectedRows.length) {
        const payload = {
          userId: getLoggedInUserId(),
        };

        if (dateRangeValue) {
          payload["startDate"] = dateRangeValue[0];
          payload["endDate"] = dateRangeValue[1];
        }

        if (filters.agent) payload["agentId"] = filters.agent;
        if (SelectedRows.length) {
          let selectedRowsIds = SelectedRows.map((item) => item._id);
          payload["closingId"] = JSON.stringify(selectedRowsIds);
        }
        const queryParams = structureQueryParams(payload);

        openUrlOnNewTab(
          `${BASE_URL}/signingcompany/download/pay-period${queryParams}`
        );
      } else {
        errorHandler({ reason: "Please Select Closings" });
      }
    }
  };

  _downloadAllPayPeriod = () => {
    const { filters, dateRangeValue } = deepClone(this.state);

    const payload = {
      userId: getLoggedInUserId(), // get user id of logged in user from redux
    };

    if (dateRangeValue) {
      payload["startDate"] = new Date(dateRangeValue[0]).toISOString();
      payload["endDate"] = new Date(dateRangeValue[1]).toISOString();
    }

    if (filters.agent) payload["agentId"] = filters.agent;

    if (filters.state) payload["state"] = filters.state;

    if (filters.status) payload["payStatus"] = filters.status.trim();

    if (filters.search) payload["search"] = filters.search.trim();

    const queryParams = structureQueryParams(payload); // structuring payload in url query params format

    openUrlOnNewTab(
      `${BASE_URL}/signingcompany/download/all/pay-period${queryParams}`
    );
  };

  _paginate = (pageNumber = 1, pageSize = 100) => {
    const { tableConfig, filters } = this.state;

    tableConfig.skip = (pageNumber - 1) * pageSize;
    tableConfig.limit = pageSize;
    tableConfig["pageNumber"] = pageNumber;

    this.setState({ tableConfig }, () => {
      if (filters.agent) {
        this._fetchAllSigningcompanyPayPeriodBreakdown();
      }
    });
  };

  _setSelectedRows = (SelectedRows = []) => {
    const { agentPayPeriodBreakdownList } = deepClone(this.state);

    SelectedRows = SelectedRows.filter(
      (each) => each?.payStatus !== "Paid"
    );
    const results = agentPayPeriodBreakdownList.filter((item) =>
      SelectedRows.some((each) => each.id === item.id)
    );

    this.setState({
      SelectedRows,
      selectedArray: results,
    });
  };

  _onTogglePayAgentModal = (data) => {
    try {
      let { payAgentModal } = JSON.parse(JSON.stringify(this.state));

      payAgentModal.isOpen = !payAgentModal.isOpen;
      this.setState({
        payAgentModal,
      });
    } catch (err) {
      errorHandler(err);
    }
  };

  _onToggleAddNotesModal = (isOpen = false, data = null) => {
    this.setState({
      addNotesModal: {
        isOpen,
        data,
      },
    });
  };

  _onTogglePayAchModal = (note) => {
    let { payAchModal } = JSON.parse(JSON.stringify(this.state));

    if (payAchModal.isOpen === false) {
      this._onTogglePayAgentModal();
    }

    payAchModal.isOpen = !payAchModal.isOpen;
    this.setState({
      payAchModal,
      note,
    });
  };

  _dataFormat = (cell, row, header) => {
    switch (header) {
      case "agentFee": {
        return cell ? formatCurrencyValue(cell) : "N/A";
      }
      case "total": {
        return row?.agentFee ? formatCurrencyValue(row?.agentFee) : "N/A";
      }

      case "notes": {
        return row?.payPeriodPaymentNotes ? (
          <>
            <Button
              color="link"
              className="actionBtn"
              data-toggle="tooltip"
              data-placement="top"
              title={`Note:- ${row?.payPeriodPaymentNotes}` || "N/A"}
            >
              <img
                src={require("../../../assets/img/nodes.svg").default}
                alt="notes"
              />
            </Button>
          </>
        ) : (
          "-"
        );
      }

      case "address": {
        return row.closingAddress ? (
          <div title={formatAddressInSingleText(row.closingAddress)}>
            {formatAddressInSingleText(row.closingAddress)}
          </div>
        ) : (
          "N/A"
        );
      }

      case "_borrower": {
        return (
          <div>
            {row?._borrower?.length > 0
              ? capitalize(row?._borrower[0]?.name?.full)
              : "N/A"}{" "}
            {/* show the below span only when there are more than 1 signers  */}
            {row?._borrower?.length > 1 ? (
              <Suspense fallback={<></>}>
                <SignerPopover
                  data={row?._borrower}
                  targetId={row?._id}
                  displayType="onlySignerNames"
                />
              </Suspense>
            ) : null}
          </div>
        );
      }

      case "city": {
        return row.closingAddress ? row.closingAddress?.city : "N/A";
      }

      case "serviceDate": {
        return row.appointmentDate ? (
          <>
            {formatDateAsPerTimeZOne(
              row.appointmentDate,
              row.closingAddress.timeZone
            )}
            {" | "}
            <span className="mr-1">
              {getTimeZoneAbbr(
                row.appointmentDate,
                row.closingAddress.timeZone
              )}
            </span>
          </>
        ) : (
          "N/A"
        );
      }

      case "payStatus": {
        return cell ? (
          <Badge
            color={
              cell === "Pending" || cell === "InReview"
                ? "warning"
                : cell === "DuePayment"
                  ? "danger"
                  : "success"
            }
            className="outline mr-2"
            pill
          >
            {cell
              .replace(/([a-z])([A-Z])/, "$1 $2")
              .charAt(0)
              .toUpperCase() + cell.replace(/([a-z])([A-Z])/, "$1 $2").slice(1)}
          </Badge>
        ) : (
          "N/A"
        );
      }
      default: {
        return cell;
      }
    }
  };

  _updateStatus = async (action, id) => {
    try {
      let { filters } = deepClone(this.state);

      let payload = {
        closingId: id,
        agentId: filters.agent,
        action: action,
      };

      this._manageLoading("showTableLoading", true);

      await updateSigningCompanyPayPeriodBreakdown(payload);
      this._payPeriodSigningcompanyAgentList();

      showToast(`Action is Successfully Done`, "success");
      this._manageLoading("showTableLoading", false);
    } catch (error) {
      errorHandler(error);
      this._manageLoading("showTableLoading", false);
    }
  };

  nextAgent = () => {
    const { agents, filters } = deepClone(this.state);

    let index = agents.findIndex((item) => filters.agent === item.id);

    // if index > max agents length, means we'are already at last point and can't go further right(next) side
    if (index !== agents.length - 1) {
      let agent = agents[index + 1].id;
      this._manageLoading("showTableLoading", true);

      filters["agent"] = agent;

      this.setState({ filters, agentPayPeriodBreakdownTotalDue: 0 }, () => {
        this.searchOnChange([this._paginate]);
      });
    }
  };

  prevAgent = () => {
    const { agents, filters } = deepClone(this.state);

    let index = agents.findIndex((item) => filters.agent === item.id);

    // if index <= 0, means we'are already at last point and can't go further left(previous) side
    if (index > 0) {
      let agent = agents[index - 1].id;
      this._manageLoading("showTableLoading", true);
      filters["agent"] = agent;

      this.setState({ filters, agentPayPeriodBreakdownTotalDue: 0 }, () => {
        this.searchOnChange([this._paginate]);
      });
    }
  };

  // a helper function to calculate total agent fee
  _calculateAgentTotalFee = () => {
    const { selectedArray } = deepClone(this.state);
    let arr = selectedArray.map((item) =>
      item.agentFee !== undefined ? item.agentFee : 0
    );
    return arr.reduce((a, b) => a + b, 0);
  };

  // functtion to handle payment
  _processPayment = async () => {
    let { filters, SelectedRows } = deepClone(this.state);

    if (SelectedRows.length) {
      this.setState({ loadingModal: true });

      let payload = {
        agentId: filters.agent,
        amount: this._calculateAgentTotalFee(),
        payPeriodPaymentNotes: this.state.note,
        totalAmount: Number((calculateTotalChargeWithFee(this._calculateAgentTotalFee()))?.totalCharge)
      };

      if (SelectedRows && SelectedRows.length > 0) {
        let SelectedRowsIds = SelectedRows.map((item) => item._id);
        payload["closingId"] = SelectedRowsIds;
      }
      try {
        let res = await companyPayAgent(payload);
        if (res?.isKycDone) {
          this._onTogglePayAchModal();
          this._paginate();
          this._setSelectedRows();
          console.log(res);
          this.setState({ note: "" });
          showToast(`Payment is Successfully Done`, "success");
        } else {
          this._onTogglePayAchModal();
          this._paginate();
          this._setSelectedRows();
          this._onToggleKycModal();
        }

        this.setState({ loadingModal: false });
      } catch (error) {
        errorHandler(error);
        this.setState({ loadingModal: false });
      }
    }
    return;
  };

  // persisting filters in local storage
  _persistFilter = () => {
    try {
      const { filters, dateRangeValue, tableConfig } = this.state;
      if (
        (filters &&
          ((filters.search && filters.search.length) ||
            filters.agent ||
            filters.state ||
            filters.status)) ||
        dateRangeValue !== null ||
        tableConfig.pageNumber !== 1
      ) {
        let data = { filters, dateRangeValue, tableConfig };
        localStorage.agentpayperiod = JSON.stringify(data);
      } else {
        delete localStorage.agentpayperiod;
      }
    } catch (err) {
      errorHandler(err);
    }
  };

  // fetching and setting filters from local storage and then making api call with those filters
  _setFilterDataFromLocalStorage = () => {
    if (localStorage && localStorage.agentpayperiod) {
      try {
        const filters = JSON.parse(localStorage.agentpayperiod);
        let dateRange = null;
        if (filters.dateRangeValue) {
          dateRange = [
            new Date(convertIsoToUtc(filters.dateRangeValue[0])),
            new Date(convertIsoToUtc(filters.dateRangeValue[1])),
          ];
        }
        this.setState(
          {
            filters: filters.filters,
            dateRangeValue: dateRange,
            tableConfig: filters?.tableConfig,
          },
          () => {
            this._payPeriodSigningcompanyAgentList();
            this._fetchAllSigningcompanyPayPeriodBreakdown();
          }
        );
      } catch (e) {
        this._payPeriodSigningcompanyAgentList();
        this._fetchAllSigningcompanyPayPeriodBreakdown();
      }
    } else {
      this._payPeriodSigningcompanyAgentList();
      this._fetchAllSigningcompanyPayPeriodBreakdown();
    }
  };

  // function to prepare filter data before API CALL  --  not being used here
  _prepareFilterData = () => {
    const { filters } = deepClone(this.state);

    if (!filters.agent) delete filters.agent;
    if (!filters.state) delete filters.state;
    if (!filters.status) delete filters.status;
    if (!filters.search?.trim()?.length) delete filters.search;

    return filters;
  };

  _cardHeaderFormat = (cell, row, header) => {
    switch (header) {
      case "name": {
        return row?.payStatus ? (
          <>
            <div className="cardTableTitle">
              <Label>Signer</Label>
              <div>
                <span>
                  {(row?._borrower &&
                    capitalize(row?._borrower[0]?.name?.full)) ||
                    "N/A"}{" "}
                </span>
                {/* show below data only if there are more than 1 signers */}
                {row?._borrower?.length > 1 ? (
                  <Suspense fallback={<></>}>
                    <SignerPopover
                      data={row?._borrower}
                      targetId={row?._id}
                      displayType="onlySignerNames"
                    />
                  </Suspense>
                ) : null}
              </div>
            </div>
          </>
        ) : (
          "N/A"
        );
      }
      default: {
        return cell;
      }
    }
  };

  _cardDataFormat = (row) => {
    return (
      <ul className="cardInfo">
        <li>
          <div className="dataWrap">
            <Label>Service Date </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.appointmentDate ? (
                <>
                  {formatDateAsPerTimeZOne(
                    row?.appointmentDate,
                    row?.closingAddress?.timeZone
                  )}
                  {" | "}
                  <>
                    {getTimeZoneAbbr(
                      row?.appointmentDate,
                      row?.closingAddress?.timeZone
                    )}
                  </>
                </>
              ) : (
                "N/A"
              )}
            </div>
          </div>
          <div className="dataWrap">
            <Label>Notes </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.payPeriodPaymentNotes ? (
                <>
                  <Button
                    color="link"
                    className="actionBtn"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={`Note:- ${row?.payPeriodPaymentNotes}` || "N/A"}
                  >
                    <img
                      src={require("../../../assets/img/nodes.svg").default}
                      alt="notes"
                    />
                  </Button>
                </>
              ) : (
                "-"
              )}
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Address </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.closingAddress ? (
                <div title={formatAddressInSingleText(row?.closingAddress)}>
                  {formatAddressInSingleText(row?.closingAddress)}
                </div>
              ) : (
                "N/A"
              )}
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>City </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.closingAddress ? row?.closingAddress?.city : "N/A"}
            </div>
          </div>
          <div className="dataWrap">
            <Label>Fee </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.agentFee ? formatCurrencyValue(row?.agentFee) : "N/A"}
            </div>
          </div>

          <div className="dataWrap">
            <Label>Total </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.agentFee ? formatCurrencyValue(row?.agentFee) : "N/A"}
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap d-flex align-items-center justify-content-between">
            <Label>Payment</Label>
            {/* showing different badge color based on pay status*/}
            <Badge
              color={
                row?.payStatus === "Pending" || row?.payStatus === "InReview"
                  ? "warning"
                  : row?.payStatus === "DuePayment"
                    ? "danger"
                    : "success"
              }
              className="outline mr-2"
              pill
            >
              {row?.payStatus
                ?.replace(/([a-z])([A-Z])/, "$1 $2")
                .charAt(0)
                .toUpperCase() +
                row?.payStatus?.replace(/([a-z])([A-Z])/, "$1 $2").slice(1)}
            </Badge>
          </div>
        </li>
      </ul>
    );
  };

  // debounce function for search field with 1 sec waiting time
  _onToggleKycModal = () => {
    this.setState({ isOpenKycModal: !this.state.isOpenKycModal });
  };

  _sendKycRequest = async () => {
    try {
      const res = await sendKycRequest(this.state.filters.agent);
      if (!res?.error) {
        showToast("KYC request sent successfully", "success");
        this._onToggleKycModal();
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  searchOnChange = debounce((func) => {
    func.forEach((item) => item());
  }, 1000);

  render() {
    const {
      dateRangeValue,
      filters,
      agents,
      agentPayPeriodBreakdownHeaderKeys,
      tableConfig,
      loading,
      agentPayPeriodBreakdownList,
      agentPayPeriodBreakdownTotalCount,
      agentPayPeriodBreakdownTotalDue,
      SelectedRows,
      isClassAdded,
      selectedArray,
      agentPayPeriodBreakdownCardHeaderKeys,
    } = this.state;

    const { isForMobile } = this.props;

    return (
      <>
        <div className="content">

          <div className="responsiveTitle">
            <h2>Agent Pay Period Breakdown</h2>

            <div className="rightSide">
              <Button id="company-accounting-agent-pay-period-breakdown-responsive" color="link" onClick={this._resetFilter}>
                <SvgIcons type="refresh" />
              </Button>

              <Button
                className="filterBarIcon"
                color="link"
                onClick={this.filterShowMobile}
              >
                <SvgIcons type="filterIcon" />
              </Button>
            </div>
          </div>

          {/* search filed for responsive will show below 767px */}
          <div className="mobileSearchFiled">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <img
                    src={require("../../../assets/img/searchIcon.svg").default}
                    alt="searchIcon"
                  />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                value={filters.search}
                name="search"
                onChange={(e) => this._filterOnChange("search", e.target.value)}
                placeholder="Search.."
              />
            </InputGroup>
          </div>

          {/* filter for tablet and web */}
          <div
            onClick={this.handleButtonClick}
            className={`mobileFilterView ${isClassAdded ? "show" : ""}`}
          ></div>
          <div
            className={`filterContainer responsiveFilter ${isClassAdded ? "show" : ""
              }`}
          >
            <div className="filterIcon">
              <SvgIcons type="filterIcon" />
              Filter by
            </div>
            <div className="mobileTitle">
              <h2>Filter by</h2>
              <Button
                color="link"
                className="closeButton"
                onClick={this.filterShowMobile}
              >
                <img
                  src={require("../../../assets/img/close_grey.svg").default}
                  alt="close"
                  height={12}
                />
              </Button>
            </div>
            <div className="filterWrapper">
              <FormGroup className="searchTable">
                <Label>Search</Label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <img
                        src={
                          require("../../../assets/img/searchIcon.svg").default
                        }
                        alt="searchIcon"
                      />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    value={filters.search}
                    name="search"
                    onChange={(e) =>
                      this._filterOnChange("search", e.target.value)
                    }
                    placeholder="Search.."
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup className="dateRange">
                <Label>Date Range</Label>
                <DateRangePicker
                  className="dateRange"
                  format="MM-dd-y"
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                  onChange={(dateRangeValue) =>
                    this._onChangeDatePicker(dateRangeValue)
                  }
                  value={dateRangeValue}
                />
              </FormGroup>
              <FormGroup>
                <Label>State</Label>
                <div className="custom-select-wrapper">
                  <Input
                    type="select"
                    value={filters.state}
                    name="state"
                    onChange={(e) =>
                      this._filterOnChange("state", e.target.value)
                    }
                  >
                    <option value="">All</option>
                    {usaStates.map((state) => (
                      <option
                        key={state.abbreviation}
                        value={state.abbreviation}
                      >
                        {state.name}
                      </option>
                    ))}
                  </Input>
                </div>
              </FormGroup>
              <FormGroup>
                <Label>Status</Label>
                <div className="custom-select-wrapper">
                  <Input
                    type="select"
                    value={filters.status}
                    name="status"
                    onChange={(e) =>
                      this._filterOnChange("status", e.target.value)
                    }
                  >
                    <option value="">All</option>
                    <option value="Pending">Pending</option>
                    <option value="InReview">In Review</option>
                    <option value="DuePayment">Due Payment</option>
                    <option value="Paid">Paid</option>
                  </Input>
                </div>
              </FormGroup>
              <FormGroup>
                <Label>Agent</Label>
                <div className="d-flex align-items-center">
                  <Button
                    color="link"
                    className="pr-3"
                    onClick={() => this.prevAgent()}
                  >
                    <img
                      src={require("../../../assets/img/arrowLeft.svg").default}
                      height={14}
                      alt="final"
                    />
                  </Button>
                  <div className="custom-select-wrapper w-100">
                    <Input
                      type="select"
                      value={filters.agent}
                      name="agent"
                      onChange={(e) =>
                        this._filterOnChange("agent", e.target.value)
                      }
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      {agents.map((agent) => (
                        <option key={agent._id} value={agent._id}>
                          {capitalize(agent?.name?.full)}
                        </option>
                      ))}
                    </Input>
                  </div>
                  <Button
                    color="link"
                    className="pl-3"
                    onClick={() => this.nextAgent()}
                  >
                    <img
                      src={
                        require("../../../assets/img/arrowRight.svg").default
                      }
                      height={14}
                      alt="final"
                    />
                  </Button>
                </div>
              </FormGroup>
            </div>
            <div className="clearButton">
              <Button size="md" color="primary" onClick={this.filterShowMobile}>
                Close
              </Button>
            </div>
          </div>

          <Row>
            <Col md="12">
              <div className="agentPayPeriod">
                {/* when the checkboxes in the below table are selected/checked then below text is to be shown */}
                <div style={{ fontWeight: 500, fontSize: 12 }}>
                  {SelectedRows.length} Records are selected
                </div>

                <div className="buttonAction">
                  {filters.status === "DuePayment" ? (
                    <Button
                      color="primary"
                      size="md"
                      outline
                      onClick={() => this._downloadAllPayPeriod("download")}
                    >
                      Download All
                    </Button>
                  ) : null}

                  <Button
                    color="primary"
                    outline
                    size="md"
                    onClick={() =>
                      this._updateSigningCompanyPayPeriodBreakdown("download")
                    }
                    disabled={
                      loading.showTableLoading ||
                      agentPayPeriodBreakdownTotalCount === 0
                    }
                  >
                    Download
                  </Button>

                  <UncontrolledDropdown>
                    <DropdownToggle color="primary">
                      Action{" "}
                      <img
                        src={
                          require("../../../assets/img/chevron_white.svg")
                            .default
                        }
                        alt="action"
                      />
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem
                        onClick={() =>
                          this._updateSigningCompanyPayPeriodBreakdown("email")
                        }
                        disabled={
                          loading.showTableLoading ||
                          agentPayPeriodBreakdownTotalCount === 0
                        }
                      >
                        {" "}
                        Email
                      </DropdownItem>
                      <DropdownItem
                        onClick={() =>
                          this._updateSigningCompanyPayPeriodBreakdown(
                            "duePayment"
                          )
                        }
                        disabled={
                          loading.showTableLoading ||
                          agentPayPeriodBreakdownTotalCount === 0
                        }
                      >
                        {" "}
                        Review Complete
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => this._onTogglePayAgentModal()}
                        disabled={
                          loading.showTableLoading ||
                          agentPayPeriodBreakdownTotalCount === 0
                        }
                      >
                        {" "}
                        Pay
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </div>
              {isForMobile ? (
                <Suspense fallback={<></>}>
                  <div className="hideDesktop">
                    <CustomCard
                      isPageStartFromOne={true}
                      pageNumber={tableConfig.pageNumber}
                      tableData={agentPayPeriodBreakdownList}
                      headerKeys={agentPayPeriodBreakdownCardHeaderKeys}
                      dataFormat={this._dataFormat}
                      totalCount={agentPayPeriodBreakdownTotalCount}
                      onPaginate={(pageNumber, pageSize) =>
                        this._paginate(pageNumber, pageSize)
                      }
                      rowSelection={true}
                      selectedRows={SelectedRows}
                      setSelectedRows={(data) => this._setSelectedRows(data)}
                      showTableLoading={loading.showTableLoading}
                      cardHeaderFormat={this._cardHeaderFormat}
                      cardDataFormat={this._cardDataFormat}
                    />
                  </div>
                </Suspense>
              ) : (
                <Suspense fallback={<></>}>
                  <div className="hideMobile">
                    {agentPayPeriodBreakdownHeaderKeys &&
                      agentPayPeriodBreakdownHeaderKeys.length && (
                        <CustomTable
                          isPageStartFromOne={true}
                          pageNumber={tableConfig.pageNumber}
                          tableData={agentPayPeriodBreakdownList}
                          headerKeys={agentPayPeriodBreakdownHeaderKeys}
                          dataFormat={this._dataFormat}
                          totalCount={agentPayPeriodBreakdownTotalCount}
                          rowSelection={true}
                          onPaginate={(pageNumber, pageSize) =>
                            this._paginate(pageNumber, pageSize)
                          }
                          onSortChange={(sortName, sortOrder) =>
                            this._onSortChange(sortName, sortOrder)
                          }
                          showTableLoading={loading.showTableLoading}
                          selectedRows={SelectedRows}
                          setSelectedRows={(data) =>
                            this._setSelectedRows(data)
                          }
                        />
                      )}
                  </div>
                </Suspense>
              )}
              <div className="tableTotal">
                <p>Total Due</p>
                <p>
                  {agentPayPeriodBreakdownTotalDue
                    ? formatCurrencyValue(agentPayPeriodBreakdownTotalDue)
                    : "$0"}
                </p>
              </div>
            </Col>
          </Row>
          <Suspense fallback={<></>}>
            <PayAgentModal
              isOpen={this.state.payAgentModal.isOpen}
              data={selectedArray}
              toggle={() => this._onTogglePayAgentModal()}
              openAchModal={(note) => this._onTogglePayAchModal(note)}
              recordPayment={(note) =>
                this._updateSigningCompanyPayPeriodBreakdown("pay", note)
              }
            />
          </Suspense>

          <Elements>
            <Suspense fallback={<></>}>
              <AccountComponentModal
                loadingModal={this.state.loadingModal}
                isOpen={this.state.payAchModal.isOpen}
                toggle={() => this._onTogglePayAchModal()}
                processPayment={() => this._processPayment()}
                manageLoading={(value) =>
                  this.setState({ loadingModal: value })
                }
              />
            </Suspense>
          </Elements>
          <ShowKycTextModal
            heading="Send KYC Request"
            isOpen={this.state.isOpenKycModal}
            data="User has not added their bank account or done their KYC. Click on the button below to send KYC request."
            toggle={() => this._onToggleKycModal()}
            sendKycRequest={() => this._sendKycRequest()}
          />
        </div>
      </>
    );
  }
}

export default ScreenWidthHOC(AgentPayPeriodBreakdownCompany);
