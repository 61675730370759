import React, { useState } from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import { handleAuthClick, initializeClient } from "../../../helper-methods/googleCalendarService";
import { calenderGoogleAuth } from "../../../http/http-calls";
import { errorHandler } from "../../../helper-methods";
import { APP_URL } from "../../../config";
import myGif from "../../../assets/img/Google Calendar.gif";
import { store as REDUX_STORE } from "../../../redux/store";

const calenderAuthPage = (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [loading, setLoading] = useState(false);
  
  const url = window.location.href;
  const urlObj = new URL(url);
  const redirctUrl = urlObj.searchParams.get("redirctUrl");
  const token = urlObj.searchParams.get("token");
  const { userData } = REDUX_STORE.getState();
  
  const _connectHandler = async () => {

    try {
      await initializeClient()
      const res = await handleAuthClick();
      if (res) {
        setLoading(true);
        await calenderGoogleAuth({
          code: res,
          token: token,
          redirectUris: APP_URL,
          //for localhost test
          // redirectUris: "http://localhost:3000"
        });
        setLoading(false);
        // props.history.push(`/${userData?.type}/closing-master-schedule`);
      }
    } catch (error) {
      errorHandler(error);
      // props.history.push(`/${userData?.type}/closing-master-schedule`);
    }
    window.location.replace(redirctUrl)
  };

  return (
    <div className="fullPageContainer">
      <div className="resetWrapper">
        <img
          src="https://res.cloudinary.com/www-logic-square-com/image/upload/v1635332672/Jabra%20Fan%20Emailer/closewise-logo.png"
          alt="Closewise Logo"
          className="projectLogo"
        />

        <div className="resetForm">
          <div className="svg-container">
            <img
              src={require("../../../assets/img/calenderAuth.svg").default}
              alt="Verify"
              className="svg-image"
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
            <img 
              src={myGif} 
              alt="Description of GIF" 
              style={{ width: '200px', height: 'auto' }} 
            />
          </div>

          <div className="buttonContainer">
            <Button
              color="primary"
              className="authButton"
              onClick={() => _connectHandler()}
              disabled={loading}
            >
              <img
                src={require("../../../assets/img/Google-calandar.png")}
                alt="google calandar"
                height={18}
              />{" "}
              Connect google Calendar
            </Button>
          </div>

          <p style={{ fontWeight: "500", textAlign: "center" }}>
            <Link to={`/${userData?.type}/closing-master-schedule`}>Back to App</Link>
          </p>
        </div>

        <div className="loginFooter">
          <span>© 2024 Closewise</span>
        </div>
      </div>
    </div>
  );
};

export default calenderAuthPage;