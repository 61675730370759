import React, { Suspense, lazy } from "react";
import { connect } from "react-redux";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import {
  deepClone,
  errorHandler,
  getCountryDialCodeFromCountryCode,
  showToast,
  uploadFileOnServer,
} from "../../../helper-methods";
import {
  addAgentProfileDetail,
  editAgentProfileDetail,
  getAgentProfileDetail,
  getRonPlatformTypes,
  updateAgentProfileDetail,
} from "../../../http/http-calls";
import { hideLoader, showLoader } from "../../../redux/actions/loader-data";
import { updateUserObj } from "../../../redux/actions/user-data";
import SvgIcons from "../components/SvgIcons";

const AgentProfileCredentials = lazy(() =>
  import("../components/agent-profile-credentials")
);
const AgentProfileDetails = lazy(() =>
  import("../components/agent-profile-details")
);
const AgentProfileSchedule = lazy(() =>
  import("../components/agent-profile-schedule")
);
const AgentBankInfo = lazy(() => import("../components/AgentBankInfo"));
const ManageReviewLinks = lazy(() => import("../components/ManageReviewLinks"));

class AgentProfile extends React.Component {
  state = {
    agentDetails: null,
    activeTab: "1",
    loading: false,
    ronPlatformOptions: [],
  };

  componentDidMount = async () => {
    try {
      // getting tabId from url and setting current activeTab
      let tabId = this.props.location.search.replace(/[^\w\s]/gi, "");
      if (tabId) {
        this.setState({ activeTab: tabId });
      }

      this.props.showLoader("Fetching Details...");
      await this._getAgentProfileDetail();
      this._getRonPlatformType();
      this.props.hideLoader();
    } catch (error) {
      errorHandler(error);
      this.props.hideLoader();
    }
  };

  _getRonPlatformType = async () => {
    try {
      const response = await getRonPlatformTypes();

      if (!response?.error) {
        const convertedArray =
          (response?.ronPlatformTypes &&
            Object.entries(response?.ronPlatformTypes).map(
              ([label, value]) => ({ label, value })
            )) ||
          [];
        this.setState({ ronPlatformOptions: convertedArray });
      }
    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  };

  _getAgentProfileDetail = () => {
    return new Promise((resolve, reject) => {
      getAgentProfileDetail()
        .then((res) => {
          this.setState({ agentDetails: res?.agent });
          this.props.updateUserObj(res?.agent);
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  _updateOrAddAgentProfileDetail = (data, toastSuccessMsg = null) => {
    return new Promise((resolve, reject) => {
      this.setState({ loading: true });

      const { agentDetails } = deepClone(this.state);
      // FOR EDIT
      updateAgentProfileDetail(agentDetails._id, data)
        .then(async (res) => {
          await this._getAgentProfileDetail();
          showToast(
            toastSuccessMsg ? toastSuccessMsg : "Agent Details Updated",
            "success"
          );
          this.setState({ loading: false });
          resolve(true);
        })
        .catch((error) => {
          errorHandler(error);
          this.setState({ loading: false });
          resolve(false);
        });
    });
  };

  _addAgentLicense = (data, toastSuccessMsg = null) => {
    return new Promise((resolve, reject) => {
      this.setState({ loading: true });

      const { agentDetails } = deepClone(this.state);
      addAgentProfileDetail(agentDetails._id, data)
        .then(async (res) => {
          await this._getAgentProfileDetail();
          showToast(
            toastSuccessMsg ? toastSuccessMsg : "Add Agent Detail Successfully",
            "success"
          );
          this.setState({ loading: false });
          resolve(true);
        })
        .catch((error) => {
          errorHandler(error);
          this.setState({ loading: false });
          resolve(false);
        });
    });
  };

  _editAgentLicense = (data, toastSuccessMsg = null) => {
    return new Promise((resolve, reject) => {
      this.setState({ loading: true });

      const { agentDetails } = deepClone(this.state);
      editAgentProfileDetail(agentDetails._id, data)
        .then(async (res) => {
          await this._getAgentProfileDetail();
          showToast(
            toastSuccessMsg ? toastSuccessMsg : "Add Agent Detail Successfully",
            "success"
          );
          this.setState({ loading: false });
          resolve(true);
        })
        .catch((error) => {
          errorHandler(error);
          this.setState({ loading: false });
          resolve(false);
        });
    });
  };

  _toggleTab = async (tab) => {
    const { loading, activeTab } = deepClone(this.state);
    if (!loading && tab !== activeTab) {
      this.setState({ activeTab: tab });
    }
  };

  _onSubmitBasicDetail = async (basicDetails) => {
    try {
      this.setState({ loading: true });

      const countryDialCode = await getCountryDialCodeFromCountryCode(
        basicDetails.phoneCountry.value
      );

      let splitName = basicDetails.name.value.trim().split(" ");

      if (splitName?.length) {
        splitName = {
          first:
            splitName.length > 1
              ? splitName.slice(0, -1).join(" ")
              : splitName[0],
          last: splitName.length > 1 ? splitName[splitName.length - 1] : "",
        };
      }

      const payload = {
        name: {
          first: splitName.first?.trim() ? splitName.first.trim() : "",
          last: splitName.last?.trim() ? splitName.last.trim() : "",
        },
        email: basicDetails?.email?.value,
        phoneCountry: basicDetails.phoneCountry.value,
        address: basicDetails?.address,
        storeAddress: basicDetails?.storeAddress,
        workingStates: basicDetails?.workingStates?.value,
        languagesKnown: basicDetails?.languagesKnown?.value,
        ronPlatformTypes: basicDetails.ronPlatformTypes.value.map(
          (item) => item.value
        ),
        isProvideMobileServices:
          basicDetails?.isProvideMobileServices?.value || false,
        isProvideInStoreServices:
          basicDetails?.isProvideInStoreServices?.value || false,
        userName: basicDetails?.userName?.value,
      };

      if (countryDialCode && basicDetails.phone.value) {
        payload["phone"] = `(${countryDialCode})${basicDetails.phone.value}`;
      }

      if (
        basicDetails?.profilePicture?.file &&
        basicDetails?.profilePicture?.file?.uploadData
      ) {
        const uploadedFilesRes = await uploadFileOnServer([
          basicDetails?.profilePicture?.file,
        ]);
        payload["profilePicture"] = uploadedFilesRes[0].url;
      }
      if (
        basicDetails?.coverPhoto?.file &&
        basicDetails?.coverPhoto?.file?.uploadData
      ) {
        const uploadedFilesRes = await uploadFileOnServer([
          basicDetails?.coverPhoto?.file,
        ]);
        payload["coverPhoto"] = uploadedFilesRes[0].url;
      }
      if (basicDetails.password.value) {
        payload["password"] = `${basicDetails?.password?.value}`;
      }

      payload["nnaProfile"] = basicDetails?.nnaProfile?.value
        ? basicDetails?.nnaProfile?.value
        : "";
      payload["websiteUrl"] = basicDetails?.websiteUrl?.value
        ? basicDetails?.websiteUrl?.value
        : "";
      payload["bio"] = basicDetails?.bio?.value ? basicDetails?.bio?.value : "";
      payload["linkedInUrl"] = basicDetails?.linkedInUrl?.value
        ? basicDetails?.linkedInUrl?.value
        : "";

      payload["businessName"] = basicDetails?.businessName?.value?.trim()
        ? basicDetails?.businessName?.value
        : "";

      if (basicDetails?.businessName?.value?.trim()) {
        payload["isBusinessNameUsed"] =
          basicDetails?.isBusinessNameUsed?.value || false;
      } else {
        payload["isBusinessNameUsed"] = false;
      }

      payload["ronPlatformTypes"] = basicDetails?.ronPlatformTypes?.value
        ?.length
        ? basicDetails?.ronPlatformTypes?.value?.map((each) => each?.value)
        : [];

      this._updateOrAddAgentProfileDetail(payload, "Agent Profile Updated");
    } catch (error) {
      errorHandler(error);
      this.setState({ loading: false });
    }
  };

  _onSubmitCredentialDetail = (credentialName, credentialDetail) => {
    return new Promise(async (resolve, reject) => {
      try {
        this.setState({ loading: true });

        const payload = {};

        if (
          credentialName === "notaryLicense" ||
          credentialName === "barLicense"
        ) {
          payload["_id"] = credentialDetail?._id?.value
            ? credentialDetail?._id?.value
            : undefined;
          payload["licenseNumber"] =
            credentialDetail.licenseNumber.value.trim();
          payload["state"] = credentialDetail.state.value.trim();
          payload["isChanged"] = credentialDetail.isChanged;
        }

        if (credentialName === "ronCredentials") {
          payload["_id"] = credentialDetail?._id?.value
            ? credentialDetail?._id?.value
            : undefined;
          payload["state"] = credentialDetail.state.value.trim();
        }

        if (credentialName === "eoInsurance") {
          payload["companyName"] = credentialDetail.companyName.value.trim();
          payload["policyNumber"] = credentialDetail.policyNumber.value.trim();
          payload["amount"] = credentialDetail.amount.value.trim();
        }

        if (credentialName === "backgroundCheckReport")
          payload["nameOfCompany"] =
            credentialDetail.nameOfCompany.value.trim();

        if (
          credentialName === "notaryLicense" ||
          credentialName === "eoInsurance" ||
          credentialName === "backgroundCheckReport" ||
          credentialName === "barLicense"
        ) {
          payload["title"] = credentialDetail?.title.value.trim()
            ? credentialDetail.title.value
            : undefined;
          payload["url"] = credentialDetail?.document.file.previewBlob
            ? credentialDetail.document.file.previewBlob
            : undefined;
          payload["docType"] = credentialDetail?.docType.value
            ? credentialDetail.docType.value
            : undefined;
        }

        if (credentialName === "w9Credentials")
          payload["certficationYear"] = credentialDetail.certficationYear.value;

        if (
          credentialName === "notaryLicense" ||
          credentialName === "eoInsurance" ||
          credentialName === "backgroundCheckReport"
        )
          payload["expiredOn"] = credentialDetail.expiredOn.value;

        if (
          credentialDetail.document.file &&
          credentialDetail.document.file.uploadData
        ) {
          const uploadedFilesRes = await uploadFileOnServer([
            credentialDetail.document.file,
          ]);
          payload["url"] = uploadedFilesRes[0].url;
          payload["docType"] = uploadedFilesRes[0].docType;
          payload["title"] = uploadedFilesRes[0].title;
        }

        let isSuccess;

        if (payload && Object.keys(payload).length) {
          if (
            credentialName === "notaryLicense" ||
            credentialName === "barLicense" ||
            credentialName === "ronCredentials"
          ) {
            isSuccess = this._agentLicense(
              { [credentialName]: payload },
              credentialName,
              "Agent Credential Updated"
            );
          } else {
            isSuccess = await this._updateOrAddAgentProfileDetail(
              { [credentialName]: payload },
              "Agent Credential Updated"
            );
          }
        } else {
          isSuccess = true;
          this.setState({ loading: false });
        }
        this._getAgentProfileDetail();
        resolve(isSuccess);
      } catch (error) {
        errorHandler(error);
        this.setState({ loading: false });
        resolve(false);
      }
    });
  };

  _agentLicense = (payload, credentialName, toastSuccessMsg) => {
    if (payload[credentialName].isChanged && payload[credentialName]._id) {
      payload.type = "edit";
      delete payload[credentialName].isChanged;
      this._editAgentLicense(payload, toastSuccessMsg);
    } else {
      if (payload[credentialName]._id === undefined) {
        delete payload[credentialName].isChanged;
        this._addAgentLicense(payload, toastSuccessMsg);
      }
    }
  };

  render() {
    const { activeTab, agentDetails, loading } = this.state;
    // percentage is upload file percentage value
    const { percentage } = this.props;

    return (
      <>
        <div className="content">
          <div className="responsiveTitle">
            <h2>Profile</h2>
          </div>

          <div className="verticalTabs">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={activeTab === "1" ? "active" : ""}
                  onClick={() => {
                    this._toggleTab("1");
                  }}
                >
                  <SvgIcons type={"basicDetails"} />
                  Basic Details
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "2" ? "active" : ""}
                  onClick={() => {
                    this._toggleTab("2");
                  }}
                >
                  <SvgIcons type={"myCredentials"} />
                  My Credentials
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "3" ? "active" : ""}
                  onClick={() => {
                    this._toggleTab("3");
                  }}
                >
                  <SvgIcons type={"mySchedule"} />
                  My Schedule
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "4" ? "active" : ""}
                  onClick={() => {
                    this._toggleTab("4");
                  }}
                >
                  <SvgIcons type={"bankInfo"} />
                  Bank Info
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "5" ? "active" : ""}
                  onClick={() => {
                    this._toggleTab("5");
                  }}
                >
                  <SvgIcons type={"manageReview"} />
                  {/* Manage Review Links */}
                  Social/ Business Links
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={activeTab}>
              {/* basic detail page */}
              <TabPane tabId="1" className="BasicDetailTab">
                <Suspense fallback={<></>}>
                  <AgentProfileDetails
                    {...this.props}
                    activeTab={activeTab}
                    agentDetails={agentDetails}
                    percentage={percentage}
                    loading={loading}
                    ronPlatformOptions={this.state.ronPlatformOptions}
                    onSubmitBasicDetail={this._onSubmitBasicDetail}
                  />
                </Suspense>
              </TabPane>

              {/* My Credentials form start */}
              <TabPane tabId="2">
                <Suspense fallback={<></>}>
                  <AgentProfileCredentials
                    {...this.props}
                    activeTab={activeTab}
                    agentDetails={agentDetails}
                    percentage={percentage}
                    loading={loading}
                    getAgentProfileDetail={() => this._getAgentProfileDetail()}
                    deleteLicense={this._editAgentLicense}
                  />
                </Suspense>
              </TabPane>

              {/* My Schedule form start */}
              <TabPane tabId="3">
                <Suspense fallback={<></>}>
                  <AgentProfileSchedule
                    activeTab={activeTab}
                    agentDetails={agentDetails}
                    onSubmitSchedule={this._updateOrAddAgentProfileDetail}
                  />
                </Suspense>
              </TabPane>

              <TabPane tabId="4">
                <Suspense fallback={<></>}>
                  <AgentBankInfo
                    activeTab={activeTab}
                    agentDetails={agentDetails}
                    loading={loading}
                    getAgentProfileDetail={() => this._getAgentProfileDetail()}
                  />
                </Suspense>
              </TabPane>
              <TabPane tabId="5">
                <Suspense fallback={<></>}>
                  {agentDetails && (
                    <ManageReviewLinks agentDetails={agentDetails} />
                  )}
                </Suspense>
              </TabPane>
            </TabContent>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    percentage:
      state.uploadFilePercentage && state.uploadFilePercentage.percentage
        ? state.uploadFilePercentage.percentage
        : "0",
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserObj: (user) => dispatch(updateUserObj(user)),
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AgentProfile);
