export const BASE_URL =
  process.env.REACT_APP_BACKEND_ENV === "live"
    ? "https://api.closewise.com/api/v1"
    : // : "https://api-closewise.logic-square.com/api/v1";
      "https://api-dev.closewise.com/api/v1";
// " https://5b50-27-58-61-116.ngrok-free.app/api/v1";

export const FRONTEND_BASE_URL =
  process.env.REACT_APP_BACKEND_ENV === "live"
    ? "https://api.closewise.com"
    : //"https://api-closewise.logic-square.com";
      "https://api-dev.closewise.com";

export const cloudinaryConfig = {
  APIKey: "alphatec",
  uploadPreset: "fzxhy6mj",
};

export const APP_URL =
  process.env.REACT_APP_BACKEND_ENV === "live"
    ? "https://app.closewise.com"
    : "https://devapp.closewise.com";
// :"https://beta.closewise.com"

export const ONE_SIGNAL_APPID =
  process.env.REACT_APP_BACKEND_ENV === "live"
    ? "f0ca63f1-0f62-4417-95dd-0daee23e4d49" // for live
    : "a04c1362-f6ef-4154-b4f9-89a3e6d3576c"; //dev

// Client ID and API key from the Developer Console
export const CLIENT_ID =
  // process.env.REACT_APP_BACKEND_ENV === "live"?
  "515029526704-5skvccofjf099h84mp9pvivpm83dr87n.apps.googleusercontent.com"; // live
// : "794955683280-1sbpoekkamauae788gh9qq1i6a6m6unk.apps.googleusercontent.com"; // dev

export const API_KEY =
  // process.env.REACT_APP_BACKEND_ENV === "live"
  "AIzaSyDN2CVcN67_tD2sF3pFmmbh52xcfquo1o8"; // live
// : "AIzaSyCwJvotGdHzWJiHTjwIoU1c6R_2ezBqb2c"; // dev

export const SOCKET_BASE_URL =
  // "http://54.146.76.158:3500";
  process.env.REACT_APP_BACKEND_ENV === "live"
    ? "https://socket-api.closewise.com"
    : // "https://api-closewise.logic-square.com";
      "https://api-dev.closewise.com";

// process.env.REACT_APP_BACKEND_ENV === "live"
// Array of API discovery doc URLs for APIs used by the quickstart
// DISCOVERY_DOCS: [
//   "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
// ],

// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
// https://www.googleapis.com/auth/calendar
export const SCOPES = `https://www.googleapis.com/auth/calendar.calendarlist https://www.googleapis.com/auth/calendar.calendars.readonly https://www.googleapis.com/auth/calendar.events.readonly https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid`;

export const STRIPE_API_KEY =
  process.env.REACT_APP_BACKEND_ENV === "live"
    ? "pk_live_51JM5BpJJ8ouXvyZlMWsAc5EyyFlrK7bcKOD1UupgxZFhMEkRHmG7nlD5JVLuGn0fJnW01FCnPymUrJyXP4pU6ZNO001kSZGAYv"
    : "pk_test_51J2aemGbmF3o6wbBym2X2PoGgzlmaqYKhDag9CwgDQMWlHrlA3KbCUq1VMQBWmXftzQ0v79gvfysh78LBTLF4tI600I2EiO6Bg";

export const AWS_IMAGE_BUCKET_NAME =
  process.env.REACT_APP_BACKEND_ENV === "live"
    ? "closewise-live-media-storage-bucket"
    : "closewise-dev-media-storage-bucket";

export const AWS_PDF_BUCKET_NAME =
  process.env.REACT_APP_BACKEND_ENV === "live"
    ? "closewise-live-media-storage-bucket"
    : "closewise-dev-media-storage-bucket";

export const AWS_VIDEO_BUCKET_NAME = "closewise-video";
export const DEFAULT_PROFILE_PICTURE =
  require("../assets/img/default_user_icon.svg").default;
export const DEFAULT_COVER_PICTURE = require("../assets/img/placeholder-img.png");
export const app_name = "CloseWise";
export const app_logo = "/assets/img/closewise-white-logo.png";
export const app_full_logo = "/assets/img/closewise.png";

//linkedin
export const LinkedInClientId = "77biwla92ozypu";
export const LinkedInRedirectUri =
  process.env.REACT_APP_BACKEND_ENV === "live"
    ? "https://app.closewise.com/agent/marketing/social-media-management?linkedin/"
    : "https://devapp.closewise.com/agent/marketing/social-media-management?linkedin/";
export const LinkedInScpoe = "email profile";
export const LinkedInClientSecret = "9n8noaEofCgl8OP3";

// Facebook
export const FacebookClientId = "245789971783100";
export const FacebookRedirectUri =
  process.env.REACT_APP_BACKEND_ENV === "live"
    ? "https://app.closewise.com/agent/marketing/social-media-management/"
    : "https://devapp.closewise.com/agent/marketing/social-media-management/";

// NNM link
export const nnmLink =
  process.env.REACT_APP_BACKEND_ENV === "live"
    ? "https://notarynearme.com"
    : "https://devapp.notarynearme.com";

// Gleap
export const GLEAP_CHECKLIST_OUTBOUNDID =
  process.env.REACT_APP_BACKEND_ENV === "live"
    ? "659384fc216ebd0261998f24"
    : "659ccb3382640392e3e39b79";

export const GLEAP_AGENT_ID =
  process.env.REACT_APP_BACKEND_ENV === "live"
    ? "o1DV1bI295dwGpXUJKZTC5Ff7CKL7oIY"
    : "6Nin5otkRe5rcZBuoWSp9BOZE7fE8Jro";

export const GLEAP_COMPANY_ID =
  process.env.REACT_APP_BACKEND_ENV === "live"
    ? "gjcUvIyCbD2ALrGnfbaKjukRqQIbjwMS"
    : "Qsbhl0ZqZh7ZhQt1jwyD1C5FqNpKig9T";

export const GLEAP_CLIENT_ID =
  process.env.REACT_APP_BACKEND_ENV === "live"
    ? "IMuuOqVDcnEhyusm89m7Tg3f4WU5aggu"
    : "4RqRxH3GSK6fiaNtGwIkAfkO3tbYb6LL";

export const GLEAP_WHITELABEL_ID =
  process.env.REACT_APP_BACKEND_ENV === "live"
    ? "gjcUvIyCbD2ALrGnfbaKjukRqQIbjwMS"
    : "Qsbhl0ZqZh7ZhQt1jwyD1C5FqNpKig9T";

export const SocialPostMediaBucketName =
  "closewise-social-post-media-storage-bucket";
export const monthFullNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const monthShortNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const dayNames = [
  { label: "Sun", value: "sunday" },
  { label: "Mon", value: "monday" },
  { label: "Tue", value: "tuesday" },
  { label: "Wed", value: "wednesday" },
  { label: "Thu", value: "thursday" },
  { label: "Fri", value: "friday" },
  { label: "Sat", value: "saturday" },
];

export const agentClosingStatus = [
  {
    label: "Can't Complete",
    value: "CCA",
    icon: "CantComplete",
    imageBlueName: "CCABlue",
    imageBlackName: "CCA",
  },
  {
    label: "Pending",
    value: "Pending",
    icon: "statusPending",
    imageBlueName: "pendingBlue",
    imageBlackName: "pendingBlack",
  },
  // { label: "In Progress", value: "InProgress" },
  {
    label: "Closed",
    value: "Closed",
    icon: "closed",
    imageBlueName: "CLosedBlue",
    imageBlackName: "closed",
  },
  {
    label: "Cancelled",
    value: "Cancelled",
    icon: "cancelled",
    imageBlueName: "CancelledBlue",
    imageBlackName: "Cancelled",
  },
  {
    label: "Did not close",
    value: "DNC",
    icon: "ditNotClose",
    imageBlueName: "DNCBlue",
    imageBlackName: "DNC",
  },
  {
    label: "Arrived",
    value: "Arrived",
    icon: "arrived",
    imageBlueName: "ArrivedBlue",
    imageBlackName: "Arrived",
  },
];

export const credentialsData = [
  {
    label: "Notary License",
    value: "notaryLicense",
    isRequired: true,
  },
  {
    label: "Bar License",
    value: "barLicense",
    isRequired: true,
  },
  {
    label: "ID Verification",
    value: "idVerification",
    isRequired: true,
  },
  {
    label: "Background Check Report",
    value: "backgroundCheckReport",
    isRequired: true,
  },
  {
    label: "E&O Insurance",
    value: "eoInsurance",
    isRequired: true,
  },
  {
    label: "W-9",
    value: "w9Credentials",
    isRequired: true,
  },
  {
    label: "NNA Certification",
    value: "nnaCertification",
    isRequired: false,
  },
  {
    // label: "LSS Certification",
    label: "Notary Training Certification",
    value: "lssCertification",
    isRequired: false,
  },
  {
    label: "MD Title Producer License",
    value: "mdTitleProducerLicense",
    isRequired: false,
  },
  {
    label: "IN Title Producer License",
    value: "titleProducerLicense",
    isRequired: false,
  },
  {
    label: "NY Title Closer",
    value: "nyTotalCloser",
    isRequired: false,
  },
  {
    label: "Notary Bond",
    value: "notaryBond",
    isRequired: false,
  },
  {
    label: "RON Credentials",
    value: "ronCredentials",
    isRequired: false,
  },
];

export const itemListOptionsConfig = [
  { label: "Gas", value: "Gas" },
  { label: "Printing", value: "Printing" },
  { label: "Postage", value: "Postage" },
  { label: "Office Supplies", value: "Office Supplies" },
  { label: "Office Equipment", value: "Office Equipment" },
  { label: "Phone", value: "Phone" },
  { label: "Miscellaneous", value: "Miscellaneous" },
];

// export const loanTypeConfig = [
//   { label: "Refinance", value: "Refinance" },
//   { label: "Home Equity", value: "HomeEquity" },
//   { label: "Attorney Phone", value: "AttorneyPhone" },
//   { label: "Purchase", value: "Purchase" },
//   { label: "Loan Modifications", value: "LoanMod" },
//   { label: "Reverse Mtg", value: "ReverseMtg" },
//   { label: "Hybrid E Sign", value: "HybridESign" },
//   { label: "Deed In Lieu", value: "DeedInLieu" },
//   { label: "Single Doc", value: "SingleDoc" },
//   { label: "Loan Application", value: "LoanApplication" },
//   { label: "Other", value: "Other" },
// ];
export const loanTypeConfig = {
  RealEstateDocuments: [
    "Refinance",
    "HomeEquity",
    "AttorneyPhone",
    "Purchase",
    "LoanMod",
    "ReverseMtg",
    "HybridESign",
    "DeedInLieu",
    "LoanApplication",
    "SellerPackage",
    "BuyerPackage",
  ],
  LegalGeneralDocuments: [
    "2PartyAgreement",
    "DivorcePapers",
    "CourtFiling",
    "SingleDoc",
    "PowerOfAttorney",
  ],
  EstatePlanning: [
    "Wills",
    "CustodyGuardianship",
    "ExecutionerShips",
    "ProbatePetitions",
  ],
  Apostille: ["Trust"],
};

export const invoiceStatus = [
  { label: "Pending", value: "Pending" },
  { label: "Paid", value: "Paid" },
  // { label: "Cancelled", value: "Cancelled" },
];

export const customDateRangeConfig = [
  { label: "Today", value: "day" },
  { label: "This Week", value: "week" },
  { label: "This Month", value: "month" },
  { label: "This Year", value: "year" },
  { label: "All Time", value: "allTime" },
  { label: "Custom Date Range", value: "customDateRange" },
];

export const recurringFrequencyConfig = [
  { label: "Weekly", value: 7 },
  { label: "Bi-Weekly", value: 14 },
  { label: "Monthly", value: 30 },
  { label: "Yearly", value: 365 },
];

export const clientPermissionConfig = [
  {
    label: "Dashboard",
    value: "dashboard",
    permissions: [
      { label: "Create Order", value: "canCreateOrder" },
      {
        label: "Update order Status",
        value: "canUpdateClosingStatusOrScheduling",
      },
      { label: "Edit Order Entry", value: "canEditOrderEntry" },
      {
        label: "Review Documents",
        value: "canReviewDocumentsOrOrderDetail",
      },
    ],
  },
  // {
  //   label: "Connect Companies",
  //   value: "connectCompanies",
  //   permissions: [
  //     { label: "View", value: "canView" },
  //     { label: "Add", value: "canAdd" },
  //     { label: "Update", value: "canUpdate" },
  //   ],
  // },
  // {
  //   label: "Client",
  //   value: "lenderClient",
  //   permissions: [
  //     { label: "View", value: "canView" },
  //     { label: "Add", value: "canAdd" },
  //     { label: "Update", value: "canUpdate" },
  //   ],
  // },
  {
    label: "Payment",
    value: "payment",
    permissions: [
      { label: "View", value: "canView" },
      { label: "Make Payment", value: "canPay" },
    ],
  },
];

export const LanguageList = [
  { label: "English", value: "English" },
  { label: "Russian", value: "Russian" },
  { label: "German", value: "German" },
  { label: "French", value: "French" },
  { label: "Italian", value: "Italian" },
  { label: "Spanish", value: "Spanish" },
  { label: "Mandarin", value: "Mandarin" },
  { label: "Japanese", value: "Japanese" },
  { label: "Korean", value: "Korean" },
  { label: "Vietnamese", value: "Vietnamese" },
  { label: "Hindi", value: "Hindi" },
  { label: "Portuguese", value: "Portuguese" },
  { label: "Arabic", value: "Arabic" },
  { label: "Hebrew", value: "Hebrew" },
  { label: "Swahili", value: "Swahili" },
  { label: "Tagalog", value: "Tagalog" },
  { label: "Haitian Creole", value: "Haitian Creole" },
  { label: "Polish", value: "Polish" },
  { label: "Urdu", value: "Urdu" },
  { label: "Persian", value: "Persian" },
  { label: "Other", value: "Other" },
];
export const FrequencyEnum = [
  "1stDayOfMonth",
  "lastDayOfMonth",
  "15thOfMonth",
  "eachOrder",
  "weekly",
  "biWeekly",
  // "monthly",
];
export const EmailCustomizationVariable = {
  "custom-client-team-member-invitaion": [
    "{{clientEmail}}",
    "{{password}}",
    "{{name}}",
  ],
  "custom-agent-pay-period": [
    "{{name}}",
    "{{{companyLogoOrCompanyName}}}",
    "{{companyName}}",
    "{{{companySignatureOrSignature}}}",
  ],
  "custom-team-member-invitaion": [
    "{{companyEmail}}",
    "{{name}}",
    "{{password}}",
    "{{companyName}}",
    "{{email}}",
  ],
  "custom-agent-registration-invite": [
    "{{{companyLogoOrCompanyName}}}",
    "{{name}}",
    "{{companyName}}",
    "{{{companySignatureOrSignature}}}",
  ],
  "cutom-payment-received-by-company-from-client": [
    "{{companyName}}",
    "{{{companyLogoOrCompanyName}}}",
    "{{amount}}",
  ],
  "custom-agent-create-invoice": ["{{companyName}}"],
  "custom-company-invoice": ["{{clientName}}"],
  "custom-agent-invoice": ["{{companyName}}", "{{email}}", "{{phone}}"],
  "custom-client-invites-company-to-connect": [
    "{{{companyLogoOrCompanyName}}}",
    "{{name}}",
    "{{email}}",
    "{{phone}}",
  ],
  "custom-client-invites-unregistered-sigining-company-to-connect-on-closewise":
    ["{{{companyLogoOrCompanyName}}}", "{{name}}", "{{email}}", "{{phone}}"],
  "custom-company-invites-client-to-create-closewise-connect-account": [
    "{{{companyLogoOrCompanyName}}}",
    "{{companyName}}",
    "{{name}}",
  ],
  "custom-company-invites-client-to-connect": [
    "{{{companyLogoOrCompanyName}}}",
    "{{name}}",
    "{{companyName}}",
  ],
  "custom-agent-fee-update": [
    "{{name}}",
    "{{fileNo}}",
    "{{agentFee}}",
    "{{email}}",
    "{{phone}}",
    "{{{companySignatureOrSignature}}}",
  ],
  "custom-company-uploaded-document": [
    "{{{companyLogoOrCompanyName}}}",
    "{{name}}",
    "{{email}}",
    "{{phone}}",
  ],
  "custom-company-verified-docs": [
    "{{{companyLogoOrCompanyName}}}",
    "{{agentName}}",
    "{{fileNo}}",
    "{{email}}",
    "{{phone}}",
    "{{{companySignatureOrSignature}}}",
  ],
  "custom-confirm-appointment-reminder": [
    "{{{companyLogoOrCompanyName}}}",
    "{{agentName}}",
    "{{{date}}}",
    "{{time}}",
    "{{{companySignatureOrSignature}}}",
    "{{listOfSigners}}",
  ],

  "custom-ach-payment": [
    "{{{companyLogoOrCompanyName}}}",
    "{{name}}",
    "{{{companySignatureOrSignature}}}",
  ],

  "custom-agent-schedule": [
    "{{name}}",
    "{{companyName}}",
    "{{{companySignatureOrCompanyName}}}",
    "{{companyEmail}}",
    "{{companyPhone}}",
  ],
  "custom-closing-assigned": [
    "{{{companyLogoOrCompanyName}}}",
    "{{name}}",
    "{{{companySignatureOrSignature}}}",
    "{{{date}}}",
    "{{time}}",
    "{{address}}",
    "{{orderType}}",
    "{{companyName}}",
  ],
  "custom-closing-unassigned": [
    "{{{companyLogoOrCompanyName}}}",
    "{{name}}",
    "{{{companySignatureOrSignature}}}",
    "{{fileNo}}",
    "{{{date}}}",
    "{{time}}",
    "{{email}}",
    "{{phone}}",
  ],
  "custom-closing-status-company": [
    "{{{companyLogoOrCompanyName}}}",
    "{{status}}",
    "{{fileNo}}",
    "{{companyName}}",
    "{{name}}",
    "{{{companySignatureOrSignature}}}",
  ],
  "custom-closing-status-agent": [
    "{{companyName}}",
    "{{status}}",
    "{{fileNo}}",
    "{{name}}",
    "{{{companySignatureOrSignature}}}",
  ],
  "custom-agent-uploaded-document": ["{fileNo}}", "{{companyName}}"],
  "custom-new-matched-closing": [
    "{{{companyLogo}}}",
    "{{name}}",
    "{{{date}}}",
    "{{time}}",
    "{{fee}}",
    "{{companyName}}",
    "{{closingType}}",
    "{{city}}",
    "{{state}}",
    "{{scanbacks}}",
    "{{signingType}}",
  ],
  "custom-appointment-pre-reminder": ["{{name}}"],
  "custom-appointment-post-reminder": [
    "{{name}}",
    "{{fileNo}}",
    "{{{companyLogoOrCompanyName}}}",
    "{{email}}",
  ],
};

export const EmailCustomizationSubject = {
  "custom-client-team-member-invitaion": ["{{companyName}}"],
  "custom-agent-pay-period": [
    // "{{name}}",
    // "{{companyLogoOrCompanyName}}",
    // "{{CompanyName}}",
    // "{{companySignatureOrSignature}}",
  ],
  "custom-team-member-invitaion": ["{{companyName}}"],
  "custom-agent-registration-invite": ["{{companyName}}"],
  "cutom-payment-received-by-company-from-client": [
    // "{{companyName}}",
    // "{{companyLogo}}",
    // "{{amount}}",
  ],
  "custom-agent-create-invoice": [
    "{{agentName}}",
    // "{{companyName}}",
    // "{{email}}",
    // "{{phone}}",
  ],
  "custom-company-invoice": ["{{companyName}}"],
  "custom-agent-invoice": ["{{agentName}}"],
  "custom-client-invites-company-to-connect": [
    "{{companyName}}",
    // "{{companyLogo}}",
    // "{{name}}",
    // "{{email}}",
    // "{{phone}}",
  ],
  "custom-client-invites-unregistered-sigining-company-to-connect-on-closewise":
    ["{{companyName}}"],
  "custom-company-invites-client-to-create-closewise-connect-account": [
    "{{companyName}}",
  ],
  "custom-company-invites-client-to-connect": ["{{companyName}}"],
  "custom-agent-fee-update": ["{{fileNo}}"],
  "custom-company-uploaded-document": [
    "{{borrowerName}}",
    "{{{appointDayForEmail}}}",
    "{{appointTimeForEmail}}",
  ],
  // "custom-company-verified-docs": [
  //   "{{companyLogoOrCompanyName}}",
  //   "{{agentName}}",
  //   "{{fileNo}}",
  //   "{{email}}",
  //   "{{phone}}",
  //   "{{companySignatureOrSignature}}",
  // ],
  // "custom-confirm-appointment-reminder": [
  //   "{{companyLogoOrCompanyName}}",
  //   "{{agentName}}",
  //   "{{date}}",
  //   "{{time}}",
  //   "{{companySignatureOrSignature}}",
  //   "{{listOfSigners}}",
  // ],
  "custom-company-verified-docs": [],
  "custom-confirm-appointment-reminder": [],
  // "custom-closing-status-agent": [
  //   "{{status}}",
  //   "{{fileNo}}",
  //   "{{signersName}}",
  // ],

  "custom-agent-schedule": [
    "{{companyName}}",
    "{{{startDate}}}",
    "{{{endDate}}}",
  ],
  "custom-closing-assigned": ["{{fileNo}}"],
  "custom-closing-unassigned": ["{{signersName}}", "{{fileNo}}"],
  "custom-closing-status-company": [
    "{{companyName}}",
    "{{status}}",
    "{{fileNo}}",
  ],
  "custom-closing-status-agent": [
    "{{fileNo}}",
    "{{status}}",
    "{{signersName}}",
  ],
  "custom-agent-uploaded-document": [
    "{{borrowerName}}",
    "{{agentName}}",
    "{{fileNumber}}",
  ],
  "custom-new-matched-closing": [
    "{{companyName}}",
    "{{{appointmentDate}}}",
    "{{appointmentTime}}",
    "{{city}}",
    "{{state}}",
  ],
  "custom-ach-payment": [],
  "custom-appointment-pre-reminder": [],
  "custom-appointment-post-reminder": [],
};

// export const EmailCustomizationTitle = [
//   "Team member invitation(company)",
//   "Team member invitation (client)",
//   "Agent registration invite",
//   "Agent pay period breakdown",
//   "Company pay agent",
//   "Client Pay Signing Company",
//   "Agent Creates a new Invoice",
//   "Signing Company Create a new Invoice",
//   "Signing Company Email or Download Invoice",
//   "Agent Sends Invoice",
//   "Emailing Agent Schedule",
//   "Client-invites-company-to-connect",
//   "Client-invites-unregistered-sigining-company-to-connect-on-closewise",
//   "Company-invites-client-to-create-closewise-connect-account",
//   "company-invites-client-to-connect",
//   "closing-assigned",
//   "closing-unassigned",
//   "agent-fee-update after assignment",
//   "Notify agent docs are ready for download",
//   "company-verified-docs",
//   "confirm-appointment-reminder",
//   "closing-status-company",
//   "closing-status-agent",
//   "agent-finished uploading documents complete",
//   "new-matched-closing",
//   "appointment-pre-reminder",
//   "appointment-post-reminder",
//   "ach-payment",
//   "agent-schedule",
//   "closing-assigned",
//   "closing-unassigned",
//   "closing-status-company",
//   "closing-status-agent",
//   "agent-uploaded-document",
//   "new-matched-closing",
//   "appointment-pre-reminder",
//   "appointment-post-reminder",
// ];

// "custom-team-member-invitaion",
//   "custom-client-team-member-invitaion",
//   "custom-agent-registration-invite",
//   "custom-agent-pay-period",
//   "custom-ach-payment",
//   "custom-payment-received-by-company-from-client",
//   "custom-agent-create-invoice",
//   "custom-company-invoice",
//   "custom-agent-invoice",
//   "custom-agent-schedule",
//   "custom-client-invites-company-to-connect",
//   "custom-client-invites-unregistered-sigining-company-to-connect-on-closewise",
//   "custom-company-invites-client-to-create-closewise-connect-account",
//   "custom-company-invites-client-to-connect",
//   "custom-closing-assigned",
//   "custom-closing-unassigned",
//   "custom-agent-fee-update",
//   "custom-company-uploaded-document",
//   "custom-company-verified-docs",
//   "custom-confirm-appointment-reminder",
//   "custom-closing-status-company",
//   "custom-closing-status-agent",
//   "custom-agent-uploaded-document",
//   "custom-new-matched-closing",
//   "custom-appointment-pre-reminder",
//   "custom-appointment-post-reminder";

export const EmailCustomizationTitle = {
  "custom-team-member-invitaion": "Custom: Team member invitation",
  "custom-client-team-member-invitaion": "Custom: Team member invitation",
  "custom-agent-registration-invite": "Custom: New Notary/Attorney Invitation",
  "custom-agent-pay-period": "Custom: Agent Pay Breakdown",
  "custom-ach-payment": "Custom: ACH payment initiated to notary",
  "cutom-payment-received-by-company-from-client":
    "Custom: ACH payment initiated to Company",
  "custom-agent-create-invoice": "Custom: Sending an Invoice to Client",
  // "custom-company-invoice": "Custom: Sending an Invoice",
  "custom-company-invoice":
    "Custom: Sending an Invoice to Client (By Downloading)",
  "custom-agent-invoice":
    "Custom: Sending an Invoice to Client (By Downloading)",
  "custom-agent-schedule": "Custom: Notary Schedule",
  "custom-client-invites-company-to-connect":
    "Custom: Inviting Company to connect via CloseWise connect",
  "custom-client-invites-unregistered-sigining-company-to-connect-on-closewise":
    "Custom: Inviting Company to create connect account",
  "custom-company-invites-client-to-create-closewise-connect-account":
    "Custom: Inviting client to create connect account",
  "custom-company-invites-client-to-connect":
    "Custom: Inviting client to connect via CloseWise connect",
  "custom-closing-assigned": "Custom: Closing assigned to notary/attorney",
  "custom-closing-unassigned":
    "Custom: Closing unassigned from notary/attorney",
  "custom-agent-fee-update": "Custom: Update to notary/attorney's fee",
  "custom-company-uploaded-document": "Custom: Upload of docs complete",
  "custom-company-verified-docs":
    "Custom: Notary uploads verified and approved",
  "custom-confirm-appointment-reminder": "Custom: Confirm appointment reminder",
  "custom-closing-status-company": "Custom: Closing status updating client",
  "custom-closing-status-agent":
    "Custom: Closing status updating notary/attorney",
  "custom-agent-uploaded-document":
    "Custom: Sending Uploaded Documents to client",
  "custom-new-matched-closing":
    "Custom: Request for availability to Notary/Attorney",
  "custom-appointment-pre-reminder": "Custom: Appointment pre-reminder",
  "custom-appointment-post-reminder": "Custom: Appointment post reminder",
};

export const profilePercentageType = [
  "profilePicture",
  "email",
  "phone",
  "address",
  "languagesKnown",
  "notaryLicense",
  "idVerification",
  "backgroundCheckReport",
  "eoInsurance",
  "notaryBond",
  "w9Credentials",
  "bank",
];

export const TemplateCustomizationSubjectVariable = [
  "{{agentName}}",
  "{{agentEmail}}",
  "{{agentPhone}}",
  "{{clientOrCompanyName}}",
  "{{clientOrCompanyEmail}}",
  "{{clientOrCompanyPhone}}",
];
export const TemplateCustomizationBodyVariable = [
  "{{agentName}}",
  "{{agentEmail}}",
  "{{agentPhone}}",
  "{{clientOrCompanyName}}",
  "{{clientOrCompanyEmail}}",
  "{{clientOrCompanyPhone}}",
];

export const agentCredentialsConfig = [
  { label: "Notary", value: "notaryLicense" },
  { label: "Bar License", value: "barLicense" },
  { label: "ID Verification", value: "idVerification" },
  { label: "Background Check Report ", value: "backgroundCheckReport" },
  { label: "E&O Insurance ", value: "eoInsurance" },
  { label: "W-9", value: "w9Credentials" },
  { label: "NNA Certification", value: "nnaCertification" },
  // { label: "LSS Certification", value: "lssCertification" },
  { label: "Notary Training Certification", value: "lssCertification" },
  { label: "MD Title Producer License", value: "mdTitleProducerLicense" },
  { label: "IN Title Producer License", value: "titleProducerLicense" },
  { label: "NY Title Closer", value: "nyTotalCloser" },
  { label: "Notary Bond", value: "notaryBond" },
  { label: "RON Credentials", value: "ronCredentials" },
];

export const clientTeamMembersConfig = [
  { label: "No Emails", value: "noEmails" },
  { label: "All Emails", value: "allEmails" },
  { label: "Chat Log Emails", value: "chatLogEmails" },
  { label: "Status Updates", value: "statusUpdates" },
  { label: "Invoice Emails", value: "invoiceEmails" },
  { label: "Appointment Confirmation", value: "appointmentConfirmation" },
  { label: "Agent Assigned", value: "agentAssigned" },
];

export const agentWebsiteCardCollapseHeading = [
  { label: "Basic Info", value: "basicInfo" },
  { label: "Branding", value: "branding" },
  { label: "Banner Section", value: "bannerSection" },
  { label: "About Us", value: "aboutUsSection" },
  { label: "Services", value: "servicesSection" },
  { label: "Coverage Area", value: "coverageAreaSection" },
  { label: "Testimonials", value: "testimonialsSection" },
  { label: "FAQ", value: "faqSection" },
  { label: "Footer", value: "footerSection" },
];
