import React, { Suspense, lazy } from "react";
import ReactDatetime from "react-datetime";
import { connect } from "react-redux";
import { Button, Col, CustomInput, FormGroup, Label, Row } from "reactstrap";
import { loanTypeConfig } from "../../../config";
import { RegexConfig } from "../../../config/RegexConfig";
import {
  createClientClosing,
  getAllLenderClients,
  getClientSettings,
  getClosingDetailsById,
  getRonPlatformTypes,
  getCompanyStandardFee,
  ClientGetSigningCompanyCustomOrderFormId,
  clientGetListTeamsOfClient,
  FileNumberDuplicateMatchCheck,
} from "../../../http/http-calls";
import {
  deepClone,
  errorHandler,
  extractValidFields,
  formatDate,
  formatDateAndTime,
  formatDateAsPerTimeZoneWithDateAndTime,
  formatDateMoment,
  formatOnlyDateMoment,
  getPhoneNumberFromBrackets,
  isValidEmail,
  showToast,
  sortText,
} from "../../../helper-methods";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import FileNumberDuplicateModal from "../components/FileNumberDuplicateModal";
import { hideLoader, showLoader } from "../../../redux/actions/loader-data";
import AddOrderClient from "../components/AddOrderClient";
import CreateClosingAddressComponent from "../components/common/createClosingAddressComponent";

const SignerInfoGroup = lazy(() => import("../components/SignerInfoGroup"));
const SignerClosingInfo = lazy(() => import("../components/SignerClosingInfo"));
const FileChecked = lazy(() => import("../components/FileChecked"));
const Instructions = lazy(() => import("../components/instructions"));
// const ClosingAddress = lazy(() =>
//   import("../components/common/closingAddress")
// );

export const chipTheme = {
  chip: {
    padding: 5,
    background: "red",
    margin: "2.5px",
    borderRadius: 3,
    cursor: "default",
  },
  chipSelected: {
    background: "#888",
  },
  chipRemove: {
    fontWeight: "bold",
    cursor: "pointer",
    ":hover": {
      color: "red",
    },
  },
};

class CreateClosing extends React.PureComponent {
  constructor(props) {
    super(props);
    this.signerInfoGroupRef = React.createRef();
    this.closingAddressRef = React.createRef();
    this.borrowerAddressRef = React.createRef();
    this.addressRef = React.createRef();
  }

  state = {
    formFields: {
      additionalSigner: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      borrowerSearch: {
        value: "",
        placeId: null,
        isDirty: false,
        isValidate: true,
      },
      closingSearch: {
        value: "",
        placeId: null,
        isDirty: false,
        isValidate: true,
      },

      loanOfficer: {
        value: "",
        isDirty: false,
        isValidate: false,
      },
      fileNumber: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      titleCompany: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      lender: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      personOfContactName: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      personOfContactEmail: {
        value: [],
        isDirty: false,
        isValidate: true,
      },
      signingType: {
        value: "Mobile",
        isDirty: false,
        isValidate: true,
      },
      ronPlatformType: {
        value: [],
        isDirty: false,
        isValidate: true,
      },
      witnessCount: {
        value: 0,
        isDirty: false,
        isValidate: true,
      },
      loanType: {
        value: [],
        isDirty: false,
        isValidate: true,
      },
      loanTypeOther: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      loanCategories: {
        value: [],
        error: null,
        isDirty: false,
        isValidate: false,
      },
      signingCompanyId: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      signingCompanyFee: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      lenderClientId: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      clientTeams: {
        value: "",
        isValidate: true,
        isDirty: false,
      },
      appointmentDate: {
        value: "",
        isDirty: false,
        isValidate: true,
      },

      closingAddressTimeZone: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      files: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      notes: {
        value: "",
        isDirty: false,
        isValidate: false,
      },
      instructionId: {
        value: "",
        isDirty: false,
        isValidate: false,
      },
      instructionSignerData: {
        value: "",
        isDirty: false,
        isValidate: false,
      },
      instructions: {
        value: "",
        isDirty: false,
        isValidate: false,
      },
      internalNotes: {
        value: "",
        isDirty: false,
        isValidate: false,
      },
      companyNotes: {
        value: "",
        isDirty: false,
        isValidate: false,
      },
      isScanBackNeeded: {
        value: false,
        isDirty: false,
        isValidate: false,
      },
    },
    borrowerData: [],
    addClientModalData: {
      isOpen: false,
      data: null,
    },
    pdfFile: null,
    loading: {
      fileUploadLoading: false,
      submitLoading: false,
    },
    show: null,
    searchResult: [],
    loanType: [],
    isBorrowerAddressSame: false,
    isAppointmentAddressSame: false,
    isBorrowerSearch: true,
    isAppointmentSearch: true,
    inputLoanType: false,
    // signingCompanies: [],
    lenderClients: [],
    clientTeamsList: [],
    signingInstruction: [],
    tBD: false, // Time to be determined
    // isOpenAddInstruction: false,
    closingDetails: {},
    fileChecked: {
      isOpen: false,
      data: null,
    },
    FileNumberUniqueChecked: {
      isOpen: false,
    },
    textInstruction: "",
    ronPlatformTypeOptions: {},
    companyStandardFee: [],
    isChecked: false,
    questions: [],
    closingAddress: null,
    borrowerAddress: null,
    copyAddress: {},
    closingGoogleAddress: "",
    borrowerGoogleAddress: "",
    scanBackDocument: null,
    existingFileNos: null,
    keepFileNo: false,
  };

  handleCheckboxChange = () => {
    this.setState({ isChecked: !this.state.isChecked });
  };

  _resetForm = () => {
    const { formFields } = deepClone(this.state);
    Object.keys(formFields).forEach((field) => {
      if (field !== "personOfContactEmail" && field !== "loanType") {
        formFields[field].value = "";
      } else {
        formFields[field].value = [];
      }
      formFields[field].isDirty = false;
      formFields[field].isValidate = true;
      if (formFields[field].placeId) {
        formFields[field].placeId = null;
      }
    });
    this.setState({ formFields });
  };

  _getClientSettings = async () => {
    try {
      const res = await getClientSettings();

      const { formFields } = this.state;
      if (res?.clientSetting?.signingInstruction?.length) {
        const defaultInstruction = res.clientSetting?.signingInstruction.find(
          (each) => each.isDefault
        );

        formFields.instructionId.value = defaultInstruction?._id || "";
        formFields.instructions.value = defaultInstruction?.description || "";
        formFields.instructionSignerData.value =
          defaultInstruction?.description || "";
      }
      formFields.isScanBackNeeded.value =
        res.clientSetting?.scanBackDocument.isAlwaysRequired;

      this.setState({
        formFields,
        signingInstruction: res.clientSetting?.signingInstruction?.length
          ? res.clientSetting.signingInstruction
          : [],
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  _getAllLenderClients = async () => {
    try {
      const res = await getAllLenderClients();

      this.setState({
        lenderClients: res?.lenderClients?.length ? res?.lenderClients : [],
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  _getClientListTeamsOfClient = async () => {
    try {
      const clientId = this.props.userData?.user?.id;
      const res = await clientGetListTeamsOfClient(clientId);

      const teams = res?.teams || [];
      if (teams.length === 1) {
        this.setState((prevState) => ({
          clientTeamsList: teams,
          formFields: {
            ...prevState.formFields,
            clientTeams: {
              ...prevState.formFields.clientTeams,
              value: teams[0]._id,
              error: null,
            },
          },
        }));
      } else {
        // Just set the clientTeamsList when there's more than one team or no team
        this.setState({ clientTeamsList: teams });
      }
    } catch (err) {
      errorHandler(err);
    }
  };

  _getCompanyStandardFee = (id) => {
    if (id.trim().length > 0) {
      getCompanyStandardFee(id)
        .then((res) => {
          this.setState(
            {
              companyStandardFee:
                res.standardFees.length > 0 ? res.standardFees : [],
            },
            () => {
              this._findStandardrate();
            }
          );
        })
        .catch((error) => {
          errorHandler(error);
        });
    }
  };

  _ClientGetSigningCompanyCustomOrderFormId = async (id) => {
    const { formFields } = deepClone(this.state);

    if (id.trim().length > 0) {
      try {
        let response = await ClientGetSigningCompanyCustomOrderFormId(id);
        let questionsArray =
          response?.questions?.length > 0 &&
          response?.questions?.map((each) => ({
            ...each,
            reply: false,
          }));

        if (response?.productTypes) {
          this._setProductType(response?.productTypes);
        }

        formFields["signingCompanyId"].value = id;
        this.setState({
          formFields,
          questions: questionsArray || loanTypeConfig,
        });

        // this.setState({
        //     questions: response.cutomOrderForm.questions,
        //   });
      } catch (error) {
        errorHandler(error);
      }
    }
  };

  _setProductType = (products) => {
    const { formFields } = deepClone(this.state);

    const groupedProducts = products.reduce((acc, product) => {
      const { productCategory } = product;

      if (!acc[productCategory]) {
        acc[productCategory] = [];
      }

      acc[productCategory].push(product.productType);

      return acc;
    }, {});

    if (formFields.loanType.value.length > 0) {
      const newArray = formFields.loanType.value.filter((item) =>
        products.some((each) => each.productType === item.value)
      );
      // console.log("newArray", newArray);
      formFields["loanType"].value = newArray;
    }

    this.setState({ loanType: groupedProducts, formFields });
  };

  componentDidMount = async () => {
    // this._getAllSigningCompanyForClient();
    await this._getAllLenderClients();
    await this._getClientSettings();
    await this._getRonPlatformType();
    await this._getClientListTeamsOfClient();

    // Event subscriptions
    HeaderEventEmitter.subscribe("back-button", () => {
      this.props.history.goBack();
    });
    document.querySelector("#scroll").scrollTo(0, 0);

    const res = await getRonPlatformTypes();

    const ronOptions = Object.keys(res?.ronPlatformTypes)?.map((each) => ({
      label: each,
      value: res?.ronPlatformTypes[each],
    }));

    this.setState({
      ronPlatformTypeOptions: ronOptions,
    });

    // Process location search params
    if (this.props.location.search) {
      let params = this.props.location.search.split("&");
      let id = params[0].replace(/[^\w\s]/gi, "");
      this._getSigningClosingById(id); // get closing details by Id for clone option
    }

    // for white label get question which was set by company
    if (this.props.userData?.user?.siteOwnerDetail) {
      let whiteLabelId = this.props.userData?.user?.siteOwnerDetail?._id;
      this._ClientGetSigningCompanyCustomOrderFormId(whiteLabelId);
      this._getCompanyStandardFee(whiteLabelId);
    }
  };

  _getRonPlatformType = async () => {
    try {
      const response = await getRonPlatformTypes();

      if (!response.error) {
        const convertedArray = Object.entries(response.ronPlatformTypes).map(
          ([label, value]) => ({ label, value })
        );
        this.setState({ ronPlatformOptions: convertedArray });
      }
    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  };
  _getSigningClosingById = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await getClosingDetailsById(id);

        this.setState({
          closingDetails: res?.closing || {},
        });

        this._setForm(res?.closing);
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  };

  _setForm = (closingDetail) => {
    let {
      formFields,
      ronPlatformTypeOptions,
      closingAddress,
      borrowerAddress,
      borrowerGoogleAddress,
      closingGoogleAddress,
    } = deepClone(this.state);

    let borrower = [];
    closingDetail?._borrower?.forEach((each) => {
      borrower.push({
        name: each?.name?.full || "",
        email: each?.email || "",
        workPhone: getPhoneNumberFromBrackets(each?.phone?.work) || "",
        phoneHome: "",
        isForeignNational: each?.isForeignNational,
        language: each?.language,
        languageTypeOther: each?.languageTypeOther || "",
        timeZone: each?.timeZone || "",
      });
    });
    this.setState({ borrowerData: borrower });

    formFields["fileNumber"].value = closingDetail?.fileNumber || "";
    formFields["lender"].value = closingDetail?.lender || "";

    formFields["personOfContactName"].value =
      closingDetail?.personOfContact?.name || "";
    formFields["personOfContactEmail"].value =
      closingDetail?.personOfContact?.email[0]?.trim()?.length
        ? closingDetail?.personOfContact?.email
        : [];
    formFields["signingType"].value = closingDetail?.signingType
      ? closingDetail?.signingType
      : "Mobile";

    formFields["closingAddressTimeZone"].value =
      closingDetail?.closingAddress?.timeZone || "";

    if (Object.keys(ronPlatformTypeOptions)?.length) {
      const temp = [];
      ronPlatformTypeOptions?.forEach((each) => {
        closingDetail?.ronPlatformTypes.forEach((subEach) => {
          if (subEach === each?.value) {
            temp.push(each);
          }
        });
      });

      formFields["ronPlatformType"].value = temp || [];
    }

    formFields["loanTypeOther"].value = closingDetail?.loanTypeOther || "";

    formFields["companyNotes"].value = closingDetail?.companyNotes || "";
    formFields["internalNotes"].value = closingDetail?.internalNotes || "";
    formFields["signingCompanyFee"].value =
      closingDetail?.signingCompanyFee?.toString() || "";
    formFields["instructions"].value = closingDetail?.instructions || "";

    const borrowerAddressData = closingDetail?._borrower[0]?.address;

    borrowerAddress = {
      addressLine1: borrowerAddressData?.line1 || "",
      addressLine2: borrowerAddressData?.line2 || "",
      city: borrowerAddressData?.city || "",
      state: borrowerAddressData?.state || "",
      zip: borrowerAddressData?.zip || "",
    };

    borrowerGoogleAddress = Object.values({
      line1: borrowerAddressData?.line1 || "",
      line2: borrowerAddressData?.line2 || "",
      city: borrowerAddressData?.city || "",
      state: borrowerAddressData?.state || "",
      zip: borrowerAddressData?.zip || "",
    })
      .filter(Boolean)
      .join(",");

    const closingAddressData = closingDetail?.closingAddress || {};

    closingAddress = {
      addressLine1: closingAddressData?.line1 || "",
      addressLine2: closingAddressData?.line2 || "",
      city: closingAddressData?.city || "",
      state: closingAddressData?.state || "",
      zip: closingAddressData?.zip || "",
    };

    closingGoogleAddress = Object.values({
      line1: closingAddressData?.line1 || "",
      line2: closingAddressData?.line2 || "",
      city: closingAddressData?.city || "",
      state: closingAddressData?.state || "",
      zip: closingAddressData?.zip || "",
    })
      .filter(Boolean)
      .join(",");

    // formFields["line1"].value = closingDetail?._borrower[0]?.address?.line1;
    // formFields["line2"].value = closingDetail?._borrower[0]?.address?.line2;
    // formFields["city"].value = closingDetail?._borrower[0]?.address?.city;
    // formFields["state"].value = closingDetail?._borrower[0]?.address?.state;
    // formFields["zip"].value = closingDetail?._borrower[0]?.address?.zip;
    // formFields["borrowerSearch"].value =
    //   closingDetail?._borrower[0]?.address !== undefined
    //     ? closingDetail?._borrower[0]?.address?.line1 !== undefined
    //       ? closingDetail?._borrower[0]?.address?.line1
    //       : "," + closingDetail?._borrower[0]?.address?.line2 !== undefined
    //       ? closingDetail?._borrower[0]?.address?.line2
    //       : "," + closingDetail?._borrower[0]?.address?.city !== undefined
    //       ? closingDetail?._borrower[0]?.address?.city
    //       : "," + closingDetail?._borrower[0]?.address?.state !== undefined
    //       ? closingDetail?._borrower[0]?.address?.state
    //       : ""
    //     : "";

    // formFields["closingAddressLine1"].value =
    //   closingDetail?.closingAddress?.line1;
    // formFields["closingAddressLine2"].value =
    //   closingDetail?.closingAddress?.line2;
    // formFields["closingAddressCity"].value =
    //   closingDetail?.closingAddress?.city;
    // formFields["closingAddressState"].value =
    //   closingDetail?.closingAddress?.state;
    // formFields["closingAddressZip"].value = closingDetail?.closingAddress?.zip;
    // formFields["closingSearch"].value =
    //   closingDetail?.closingAddress?.line1 +
    //   "," +
    //   closingDetail?.closingAddress?.line2 +
    //   "," +
    //   closingDetail?.closingAddress?.city +
    //   "," +
    //   closingDetail?.closingAddress?.state;
    formFields["loanType"].value = closingDetail?.loanType?.map((each) => ({
      label: each,
      value: each,
    }));
    formFields["loanCategories"].value = closingDetail?.loanCategories || [];

    formFields["ronPlatformType"].value = closingDetail?.ronPlatformTypes.map(
      (each) => ({
        label: each,
        value: each,
      })
    );

    if (closingDetail?.appointmentDate) {
      formFields["appointmentDate"].value =
        formatDateAsPerTimeZoneWithDateAndTime(
          closingDetail?.appointmentDate,
          closingDetail?.closingAddress?.timeZone
        );
    }

    this.setState(
      {
        formFields,
        closingAddress,
        borrowerAddress,
        closingGoogleAddress,
        borrowerGoogleAddress,
        isBorrowerSearch: false,
        isAppointmentSearch: false,
      },
      () => {
        this._findStandardrate();
      }
    );
  };

  _toggleAddClientModal = (isOpen = false, data = null) => {
    this.setState({
      addClientModalData: {
        isOpen,
        data,
      },
    });
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;

      let isFormValid = true;

      Object.keys(formFields).forEach((key) => {
        if (
          key === "borrower" ||
          (formFields[key].isDirty && formFields[key].isValidate)
        ) {
          switch (key) {
            case "closingAddressLine1":
            case "closingAddressCity":
            case "closingAddressState":
            case "closingAddressZip": {
              if (
                formFields?.signingType?.value === "RON" ||
                (formFields?.loanType?.value?.length === 1 &&
                  formFields?.loanType?.value[0]?.value === "AttorneyPhone") ||
                (formFields[key]?.value &&
                  formFields[key]?.value?.trim()?.length)
              ) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "closingAddressTimeZone": {
              if (
                formFields.signingType.value === "RON" ||
                (formFields?.loanType?.value?.length === 1 &&
                  formFields?.loanType?.value[0]?.value === "AttorneyPhone")
              ) {
                if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }
            case "fileNumber": {
              if (
                formFields[key].value &&
                formFields[key].value.trim().length
              ) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }

            case "email": {
              if (
                formFields[key].value &&
                formFields[key].value.trim().length
              ) {
                if (
                  RegexConfig.email.test(
                    String(formFields[key].value).toLowerCase()
                  )
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Invalid Email";
                  isFormValid = false;
                }
              } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }
            case "loanType": {
              if (formFields[key].value && formFields[key].value.length) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "appointmentDate": {
              if (formFields[key].value && formFields[key].value.length) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }

              break;
            }
            case "loanTypeOther": {
              if (
                formFields?.loanType?.value.find((e) => e.value === "Other")
              ) {
                if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }

            case "location": {
              if (
                formFields[key].value &&
                formFields[key].value.trim().length
              ) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }

            // case "lenderClientId": {
            //   if (
            //     formFields[key].value &&
            //     formFields[key].value.trim().length
            //   ) {
            //     formFields[key].isDirty = false;
            //     formFields[key].error = null;
            //   } else {
            //     formFields[key].isDirty = true;
            //     formFields[key].error = "*Required";
            //     isFormValid = false;
            //   }
            //   break;
            // }

            case "clientTeams": {
              if (this.state?.clientTeamsList?.length) {
                if (
                  formFields[key]?.value &&
                  formFields[key]?.value?.trim()?.length
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }

            case "signingCompanyFee": {
              if (formFields.signingCompanyId.value.trim().length) {
                if (formFields[key].value && formFields[key].value.length) {
                  if (
                    isNaN(formFields[key].value) ||
                    Number(formFields[key].value) < 0
                  ) {
                    formFields[key].isDirty = true;
                    formFields[key].error = "*Fee must be a positive number";
                    isFormValid = false;
                  } else {
                    formFields[key].isDirty = false;
                    formFields[key].error = null;
                  }
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }
            // case "borrowerSearch": {
            //   if (!isAppointmentAddressSame && isBorrowerSearch) {
            //     if (
            //       formFields[key].value &&
            //       formFields[key].value.trim().length
            //     ) {
            //       if (formFields[key].placeId) {
            //         formFields[key].isDirty = false;
            //         formFields[key].error = null;
            //       } else {
            //         formFields[key].isDirty = true;
            //         formFields[key].error = "*Invalid Address";
            //         isFormValid = false;
            //       }
            //     } else {
            //       formFields[key].isDirty = true;
            //       formFields[key].error = "*Required";
            //       isFormValid = false;
            //     }
            //   } else {
            //     formFields[key].isDirty = false;
            //     formFields[key].error = null;
            //   }
            //   break;
            // }
            // case "closingSearch": {
            //   if (
            //     formFields.signingType.value !== "RON" &&
            //     !(
            //       formFields?.loanType?.value?.length === 1 &&
            //       formFields?.loanType?.value[0]?.value === "AttorneyPhone"
            //     )
            //   ) {
            //     if (
            //       this.props.location.search.length === 0 &&
            //       this.state.isAppointmentSearch
            //     ) {
            //       if (
            //         formFields[key].value &&
            //         formFields[key].value.trim().length
            //       ) {
            //         if (formFields[key].placeId) {
            //           formFields[key].isDirty = false;
            //           formFields[key].error = null;
            //         } else {
            //           formFields[key].isDirty = true;
            //           formFields[key].error = "*Invalid Address";
            //           isFormValid = false;
            //         }
            //       } else {
            //         formFields[key].isDirty = true;
            //         formFields[key].error = "*Required";
            //         isFormValid = false;
            //       }
            //     } else {
            //       formFields[key].isDirty = false;
            //       formFields[key].error = null;
            //     }
            //   } else {
            //     formFields[key].isDirty = false;
            //     formFields[key].error = null;
            //   }
            //   break;
            // }

            case "signingCompanyId": {
              if (formFields?.signingCompanyId?.value?.trim()?.length) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }

              break;
            }

            default:
          }
        }
      });

      this.setState({ formFields }, () => {
        resolve(isFormValid);
      });
    });
  };

  _onChangeFormField = (fieldName, value) => {
    const { formFields, signingInstruction, textInstruction } = deepClone(
      this.state
    );

    if (fieldName === "signingCompanyId") {
      this._getCompanyStandardFee(value);
      this._ClientGetSigningCompanyCustomOrderFormId(value);
    }

    if (fieldName === "signingCompanyFee") {
      if (
        isNaN(value) ||
        (value.includes(".") && value.split(".")[1].length > 2)
      ) {
        return;
      }
    }

    if (fieldName === "instructionId") {
      const findInstruction = signingInstruction.find(
        (each) => each._id === value
      );
      formFields.instructionId.value = findInstruction?._id || "";
      formFields.instructions.value = findInstruction?.description || "";
      formFields.instructionSignerData.value =
        findInstruction?.description || "";
    }

    if (fieldName === "appointmentDate")
      formFields[fieldName].value = value ? new Date(value).toISOString() : "";
    else formFields[fieldName].value = value;

    if (fieldName === "clientTeams") {
      const isValueEmpty = !value || value === "SELECT";
      formFields.clientTeams.error = isValueEmpty ? "*Required" : null;
    }
    if (
      fieldName === "homePhone" ||
      fieldName === "phone" ||
      fieldName === "workPhone" ||
      fieldName === "alternatePhone"
    )
      formFields[fieldName].value = value.replace(
        /\([^]*\)|[^a-z0-9\s]|\D|\s/gi,
        ""
      );

    formFields[fieldName].isDirty = true;

    if (fieldName === "witnessCount") {
      let text =
        value !== ""
          ? !textInstruction.includes("witness")
            ? `<p>#${value} witness required.</p>`.concat(textInstruction)
            : textInstruction.replace(/\d+(?= witness required)/, value)
          : textInstruction.replace(/<p>#.*?<\/p>/, "");

      this.setState({
        textInstruction: text,
      });
    }

    if (fieldName === "ronPlatformType") {
      let text = value?.length
        ? !formFields.instructions.value.includes("MUST BE A RON APPROVED")
          ? `MUST BE A RON APPROVED NOTARY WITH UPLOADED RON CREDENTIAL IN PROFILE. MUST CONDUCT RON SIGNING ON ${value?.map(
              (each) => each?.value
            )}`.concat(formFields.instructions.value)
          : formFields.instructions.value.replace(
              /MUST BE A RON APPROVED NOTARY WITH UPLOADED RON CREDENTIAL IN PROFILE. MUST CONDUCT RON SIGNING ON \s*([\w\s,]+)/,
              `MUST BE A RON APPROVED NOTARY WITH UPLOADED RON CREDENTIAL IN PROFILE. MUST CONDUCT RON SIGNING ON ${value?.map(
                (each) => each?.value
              )}`
            )
        : formFields.instructions.value.replace(
            /MUST BE A RON APPROVED NOTARY WITH UPLOADED RON CREDENTIAL IN PROFILE. MUST CONDUCT RON SIGNING ON \s*([\w\s,]+)/,
            ""
          );

      formFields["instructions"].value = text;
    }

    formFields[fieldName].isDirty = true;
    this.setState({ formFields }, () => {
      // validation
      this._validateForm();

      if (fieldName === "witnessCount" || fieldName === "instructionId") {
        this._signerDataInstruction();
      }

      if (
        fieldName === "loanType" ||
        fieldName === "closingAddressState" ||
        fieldName === "closingAddressCity" ||
        fieldName === "closingSearch" ||
        fieldName === "signingType" ||
        (fieldName === "signingCompanyId" &&
          formFields.loanType.value.length > 0)
      ) {
        this._findStandardrate();
      }
    });
  };

  _signerDataInstruction = () => {
    const { formFields, textInstruction } = deepClone(this.state);
    let finalText = textInstruction.concat(
      formFields.instructionSignerData.value
    );
    formFields["instructions"].value = sortText(finalText);

    this.setState({ formFields });
  };

  _structureData = (updatedFormfields) => {
    const data = updatedFormfields?.map((each) => {
      let obj = {
        email: each?.email?.value,
        phone: {
          work: each?.workPhone?.value ? each?.workPhone?.value : undefined,
          alternate: each?.phoneHome?.value
            ? each?.phoneHome?.value
            : undefined,
        },
        name: {
          first: each?.name?.value?.split(" ").slice(0, -1).join(" "),
          last: each?.name?.value?.split(" ").slice(-1).join(" "),
        },
        isForeignNational: each?.isForeignNational?.value,
        language: each?.language?.value,
        languageTypeOther:
          each?.language?.value === "Other"
            ? each?.languageTypeOther?.value
            : undefined,
        address: {},
      };
      return obj;
    });
    return data;
  };

  _submitCreateSigningClosing = async () => {
    try {
      await this._markAllFieldDirty();

      const isFormValid = await this._validateForm();

      const res = await this.signerInfoGroupRef?.current?._handleSubmit();

      // const closingAddressResponse =
      //   await this.closingAddressRef?.current?._handleSubmit();
      // const borrowerAddressResponse =
      //   await this.borrowerAddressRef?.current?._handleSubmit();

      const addressResponse = await this.addressRef?.current?._handleSubmit();

      console.log("addressResponse >>", addressResponse);

      const {
        isClosingAddressValid,
        closingAddressResponse,
        borrowerAddressResponse,
      } = addressResponse;

      if (!isFormValid || !res?.isFormValid) {
        Object.keys(this.state.formFields).forEach((key) => {
          if (this.state.formFields[key].error) {
            const result = key.replace(/([A-Z])/g, " $1");
            const finalResult =
              result.charAt(0).toUpperCase() + result.slice(1);
            const finalResult2 =
              finalResult === "Loan Type"
                ? "Product Type"
                : result.charAt(0).toUpperCase() + result.slice(1);
            showToast(`Please provide ${finalResult2} `, "error");
          }
        });
        if (!this.state.formFields.fileNumber.value.trim().length > 0) {
          this._toggleFileCheckedModal(true);
        }
        this._manageLoading("submitLoading", false);
      }

      if (!isClosingAddressValid) {
        showToast(`Please provide valid Closing Address `, "error");
        this._manageLoading("draftLoading", false);
        this._manageLoading("submitLoading", false);
      }

      if (isFormValid && res?.isFormValid && isClosingAddressValid) {
        this.props.showLoader("Create order...");

        const { formFields, tBD, questions } = deepClone(this.state);

        let borrower = this._structureData(res?.updatedFormfields);

        const payload = {
          // borrower: [],

          signingCompanyFee: formFields.signingCompanyFee.value
            ? formFields.signingCompanyFee.value
            : undefined,
          fileNumber: formFields.fileNumber.value
            ? formFields.fileNumber.value
            : undefined,
          lender: formFields.lender.value ? formFields.lender.value : undefined,

          personOfContact: {
            name: formFields.personOfContactName.value,
            email: formFields.personOfContactEmail.value,
          },

          loanTypeOther:
            formFields.loanType.value.filter((item) => item.value === "Other")
              .length > 0
              ? formFields.loanTypeOther.value
              : undefined,
          tBD,

          closingAddress: {
            line1: closingAddressResponse?.addressLine1?.value || "",
            line2: closingAddressResponse?.addressLine2?.value || "",
            city: closingAddressResponse?.city?.value || "",
            state: closingAddressResponse?.state?.value || "",
            zip: closingAddressResponse?.zip.value || "",
            timeZone: formFields.closingAddressTimeZone.value
              ? formFields?.closingAddressTimeZone?.value
              : undefined,
          },

          instructions: formFields.instructions.value
            ? formFields.instructions.value
            : undefined,
          isScanBackNeeded: formFields.isScanBackNeeded.value,
        };

        payload["borrower"] = borrower;
        if (formFields.loanType.value) {
          payload["loanType"] = await formFields.loanType.value.map(
            (item) => item.value
          );
        }
        if (formFields.loanCategories.value) {
          payload["loanCategories"] = await formFields?.loanCategories?.value;
        }
        if (
          formFields.loanOfficer.value &&
          formFields.loanOfficer.value.trim().length
        ) {
          payload["loanOfficer"] = formFields.loanOfficer.value.trim();
        }

        if (
          formFields.signingType.value &&
          formFields.signingType.value.trim().length
        ) {
          payload["signingType"] = formFields.signingType.value.trim();
        }

        if (
          formFields.witnessCount.value &&
          formFields.witnessCount.value.trim().length
        ) {
          payload["witnessCount"] = Number(
            formFields.witnessCount.value.trim()
          );
        }

        if (formFields.appointmentDate.value) {
          if (!tBD) {
            payload["appointmentDate"] = formatDateMoment(
              formFields.appointmentDate.value
            );
          } else {
            payload["appointmentDate"] = formatOnlyDateMoment(
              formFields.appointmentDate.value
            );
          }
        }

        if (formFields.additionalSigner.value) {
          payload["additionalSigner"] = formFields.additionalSigner.value;
        }

        if (formFields?.signingCompanyId?.value) {
          payload["signingCompanyId"] = formFields.signingCompanyId.value;
        }

        // if (formFields.lenderClientId.value) {
        //   payload["lenderClientId"] = formFields.lenderClientId.value;
        // }
        if (
          formFields?.clientTeams?.value &&
          formFields?.clientTeams?.value?.trim()?.length
        ) {
          payload["_clientAssistantTeam"] =
            formFields?.clientTeams?.value?.trim();
        }

        if (
          formFields.internalNotes.value &&
          formFields.internalNotes.value.trim().length
        ) {
          payload["internalNotes"] = formFields.internalNotes.value.trim();
        }

        if (formFields.signingType.value === "RON")
          payload.ronPlatformTypes = formFields?.ronPlatformType?.value?.length
            ? formFields?.ronPlatformType?.value?.map((each) => each?.value)
            : undefined;

        if (
          formFields.companyNotes.value &&
          formFields.companyNotes.value.trim().length
        ) {
          payload["companyNotes"] = formFields.companyNotes.value.trim();
        }

        if (
          formFields.loanOfficer.value &&
          formFields.loanOfficer.value.trim().length
        ) {
          payload["loanOfficer"] = formFields.loanOfficer.value.trim();
        }

        if (borrowerAddressResponse?.zip?.value) {
          const address = extractValidFields(borrowerAddressResponse);
          const modifiedAddress = {
            ...address,
            line1: address.addressLine1,
            line2: address.addressLine2,
          };

          delete modifiedAddress.addressLine1;
          delete modifiedAddress.addressLine2;

          const newArray = borrower.map((obj) => ({
            ...obj,
            address: modifiedAddress,
          }));
          payload["borrower"] = newArray;
          console.log("payload >>>>>", payload);
        }
        if (formFields.ronPlatformType.value) {
          payload["ronPlatformTypes"] =
            await formFields.ronPlatformType.value.map((item) => item.value);
        }

        if (questions.length > 0) {
          payload["questions"] = questions;
        }
        payload["isScanBackNeeded"] = this._checkIfScanBackNeeded();
        //for create Closing
        this._createClientClosing(payload);
        // console.log("payload >>>>>", payload);
      }
    } catch (err) {
      errorHandler(err);
    }
  };

  _createClientClosing = async (data) => {
    try {
      await createClientClosing(data);

      showToast("Order has been created", "success");
      this.props.hideLoader();
      this._manageLoading("submitLoading", false);
      this.props.history.replace("/client/order-dashboard");
    } catch (error) {
      errorHandler(error);
      this.props.hideLoader();
      this._manageLoading("submitLoading", false);
    }
  };

  _markAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((e) => {
        if (formFields[e].isDirty !== undefined) formFields[e].isDirty = true;
      });

      this.setState({ formFields }, () => resolve(true));
    });
  };

  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  _toggleSameAddress = async (
    isBorrowerAddressSameAsAppointment = false,
    data = null
  ) => {
    if (isBorrowerAddressSameAsAppointment) {
      // if Checked
      // get closing address
      const closingAddressResponse =
        await this.closingAddressRef?.current?._getAddressData();

      this.setState({
        borrowerAddress:
          data !== null ? data : closingAddressResponse?.closingAddress,
        borrowerGoogleAddress:
          data !== null
            ? data?.googleAddressResult
            : closingAddressResponse?.googleAddressResult ||
              this?.state?.borrowerGoogleAddress,
        closingAddress:
          data !== null ? data : closingAddressResponse?.closingAddress,
      });
    } else {
      this.setState({
        borrowerAddress: null,
        borrowerGoogleAddress: "",
      });
    }
    this.setState({
      isBorrowerAddressSameAsAppointment,
      isAppointmentAddressSame: isBorrowerAddressSameAsAppointment,
    });
  };

  _onChipSelect = (chips) => {
    let isValid = chips.length ? isValidEmail(chips[chips.length - 1]) : true;
    if (isValid) {
      this.setState({
        formFields: {
          ...this.state.formFields,
          personOfContactEmail: {
            ...this.state.formFields.personOfContactEmail,
            value: chips,
          },
        },
      });
    }
  };

  // this is only for client To Be Determined the app. date
  _onChangeTBD = (tBD) => {
    this.setState({ tBD });
  };

  _toggleFileCheckedModal = (isOpen = false, data = null) => {
    this.setState({
      fileChecked: {
        isOpen,
        data,
      },
    });
  };

  _toggleFileNumberUniqueCheckedModal = (isOpen = false) => {
    this.setState({
      FileNumberUniqueChecked: {
        isOpen,
      },
    });
  };

  _approvedFileNumber = (value) => {
    const { formFields } = deepClone(this.state);
    formFields["fileNumber"].value = "CW-" + value?.FileNumber;
    this.setState({ formFields }, () => {
      this._toggleFileCheckedModal();
      this._validateForm();
    });
  };

  _onChangeFormFieldBorrower = (fieldName, value, index) => {
    const { formFields } = deepClone(this.state);

    this.setState({ formFields }, () => {
      this._validateForm();
    });
    this._addTextInInstruction(fieldName, value, index);
  };

  _addTextInInstruction = (subField, value, index) => {
    const { textInstruction } = deepClone(this.state);

    if (subField === "isForeignNational") {
      let text = value
        ? `<p>Signer ${index + 1} is a foreign National.</p>`.concat(
            textInstruction
          )
        : textInstruction.replace(
            `<p>Signer ${index + 1} is a foreign National.</p>`,
            ""
          );

      this.setState(
        {
          textInstruction: text,
        },
        () => {
          this._signerDataInstruction();
        }
      );
    }
    if (subField === "timeZone") {
      let text =
        value !== ""
          ? !textInstruction.match(
              new RegExp(`<p>Signer ${index + 1} is in [\\w/]+.</p>`, "g")
            )
            ? `<p>Signer ${index + 1} is in ${value}.</p>`.concat(
                textInstruction
              )
            : textInstruction.replace(
                new RegExp(`<p>Signer ${index + 1} is in [\\w/]+.</p>`, "g"),
                `<p>Signer ${index + 1} is in ${value}.</p>`
              )
          : textInstruction.replace(
              new RegExp(`<p>Signer ${index + 1} is in [\\w/]+.</p>`, "g"),
              ""
            );

      this.setState(
        {
          textInstruction: text,
        },
        () => {
          this._signerDataInstruction();
        }
      );
    }
    if (subField === "language") {
      let text =
        value !== ""
          ? !textInstruction.match(
              new RegExp(`<p>Signer ${index + 1} speaks \\w+\\.</p>`, "g")
            )
            ? `<p>Signer ${index + 1} speaks ${value}.</p>`.concat(
                textInstruction
              )
            : textInstruction.replace(
                new RegExp(`<p>Signer ${index + 1} speaks \\w+\\.</p>`, "g"),
                `<p>Signer ${index + 1} speaks ${value}.</p>`
              )
          : textInstruction.replace(
              new RegExp(`<p>Signer ${index + 1} speaks \\w+\\.</p>`, "g"),
              ""
            );

      this.setState(
        {
          textInstruction: text,
        },
        () => {
          this._signerDataInstruction();
        }
      );
    }
  };

  _isNumber = (e) => {
    var charCode = e.which ? e.which : e.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57))
      return e.preventDefault();
    return;
  };

  _findStandardrate = async (addressData) => {
    const { formFields } = deepClone(this.state);

    let closingAddress;
    const data = await this.addressRef?.current?._getAddressData();
    if (addressData === undefined) {
      closingAddress = data;
    } else {
      closingAddress = addressData;
    }

    // console.log("addressData >>", closingAddressResponse);

    let companySettingStandardFee = this._filterObject(
      closingAddress?.state?.value || "",
      closingAddress?.city?.value || "",
      formFields.loanType.value,
      formFields?.signingType?.value,
      this.props?.userData?.user?._id
    );

    // console.log("companySettingStandardFee >>", companySettingStandardFee);

    formFields["signingCompanyFee"].value =
      companySettingStandardFee[0]?.offerRate !== undefined
        ? companySettingStandardFee[0]?.offerRate?.toString()
        : formFields.signingCompanyFee.value;
    this.setState({ formFields }, () => {
      // console.log("1qw", formFields.signingCompanyFee);
    });
  };

  _filterObject = (state, city, productType, signingType, clientId) => {
    const { companyStandardFee, formFields } = this.state;
    console.log("qa", state, city, productType, signingType);

    let loanTypes = productType.map((item) => item.value);

    let filteredData =
      Array.isArray(companyStandardFee) && companyStandardFee.length > 0
        ? companyStandardFee.filter(
            (item) =>
              item.signingType === signingType &&
              loanTypes.includes(item.productType)
          )
        : [];

    console.log("filteredData1", filteredData);

    // if (state) {
    //   filteredData = filteredData.filter((item) => item.state === state);
    // } else {
    //   filteredData = filteredData.filter((item) => item?.state === "");
    // }
    if (state) {
      const matched = filteredData?.filter(
        (item) => item?.isForClient && item?.state === state
      );
      filteredData =
        matched.length > 0
          ? matched
          : Array.isArray(filteredData) &&
            filteredData?.filter((item) => item?.state === "");
      console.log("matchstate", matched);
    } else {
      // filteredData = filteredData?.filter((item) => item?.state === "");
    }
    console.log("filteredData2", filteredData);

    // if (city) {
    //   filteredData = filteredData.filter((item) =>
    //     item.county.trim().length > 0
    //       ? item.county
    //           .toLowerCase()
    //           .includes(formFields?.closingAddressCity?.value.toLowerCase())
    //       : true
    //   );
    // } else {
    //   filteredData = filteredData.filter((item) => !item?.county);
    // }

    if (city) {
      const matched = filteredData?.filter((item) =>
        item?.county?.toLowerCase().includes(city.toLowerCase())
      );
      console.log("matchcity", matched, filteredData);

      filteredData =
        matched.length > 0
          ? matched
          : filteredData?.filter(
              (item) =>
                item?.county === undefined || item?.county?.trim().length === 0
            );
      console.log("filterdatty", filteredData);
    } else {
      filteredData = filteredData?.filter((item) => !item?.county);
    }

    console.log("filteredData3", filteredData);

    if (clientId) {
      const matched = filteredData.find(
        (item) => item?.isForClient && item?._client === clientId
      );

      filteredData = matched
        ? [matched]
        : filteredData.filter(
            (item) => item?.isForClient && item?._client === undefined
          );
    } else {
      filteredData = filteredData.filter(
        (item) => item?.isForClient && item?._client === undefined
      );
    }

    console.log("filteredData4", filteredData);
    return filteredData;
  };

  _onChangeQuestionFormField = (value, index) => {
    const { questions } = deepClone(this.state);
    questions[index].reply = !questions[index].reply;

    this.setState({ questions });
  };

  _duplicateFileNumberCheck = async () => {
    let { fileNumber } = deepClone(this.state.formFields);
    try {
      this._manageLoading("submitLoading", true);
      const res = await FileNumberDuplicateMatchCheck({
        fileNumber: fileNumber.value,
      });

      if (res?.existingFileNos.length > 0) {
        this._toggleFileNumberUniqueCheckedModal(true);
        this.setState({ existingFileNos: res.existingFileNos });
        this._manageLoading("submitLoading", false);
      } else {
        this._submitCreateSigningClosing();
      }
    } catch (error) {
      errorHandler(error);
      this._manageLoading("submitLoading", false);
    }
  };

  _checkIfScanBackNeeded = () => {
    const { formFields, scanBackDocument } = deepClone(this.state);

    if (scanBackDocument?.isAlwaysRequired) {
      return true;
    } else {
      return scanBackDocument?.scanBackDocumentDetail?.some((detail) =>
        formFields.loanType.value.some(
          (each) => each.value === detail.productType
        )
      );
    }
  };

  _resetAddress = (fieldName, addressData) => {
    this.setState({ [`${fieldName}`]: addressData });
  };

  _enableOrDisableFeeField = () => {
    const { companyStandardFee, formFields } = deepClone(this.state);
    if (formFields.loanType.value.length > 1) {
      // enable on multiple loantype if user enters
      return false;
    } else {
      let loanTypes = formFields.loanType.value.map((item) => item.value);
      // check loantype is present in the array of companyStandardFee
      // console.log("loanTypes >>", loanTypes);
      // console.log("companyStandardFee >", companyStandardFee);
      // console.log(
      //   "companyStandardFee length >>",
      //   companyStandardFee.some((item) => loanTypes.includes(item.productType))
      // );
      return (
        Array.isArray(companyStandardFee) &&
        companyStandardFee.length > 0 &&
        companyStandardFee.some((item) => loanTypes.includes(item.productType))
      );
    }
  };

  render() {
    const {
      formFields,
      // isAppointmentAddressSame,
      tBD,
      lenderClients,
      signingInstruction,
      fileChecked,
      ronPlatformTypeOptions,
      loanType,
      // borrowerAddress,
      // closingAddress,
      clientTeamsList,
      questions,
      FileNumberUniqueChecked,
      isOpenAddInstruction,
      loading,
      existingFileNos,
      keepFileNo,
    } = deepClone(this.state);

    return (
      <>
        <div id="scroll">
          <div className="content">
            <div className="responsiveTitle">
              <div className="d-flex align-items-center">
                <Button
                  color="link"
                  className="backBtn"
                  onClick={() => this.props.history.goBack()}
                >
                  <img
                    src={require("../../../assets/img/arrowLeft.svg").default}
                    alt="backbutton"
                    height={14}
                    loading="lazy"
                  />
                </Button>
                <h2>Create Order</h2>
              </div>
            </div>
            <Suspense fallback={<></>}>
              <SignerInfoGroup
                ref={this.signerInfoGroupRef}
                _onChangeFormFieldBorrower={(fieldName, value, index) =>
                  this._onChangeFormFieldBorrower(fieldName, value, index)
                }
                data={this.state.borrowerData}
                isNeedToShownTimeZone={
                  formFields?.signingType?.value === "RON" ||
                  (formFields?.loanType?.value?.length === 1 &&
                    formFields?.loanType?.value[0]?.value === "AttorneyPhone")
                }
              />
            </Suspense>
            <hr />

            <div className="ClosingWrapper">
              <Row>
                <CreateClosingAddressComponent
                  xl={4}
                  lg={6}
                  md={6}
                  xm={12}
                  ref={this.addressRef}
                  borrowerAddress={this.state.borrowerAddress}
                  closingAddress={this.state.closingAddress}
                  closingGoogleAddress={this.state.closingGoogleAddress}
                  borrowerGoogleAddress={this.state.borrowerGoogleAddress}
                  isClosingAddressRequired={
                    formFields.signingType.value !== "RON" &&
                    !(
                      formFields?.loanType?.value?.length === 1 &&
                      formFields?.loanType?.value[0]?.value === "AttorneyPhone"
                    )
                  }
                  passData={(data) => this._findStandardrate(data)}
                />

                <Col xl={4} md={6}>
                  {!tBD ? (
                    <FormGroup
                      className={`floatingLabel ${
                        formFields.appointmentDate.value ||
                        this.state.isOpenReactDatetime
                          ? "valueAdded"
                          : ""
                      }`}
                    >
                      <ReactDatetime
                        inputProps={{
                          className: "form-control",
                          placeholder: " ",
                          value: formatDateAndTime(
                            formFields.appointmentDate.value
                          ),
                        }}
                        onChange={(e) =>
                          this._onChangeFormField("appointmentDate", e._d)
                        }
                        onOpen={() =>
                          this.setState({ isOpenReactDatetime: true })
                        }
                        onClose={() => {
                          this._validateForm();
                          this.setState({ isOpenReactDatetime: false });
                        }}
                        // isValidDate={(current) =>
                        //   current.isAfter(getYesterdayDate())
                        // }
                        timeFormat={true}
                        timeConstraints={{ minutes: { step: 15 } }}
                      />
                      <Label>Appointment Date & Time</Label>
                      {formFields.appointmentDate.error && (
                        <div className="validation-error">
                          {formFields.appointmentDate.error}
                        </div>
                      )}
                    </FormGroup>
                  ) : (
                    <FormGroup
                      //  className="floatingLabel"
                      className={`floatingLabel ${
                        formFields.appointmentDate.value ||
                        tBD ||
                        this.state.isOpenReactDatetime
                          ? "valueAdded"
                          : ""
                      }`}
                    >
                      <ReactDatetime
                        inputProps={{
                          className: "form-control",
                          placeholder: "Select",
                          value: formatDate(formFields.appointmentDate.value),
                        }}
                        // value={time.value ? new Date(time.value) : ""}
                        onChange={(e) =>
                          this._onChangeFormField("appointmentDate", e._d)
                        }
                        dateFormat={true}
                        // dateFormat={
                        //   closingDetails?._signingCompany ? false : true
                        // }
                        timeFormat={false}
                        // timeConstraints={{ minutes: { step: 15 } }}
                      />
                      <Label>Appointment Date</Label>
                    </FormGroup>
                  )}
                </Col>

                <Col xl={4} md={6}>
                  <CustomInput
                    className="mt-3"
                    type="checkbox"
                    id="appointmentDatetime_tBD"
                    label="Time T.B.D"
                    name="tBD"
                    checked={tBD}
                    onChange={(e) => this._onChangeTBD(e.target.checked)}
                  />
                </Col>
              </Row>
            </div>
            <hr />

            {/* SignerClosingInfo component is mostly for display purposeses only, 
                bussiness logic is written in the parent component itself.

                type -> agent, client, company -> will be used to show different items conditionally
            */}
            <Suspense fallback={<></>}>
              <SignerClosingInfo
                type="client"
                lenderClients={lenderClients}
                formFields={formFields}
                loanType={loanType}
                ronPlatformTypeOptions={ronPlatformTypeOptions}
                onChangeFormField={(fieldName, value) =>
                  this._onChangeFormField(fieldName, value)
                }
                onChipSelect={(arr, fieldName) =>
                  this._onChipSelect(arr, fieldName)
                }
                onSucessFullyClientAdd={this._getAllLenderClients}
                clientTeamsList={clientTeamsList}
                xl={4}
                lg={6}
                md={6}
                xm={12}
                enableOrDisableFeeField={this._enableOrDisableFeeField()}
              />
            </Suspense>

            <hr />

            <Suspense fallback={<></>}>
              <Instructions
                instructionData={formFields.instructions.value}
                onChangeFormField={this._onChangeFormField}
                signingInstruction={signingInstruction}
                instructionIdData={formFields.instructionId.value}
                isScanBackNeededData={formFields.isScanBackNeeded.value}
                questions={questions}
                onChangeQuestionFormField={this?._onChangeQuestionFormField}
              />
            </Suspense>

            <Button
              color="primary"
              className="mx-auto d-block mt-4"
              size="lg"
              onClick={() =>
                this.state.formFields?.fileNumber?.value?.length && !keepFileNo
                  ? this._duplicateFileNumberCheck()
                  : this._submitCreateSigningClosing()
              }
              disabled={loading?.submitLoading}
            >
              {loading?.submitLoading ? (
                <i className="fa fa-spinner fa-spin mr-2" />
              ) : null}{" "}
              Create
            </Button>
          </div>

          <Suspense fallback={<></>}>
            <AddOrderClient
              isOpen={isOpenAddInstruction}
              toggle={() => this.setState({ isOpenAddInstruction: false })}
              onSuccess={() => this._getAllLenderClients()}
            />
          </Suspense>

          <Suspense fallback={<></>}>
            <FileChecked
              isOpen={fileChecked.isOpen}
              approved={this._approvedFileNumber}
              toggle={() => this._toggleFileCheckedModal()}
            />
          </Suspense>
          {/* {FileNumberUniqueChecked?.isOpen ? (
            <FileNumberDuplicateModal
              isOpen={FileNumberUniqueChecked.isOpen}
              toggle={() => this._toggleFileNumberUniqueCheckedModal()}
              FileNumberSubmitHandler={() => this._submitCreateSigningClosing()}
            />
          ) : null} */}

          {FileNumberUniqueChecked?.isOpen ? (
            <FileNumberDuplicateModal
              isOpen={FileNumberUniqueChecked.isOpen}
              fileNumber={formFields.fileNumber.value}
              toggle={() => this._toggleFileNumberUniqueCheckedModal()}
              existingFileNos={existingFileNos}
              keep={(e) =>
                this.setState({ keepFileNo: true }, () => {
                  this._toggleFileNumberUniqueCheckedModal();
                  this._submitCreateSigningClosing(e);
                })
              }
              FileNumberSubmitHandler={
                (value) => this._onChangeFormField("fileNumber", value)
                // this._submitCreateSigningClosing(e)
              }
            />
          ) : null}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateClosing);
