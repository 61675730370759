import React, { Suspense, lazy } from "react";
import { connect } from "react-redux";
import { Button, Card, Col, ListGroupItem, Row } from "reactstrap";
import { RegexConfig } from "../../../config/RegexConfig";
import {
  capitalize,
  deepClone,
  formatAddressInSingleText,
  formatCurrencyValue,
  formatDateAsPerTimeZOne,
  formatDateHideYearIfSame,
  formatTime,
  openUrlOnNewTab,
  uploadFileOnServer,
  showToast,
  errorHandler,
} from "../../../helper-methods";
import {
  activityLogClosing,
  addAgentDocuments,
  addAgentExpenses,
  agentDownloadedDocumentClosing,
  agentUpdateClosingMileage,
  deleteAgentClosingExpense,
  deleteDocumentForClosing,
  getAgentClient,
  getAllAgentExpensesForClosing,
  getClosingDetailsById,
  updateClosingDetailsById,
} from "../../../http/http-calls";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import { showLoader, hideLoader } from "../../../redux/actions/loader-data";
import SignerAppointment from "../components/common/signerAppointment";
import Instructions from "../components/instructions";
import DownloadInvoiceModal from "../components/Modals/DownloadInvoiceModal";
import { LoanType } from "../../../config/loan-types";
import { expensesHeaderKeys } from "../../../config/stateConfig";
import { loanTypeConfig } from "../../../config";

const EditOrderDetailsModal = lazy(() =>
  import("../components/Modals/EditOrderDetailsModal")
);
const MilageExpanceTabs = lazy(() => import("../components/MilageExpanceTabs"));
const SecureDocumentCardComponent = lazy(() =>
  import("../components/SecureDocumentCardComponent")
);
const ChatLogCardComponent = lazy(() =>
  import("../components/ChatLogCardComponent")
);
// const TextEditor = lazy(() => import("../components/TextEdittor"));
const DocumentViewModal = lazy(() => import("../components/documentViewModal"));
const SignerAvailabilityModal = lazy(() =>
  import("../components/Modals/signer-availability-for-agents-modal")
);
const SigningStatusModal = lazy(() => import("../components/signing-status"));

class ClosingDetails extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef(); //newRef is now available for use throughout our component
    this.state = {
      closingDetails: null,
      closingExpenses: [],
      closingExpensesTotalAmount: 0,
      loading: {
        statusChangeLoading: false,
        fileUploadLoading: false,
        expensesLoading: false,
        deleteExpensesLoading: false,
        cardUpdateLoading: false,
        isDocumentCompletedLoading: false,
        mileageUpdateLoading: false,
      },
      signingStatusModal: {
        isOpen: false,
        data: null,
        status: null,
      },
      signerAvailabilityModal: {
        isOpen: false,
        data: null,
      },
      documentViewModalData: {
        isOpen: false,
        externalUrl: null,
        modalHeading: null,
        fileType: null,
      },
      expenseFormData: {
        item: {
          value: "",
          error: null,
        },
        amount: {
          value: "",
          error: null,
        },
        receipt: {
          file: {
            previewBlob: null,
            uploadData: null,
            type: null,
          },
          error: null,
        },
      },
      expensesHeaderKeys: expensesHeaderKeys,
      editMode: null,
      formFields: {
        instructionId: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        fileNumber: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        clientName: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        clientEmail: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        clientId: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        borrowerAddress: {
          value: "",
          placeId: null,
          error: null,
          isDirty: false,
          isValidate: true,
        },
        lender: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        trackingInfo: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: false,
        },
        personOfContactName: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        personOfContactEmail: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        signingType: {
          value: "Mobile",
          isDirty: false,
          isValidate: true,
        },
        witnessCount: {
          value: 0,
          isDirty: false,
          isValidate: true,
        },
        loanOfficer: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: false,
        },
        titleCompany: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        agentFee: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        closingAddress: {
          value: "",
          placeId: null,
          error: null,
          isDirty: false,
          isValidate: true,
        },
        agentNotes: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: false,
        },
        personalNotes: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: false,
        },
        instructions: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: false,
        },
        miles: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: false,
        },
        loanType: {
          value: [],
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        loanTypeOther: {
          value: "",
          isDirty: false,
          isValidate: true,
        },
      },
      googleAddressResult: [],
      isAddressListShow: false,
      appointmentMapType: "google",
      activityLog: [],
      activityLogPage: {
        skip: 0,
        limit: 5,
        totalCount: null,
      },
      clients: [],
      editOrderDetailsModal: {
        isOpen: false,
        data: null,
      },
      downloadInvoiceModal: {
        isOpen: false,
        data: null,
      },
      isOpenCollapse: true,
      seed: 0, // used to refresh the component it is passed to as key
    };
  }

  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  _updateClosingState = (closingDetails) => {
    this.setState({ closingDetails });
  };

  componentDidMount = async () => {
    this._getAgentClient();

    let options = LoanType.map((item) => ({
      value: item,
      label: item.replace(/([A-Z])/g, " $1").trim(),
    }));
    options.splice(0, 0, { value: "", label: "Select" });
    this.setState({ loanType: options });
    try {
      // const id = this.props.match.params.id;
      const id = this.props.match.params?.id?.includes("guestuser")
        ? this.props.match.params?.id?.replace("&guestuser", "")
        : this.props.match.params?.id;
      this.props.showLoader("Fetching Closing Details...");
      await this._getClosingDetailsById(id);
      await this._getAllAgentExpensesForClosing(id);
      this.props.hideLoader();
    } catch (error) {
      errorHandler(error);
      this.props.hideLoader();
      this._redirectToDashboard();
    }

    HeaderEventEmitter.subscribe("back-button", () => {
      this._goBackBtn();
    });
    HeaderEventEmitter.subscribe("download-invoice", () => {
      this._onToggleDownloadInvoiceModal(true, this.state?.closingDetails);
    });

    document.querySelector("#scroll").scrollTo(0, 0);
  };

  _goBackBtn = () => {
    // eslint-disable-next-line no-unused-vars
    const { closingDetails } = this.state;

    // this.props.history.push(`/agent/dashboard`);
    this.props.history.goBack();
  };

  _getAgentClient = () => {
    getAgentClient()
      .then((res) => {
        this.setState({ clients: res?.clients || [] });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  //fetch activity log
  _activityLogClosing = () => {
    //fetch activity log
    const { activityLogPage } = deepClone(this.state);

    let payload = `closingId=${this.props.match.params.id}&skip=${activityLogPage.skip}&limit=${activityLogPage.limit}`;

    activityLogClosing(payload)
      .then((res) => {
        this.setState({
          activityLog: res?.logs || [],
          activityLogPage: {
            ...this.state.activityLogPage,
            totalCount: res?.totalCount || 0,
          },
        });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  _getClosingDetailsById = async (id = null) => {
    if (!id && this.state.closingDetails && this.state.closingDetails.id) {
      id = this.state.closingDetails.id;
    }

    try {
      const res = await getClosingDetailsById(id);

      this._handleActivityLogPagination();
      this.setState(
        {
          closingDetails: res?.closing || {},
          seed: Math.random(), // used for component refresh purposes
        },
        () => {
          this._setFormFields();
        }
      );

      HeaderEventEmitter.dispatch("closing-details", {
        closingDetails: res?.closing || {},
      });

      return res;
    } catch (error) {
      throw error;
    }
  };

  // update CLosing Detail by Id
  _updateClosingDetailsById = (id, payload) => {
    return new Promise((resolve, reject) => {
      updateClosingDetailsById({ id, payload })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  //Add Document
  _addAgentDocuments = (id, payload) => {
    addAgentDocuments(id, payload)
      .then((res) => {
        this._getClosingDetailsById(id);
        showToast("Document Updated", "success");
        this._manageLoading("fileUploadLoading", false);
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("fileUploadLoading", false);
      });
  };

  // Deleate expense
  _deleteAgentClosingExpense = (expensesId) => {
    this._manageLoading("deleteExpensesLoading", true);

    const { closingDetails } = deepClone(this.state);

    deleteAgentClosingExpense(closingDetails.id, expensesId)
      .then(async (res) => {
        await this._getAllAgentExpensesForClosing(closingDetails.id);
        showToast("Expense Deleted", "success");
        this._manageLoading("deleteExpensesLoading", false);
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("deleteExpensesLoading", false);
      });
  };

  _validateAgentExpenseForm = () => {
    return new Promise((resolve, reject) => {
      const { expenseFormData } = deepClone(this.state);

      let isFormValid = true;
      let errors = {};

      Object.keys(expenseFormData).forEach((key) => {
        switch (key) {
          case "item": {
            if (
              expenseFormData[key].value &&
              expenseFormData[key].value.trim().length
            ) {
              expenseFormData[key].error = null;
            } else {
              expenseFormData[key].error = "*Required";
              errors[key] = "*Required";
              isFormValid = false;
            }
            break;
          }
          case "amount": {
            if (
              expenseFormData[key].value &&
              expenseFormData[key].value.trim().length
            ) {
              if (
                isNaN(expenseFormData[key].value) ||
                Number(expenseFormData[key].value) <= 0
              ) {
                expenseFormData[key].error =
                  "*Amount must be a positive number & greater than 0";
                errors[key] =
                  "*Amount must be a positive number & greater than 0";
                isFormValid = false;
              } else {
                expenseFormData[key].error = null;
              }
            } else {
              expenseFormData[key].error = "*Required";
              errors[key] = "*Required";
              isFormValid = false;
            }
            break;
          }
          default: {
          }
        }
      });

      if (errors && Object.keys(errors) && Object.keys(errors).length) {
        setTimeout(() => this._resetExpenseFormDataError(), 4000);
      }

      this.setState({ expenseFormData }, () => resolve(isFormValid));
    });
  };

  // reset Expense form data error
  _resetExpenseFormDataError = () => {
    const { expenseFormData } = deepClone(this.state);
    Object.keys(expenseFormData).forEach((key) => {
      expenseFormData[key].error = null;
    });
    this.setState({ expenseFormData });
  };

  // reset Expense form data

  _resetExpenseFormData = () => {
    this.setState(
      {
        expenseFormData: {
          item: {
            value: "",
            error: null,
          },
          amount: {
            value: "",
            error: null,
          },
          receipt: {
            file: {
              previewBlob: null,
              uploadData: null,
              type: null,
            },
            error: null,
          },
        },
      },
      () => {
        this._manageLoading("expensesLoading", false);
      }
    );
  };

  _getAllAgentExpensesForClosing = async (id = null) => {
    try {
      if (!id && this.state.closingDetails && this.state.closingDetails.id) {
        id = this.state.closingDetails.id;
      }

      const res = await getAllAgentExpensesForClosing(id);

      this.setState({
        closingExpenses: res?.expenses || [],
        closingExpensesTotalAmount: res?.totalAmount || 0,
      });

      return res;
    } catch (error) {
      throw error;
    }
  };

  // add expense
  _addAgentExpenses = async (id) => {
    const isFormValid = await this._validateAgentExpenseForm();

    if (isFormValid) {
      this._manageLoading("expensesLoading", true);

      const { expenseFormData } = deepClone(this.state);

      const payload = {
        expenses: {
          item: expenseFormData.item.value.trim(),
          amount: Number(expenseFormData.amount.value),
          // receipt: '',
        },
      };

      if (
        expenseFormData.receipt &&
        expenseFormData.receipt.file &&
        expenseFormData.receipt.file.uploadData
      ) {
        const uploadedFilesRes = await uploadFileOnServer([
          expenseFormData.receipt.file,
        ]);
        payload.expenses["receipt"] = uploadedFilesRes[0].url;
        payload.expenses["docType"] = uploadedFilesRes[0].docType;
      }

      addAgentExpenses(id, payload)
        .then(async (res) => {
          this._resetExpenseFormData();
          await this._getAllAgentExpensesForClosing(id);
          showToast("Expense Updated", "success");
        })
        .catch((error) => {
          errorHandler(error);
          this._manageLoading("expensesLoading", false);
        });
    }
  };

  // delete expense

  _deleteExpenseFile = () => {
    const { expenseFormData } = deepClone(this.state);
    expenseFormData["receipt"] = {
      file: {
        previewBlob: null,
        uploadData: null,
        type: null,
      },
      error: null,
    };
    this.setState({ expenseFormData });
  };

  _onChangeExpenseForm = (fieldName, value) => {
    const { expenseFormData } = deepClone(this.state);
    if (fieldName === "amount") {
      if (
        isNaN(value) ||
        (value.includes(".") && value.split(".")[1].length > 2)
      ) {
        return;
      }
    }
    expenseFormData[fieldName].value = value;
    this.setState({ expenseFormData });
  };

  // update closing status by id
  _updateClosingDetailsByIdStatus = async (data, status) => {
    // open signing status modal for closed ,dnc ,cca
    try {
      if (
        status.status === "Closed" ||
        status.status === "DNC" ||
        status.status === "CCA"
      ) {
        this._onToggleSigningStatusModal(true, data, status.status);
      } else {
        this._manageLoading("statusChangeLoading", true);

        //  update closing details
        const updateAgentRes = await this._updateClosingDetailsById(
          data.id,
          status
        );

        showToast("Status Updated Successfully", "success");
        const { closingDetails } = deepClone(this.state);
        closingDetails["status"] = updateAgentRes?.closing?.status;
        closingDetails["isAgentViewDocument"] =
          updateAgentRes?.closing?.isAgentViewDocument;
        // this._handleActivityLogPagination();
        // this._getClosingDetailsById();
        this.setState({ closingDetails });
        this._manageLoading("statusChangeLoading", false);
      }
    } catch (error) {
      errorHandler(error);
      this._manageLoading("statusChangeLoading", false);
    }
  };

  // upload file for expense or closing document
  _updateFile = async (uploadType, event) => {
    const { closingDetails, expenseFormData } = deepClone(this.state);

    let objFile, objFileType;

    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length
    ) {
      if (uploadType === "expense") {
        objFile = event.target.files[0];
        objFileType = objFile.type.split("/")[0];
        if (objFileType === "image" || objFile.type.includes("pdf")) {
          expenseFormData.receipt.file = {
            previewBlob: URL.createObjectURL(objFile),
            uploadData: objFile,
            type: objFileType === "application" ? "pdf" : objFileType,
          };

          this.setState({ expenseFormData });
        } else {
          showToast("Only Image or PDF file is allowed", "error");
        }
      } else if (uploadType === "document") {
        this._manageLoading("fileUploadLoading", true);
        let uploadFiles = [];

        for (let index = 0; index < event.target.files.length; index++) {
          objFile = event.target.files[index];
          objFileType = objFile.type.split("/")[0];
          uploadFiles.push({
            previewBlob: URL.createObjectURL(objFile),
            uploadData: objFile,
            type: objFileType === "application" ? "pdf" : objFileType,
          });
        }
        if (uploadFiles && uploadFiles.length) {
          const documents = await uploadFileOnServer(uploadFiles);
          this._addAgentDocuments(closingDetails._id, { documents });
        }
      } else {
        showToast("Upload type is required");
        return;
      }
    }
  };

  _redirectToDashboard = (e) => {
    if (e) e.preventDefault();
    const { userData } = this.props;
    this.props.history.push(`/${userData.type}/dashboard`);
  };

  // signing status modal open/close
  _onToggleSigningStatusModal = (
    isOpen = false,
    data = null,
    status = null
  ) => {
    this.setState({
      signingStatusModal: {
        isOpen,
        data,
        status,
      },
    });
  };

  // signing  availabilty modal open/close

  _onToggleSignerAvailabilityModal = (isOpen = false, data = null) => {
    this.setState({
      signerAvailabilityModal: {
        isOpen,
        data,
      },
    });
  };

  _onToggleDownloadInvoiceModal = (isOpen = false, data = null) => {
    this.setState({
      downloadInvoiceModal: {
        isOpen,
        data,
      },
    });
  };

  _onToggleDocumentViewModal = (doc = null, isOpen = false) => {
    let { documentViewModalData } = deepClone(this.state);
    if (doc && isOpen) {
      documentViewModalData = {
        isOpen: true,
        externalUrl: doc.url,
        modalHeading: doc.title,
        fileType: doc.docType,
      };
    } else {
      documentViewModalData = {
        isOpen: false,
        externalUrl: null,
        modalHeading: null,
        fileType: null,
      };
    }
    this.setState({ documentViewModalData });
  };

  // document data format for documnet
  _documentDataFormat = (cell, row, header) => {
    let { closingDetails } = deepClone(this.state);

    switch (header) {
      case "title": {
        return row ? (
          <>
            {row.docType === "pdf" ? (
              <i className="fa fa-file-pdf-o mr-1" />
            ) : (
              <i className="fa fa-file-text-o mr-1" />
            )}
            {row.title}
          </>
        ) : (
          "N/A"
        );
      }
      case "createdAt": {
        return cell ? (
          <>
            {formatDateHideYearIfSame(cell)} <br /> {formatTime(cell)}
          </>
        ) : (
          "N/A"
        );
      }
      case "action": {
        return row ? (
          <>
            <Button
              color="link"
              className="actionBtn"
              onClick={() => this._onToggleDocumentViewModal(row, true)}
            >
              <i className="fa fa-eye" />
            </Button>
            <Button
              color="link"
              className="actionBtn"
              onClick={() => this._downloadDocument(row)}
            >
              <i className="fa fa-download" />
            </Button>

            {!row.isPrivate &&
            !closingDetails.isDocumentCompletedByAgent &&
            !row?.isCreatedForAgent ? (
              <Button
                color="link"
                className="actionBtn"
                onClick={() => this._deleteDocumentClosing(row)}
              >
                <i className="fa fa-trash-o text-danger" />
              </Button>
            ) : null}
          </>
        ) : (
          "N/A"
        );
      }
      default: {
        return cell;
      }
    }
  };
  // download document
  _downloadDocument = (row) => {
    let { closingDetails } = deepClone(this.state);
    let documentPayload = {
      document: { isDownloadedByAgent: true, _id: row._id },
    };
    if (!closingDetails?.isDownloadedByAgent) {
      this._updateClosingDetailsByIdStatus(closingDetails, documentPayload);
    }
    let payload = {
      closingId: closingDetails._id,
      documentId: row._id,
    };
    agentDownloadedDocumentClosing(payload)
      .then((res) => {})
      .catch((error) => {
        errorHandler(error);
      });
    openUrlOnNewTab(row.url);
  };

  // delete document
  _deleteDocumentClosing = (row) => {
    const { closingDetails } = deepClone(this.state);
    let payload = { documentId: row._id };
    this.props.showLoader("Deleting Document...");

    deleteDocumentForClosing(closingDetails._id, payload)
      .then((res) => {
        this._getClosingDetailsById();
        showToast("Document Deleted Successfully", "success");
        this.props.hideLoader();
      })
      .catch((error) => {
        errorHandler(error);
        this.props.hideLoader();
      });
  };

  _expenseDataFormat = (cell, row, header) => {
    switch (header) {
      case "item": {
        return cell ? capitalize(cell) : "N/A";
      }
      case "amount": {
        return cell ? formatCurrencyValue(cell) : "N/A";
      }
      case "receipt": {
        return cell ? (
          <Button
            color="link"
            className="actionBtn"
            onClick={() => openUrlOnNewTab(cell)}
          >
            <i className="fa fa-download" />
          </Button>
        ) : (
          "N/A"
        );
      }
      case "action": {
        return row ? (
          <Button
            color="link"
            className="actionBtn"
            title="Delete Expense"
            disabled={this.state.loading.deleteExpensesLoading}
            onClick={() => this._deleteAgentClosingExpense(row._id)}
          >
            <i className="fa fa-trash-o text-danger" />
          </Button>
        ) : (
          "N/A"
        );
      }
      default: {
        return cell;
      }
    }
  };

  // reset form fields
  _resetFormFields = () => {
    const defaultField = {
      value: "",
      error: null,
      isDirty: false,
      isValidate: true,
    };

    const defaultValue = {
      value: "",
      error: null,
      isDirty: false,
      isValidate: false,
    };

    this.setState({
      formFields: {
        name: { ...defaultField },
        email: { ...defaultField },
        phoneHome: { ...defaultField },
        phoneWork: { ...defaultField },
        phoneMobile: { ...defaultField },

        fileNumber: { ...defaultField },
        clientName: { ...defaultField },
        clientEmail: { ...defaultField },
        clientId: { ...defaultField },
        borrowerAddress: {
          value: "",
          placeId: null,
          error: null,
          isDirty: false,
          isValidate: true,
        },
        lender: {
          ...defaultField,
        },
        trackingInfo: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        personOfContactName: {
          ...defaultField,
        },
        personOfContactEmail: {
          value: [],
          error: null,
          isDirty: false,
          isValidate: true,
        },
        signingType: {
          value: "Mobile",
          isDirty: false,
          isValidate: true,
        },
        witnessCount: {
          value: 0,
          isDirty: false,
          isValidate: true,
        },
        loanOfficer: {
          ...defaultValue,
        },
        titleCompany: {
          ...defaultField,
        },
        agentFee: {
          ...defaultField,
        },
        closingAddress: {
          value: "",
          placeId: null,
          error: null,
          isDirty: false,
          isValidate: true,
        },
        agentNotes: {
          ...defaultValue,
        },
        personalNotes: {
          ...defaultValue,
        },
        instructions: {
          ...defaultValue,
        },
        miles: {
          ...defaultValue,
        },
        loanType: {
          value: [],
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        loanTypeOther: {
          value: "",
          isDirty: false,
          isValidate: true,
        },
      },
      googleAddressResult: [],
    });
  };

  _setFormFields = async () => {
    const { formFields, closingDetails } = deepClone(this.state);
    if (closingDetails) {
      formFields["fileNumber"].value = closingDetails.fileNumber
        ? closingDetails.fileNumber
        : "";
      formFields["lender"].value = closingDetails.lender
        ? closingDetails.lender
        : "";
      formFields["loanOfficer"].value = closingDetails.loanOfficer
        ? closingDetails.loanOfficer
        : "";
      formFields["titleCompany"].value = closingDetails.titleCompany
        ? closingDetails.titleCompany
        : "";
      formFields["agentFee"].value = closingDetails.agentFee
        ? closingDetails.agentFee.toString()
        : "";

      formFields["loanType"].value = closingDetails?.loanType.map((each) => ({
        label: each,
        value: each,
      }));
      formFields["loanTypeOther"].value = closingDetails?.loanTypeOther || "";

      if (closingDetails._client) {
        formFields["clientName"].value = closingDetails._client.name.full
          ? closingDetails._client.name.full
          : "";
        formFields["clientEmail"].value = closingDetails._client.email
          ? closingDetails._client.email
          : "";
        formFields["clientId"].value = closingDetails._client.id
          ? closingDetails._client.id
          : "";
      }

      formFields["trackingInfo"].value = closingDetails.trackingInfo
        ? closingDetails.trackingInfo
        : "";

      if (closingDetails.personOfContact) {
        formFields["personOfContactName"].value = closingDetails.personOfContact
          .name
          ? closingDetails.personOfContact.name
          : "";
        formFields["personOfContactEmail"].value = closingDetails
          .personOfContact.email
          ? closingDetails?.personOfContact?.email?.filter((e) => e !== "")
          : [];
      }

      if (closingDetails._borrower.address) {
        formFields["borrowerAddress"].value = formatAddressInSingleText(
          closingDetails._borrower.address
        );
        formFields["borrowerAddress"].placeId = "initialValue";
      }

      if (closingDetails.closingAddress) {
        formFields["closingAddress"].value = formatAddressInSingleText(
          closingDetails.closingAddress
        );
        formFields["closingAddress"].placeId = "initialValue";
      }

      formFields["agentNotes"].value = closingDetails.agentNotes || "";
      formFields["personalNotes"].value = closingDetails.personalNotes || "";
      formFields["instructions"].value = closingDetails.instructions
        ? closingDetails.instructions
        : "";

      formFields["miles"].value = closingDetails._mileage?.distance
        ? closingDetails._mileage.distance.toString()
        : "0";
    }
    this.setState({ formFields });
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields, editMode } = this.state;

      let isFormValid = true;

      Object.keys(formFields).forEach((key) => {
        if (
          key === "borrower" ||
          (formFields[key].isDirty && formFields[key].isValidate)
        ) {
          if (editMode === "appointment") {
            switch (key) {
              case "borrower": {
                formFields.borrower.forEach((each, index) => {
                  if (each.borrowerName?.value.trim().length) {
                    formFields[key][index]["borrowerName"].isDirty = false;
                    formFields[key][index]["borrowerName"].error = null;
                  } else {
                    formFields[key][index]["borrowerName"].isDirty = true;
                    formFields[key][index]["borrowerName"].error = "*Required";
                    isFormValid = false;
                  }

                  if (
                    formFields[key][index]["email"].value &&
                    formFields[key][index]["email"].value.trim().length
                  ) {
                    if (
                      RegexConfig.email.test(
                        String(
                          formFields[key][index]["email"].value
                        ).toLowerCase()
                      )
                    ) {
                      formFields[key][index]["email"].isDirty = false;
                      formFields[key][index]["email"].error = null;
                    } else {
                      formFields[key][index]["email"].isDirty = true;
                      formFields[key][index]["email"].error = "*Invalid Email";
                      isFormValid = false;
                    }
                  } else {
                    formFields[key][index]["email"].isDirty = false;
                    formFields[key][index]["email"].error = null;
                  }

                  if (
                    formFields[key][index]["workPhone"].value &&
                    formFields[key][index]["workPhone"].value.length
                  ) {
                    if (
                      RegexConfig.phone.test(
                        String(
                          formFields[key][index]["workPhone"].value
                        ).toLowerCase()
                      )
                    ) {
                      formFields[key][index]["workPhone"].isDirty = false;
                      formFields[key][index]["workPhone"].error = null;
                    } else {
                      isFormValid = false;
                      formFields[key][index]["workPhone"].isDirty = true;
                      formFields[key][index]["workPhone"].error =
                        "*Invalid Phone Number";
                    }
                  } else {
                    formFields[key][index]["workPhone"].isDirty = false;
                    formFields[key][index]["workPhone"].error = null;
                  }
                });
                break;
              }
              case "closingAddress": {
                if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  if (formFields[key].placeId) {
                    formFields[key].isDirty = false;
                    formFields[key].error = null;
                  } else {
                    formFields[key].isDirty = true;
                    formFields[key].error = "*Invalid Address";
                    isFormValid = false;
                  }
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
                break;
              }
              default:
            }
          } else if (editMode === "orderDetails") {
            switch (key) {
              case "clientId":
              case "fileNumber":
              case "personOfContactName":
              case "lender": {
                if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }

                break;
              }
              case "agentFee": {
                if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  if (
                    isNaN(formFields[key].value.trim()) ||
                    Number(formFields[key].value.trim()) < 0
                  ) {
                    formFields[key].isDirty = true;
                    formFields[key].error = "*Fee must be a positive number";
                    isFormValid = false;
                  } else {
                    formFields[key].isDirty = false;
                    formFields[key].error = null;
                  }
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
                break;
              }
              case "loanTypeOther": {
                if (formFields.loanType.value === "Other") {
                  if (
                    formFields[key].value &&
                    formFields[key].value.trim().length
                  ) {
                    formFields[key].isDirty = false;
                    formFields[key].error = null;
                  } else {
                    formFields[key].isDirty = true;
                    formFields[key].error = "*Required";
                    isFormValid = false;
                  }
                }
                break;
              }
              // case "clientEmail":
              case "loanType":
              case "personOfContactEmail": {
                if (formFields[key].value.length) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
                break;
              }
              default:
            }
          }
        }
      });

      this.setState({ formFields }, () => {
        resolve(isFormValid);
      });
    });
  };

  // select address from suggested list
  _onSelectAddress = (fieldName, addr) => {
    const { formFields } = deepClone(this.state);
    formFields[fieldName].value = addr.description;
    formFields[fieldName].placeId = addr.place_id;
    formFields[fieldName].isDirty = true;
    this.setState({ formFields, isAddressListShow: false }, () => {
      this._validateForm();
    });
  };

  _onChangeFormField = (fieldName, value) => {
    const { formFields } = deepClone(this.state);

    if (fieldName === "agentFee" || fieldName === "miles") {
      if (
        isNaN(value) ||
        (value.includes(".") && value.split(".")[1].length > 2)
      ) {
        return;
      }
    }

    console.log("fieldName", fieldName);
    formFields[fieldName].value = value;
    formFields[fieldName].isDirty = true;
    this.setState({ formFields }, () => {
      // validation
      this._validateForm();
    });
  };

  // make all field dirty
  _markAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((e) => {
        if (formFields[e].isDirty !== undefined) formFields[e].isDirty = true;
      });
      this.setState({ formFields }, () => resolve(true));
    });
  };

  // save closing
  _onSaveClosing = async (cardName) => {
    try {
      await this._markAllFieldDirty();

      const isFormValid = await this._validateForm();

      if (isFormValid) {
        this._manageLoading("cardUpdateLoading", true);

        const { formFields } = this.state;

        let payload = {};

        switch (cardName) {
          case "instructions": {
            payload = formFields.instructions.value
              ? {
                  instructions: formFields.instructions.value,
                }
              : undefined;

            break;
          }
          default:
        }
        if (payload && Object.keys(payload) && Object.keys(payload).length) {
          const { closingDetails, formFields } = deepClone(this.state);

          let updatedRes = await this._updateClosingDetailsById(
            closingDetails.id,
            payload
          );
          // await this._getClosingDetailsById(closingDetails.id);
          closingDetails["instructions"] = updatedRes.closing.instructions;
          formFields["instructions"].value = updatedRes.closing.instructions
            ? updatedRes.closing.instructions
            : "";
          this.setState({ closingDetails, formFields });
          showToast("Closing Details Updated", "success");
          this._manageLoading("cardUpdateLoading", false);
          // this._onToggleEditMode();
          this.setState({ editMode: null });
        } else {
          this._manageLoading("cardUpdateLoading", false);
          // this._onToggleEditMode();
          this.setState({ editMode: null });
        }
      }
    } catch (error) {
      errorHandler(error);
      this._manageLoading("cardUpdateLoading", false);
    }
  };

  // close edit mode
  _onCancelEditMode = (event) => {
    const { editMode } = deepClone(this.state);
    switch (editMode) {
      case "borrower": {
        if (
          this.borrowerCardRef &&
          !this.borrowerCardRef.contains(event.target)
        ) {
          this._onToggleEditMode();
        }
        break;
      }
      case "orderDetails": {
        if (
          this.orderDetailsCardRef &&
          !this.orderDetailsCardRef.contains(event.target)
        ) {
          this._onToggleEditMode();
        }
        break;
      }
      case "appointment": {
        if (
          this.appointmentCardRef &&
          !this.appointmentCardRef.contains(event.target)
        ) {
          this._onToggleEditMode();
        }
        break;
      }
      case "instructions": {
        if (
          this.instructionsCardRef &&
          !this.instructionsCardRef.contains(event.target)
        ) {
          this._onToggleEditMode();
        }
        break;
      }
      default: {
      }
    }
  };

  // close edit mode on mouse down
  _onToggleEditMode = (editMode = null) => {
    if (
      this.state.closingDetails &&
      !this.state.closingDetails?.isCreatedForAgent &&
      editMode &&
      editMode !== "appointment"
    ) {
      showToast("Server error. Try again after sometime.", "error");
      return;
    }
    if (!this.state.loading.cardUpdateLoading) {
      if (editMode) {
        this._setFormFields();
        document.addEventListener("mousedown", this._onCancelEditMode);
      } else {
        // this._resetFormFields();
        document.removeEventListener("mousedown", this._onCancelEditMode);
      }
      this.setState({ editMode });
    }
  };

  _toggleActualMilesEdit = async () => {
    try {
      const { isActualMilesEdit, formFields, closingDetails } = this.state;

      if (isActualMilesEdit && closingDetails?._mileage?.id) {
        this._manageLoading("mileageUpdateLoading", true);
        let payload = {
          distance:
            formFields.miles.value?.trim() &&
            !isNaN(formFields.miles.value.trim())
              ? Number(formFields.miles.value.trim())
              : 0,
          closingId: closingDetails.id,
        };
        await agentUpdateClosingMileage(closingDetails._mileage.id, payload);
        await this._getClosingDetailsById(closingDetails.id);
        this._resetFormFields();
        showToast("Miles Updated", "success");
        this._manageLoading("mileageUpdateLoading", false);
      }

      if (!isActualMilesEdit) {
        this._setFormFields();
      }

      this.setState({
        isActualMilesEdit: !isActualMilesEdit,
      });
    } catch (error) {
      errorHandler(error);
      this._manageLoading("mileageUpdateLoading", false);
    }
  };

  // redirect
  _closingDashboard = (e) => {
    if (e) e.preventDefault();
    const { userData } = this.props;
    let restLink = "";
    if (userData.type === "agent") {
      restLink = "dashboard";
    } else if (userData.type === "company") {
      restLink = "closing-dashboard";
    }
    this.props.history.push(`/${userData.type}/${restLink}`);
  };

  //pagination for activity log
  _handleActivityLogPagination = (pageNo = 1) => {
    const { activityLogPage } = deepClone(this.state);

    activityLogPage["skip"] = (pageNo - 1) * activityLogPage.limit;

    this.setState({ activityLogPage }, () => {
      this._activityLogClosing();
    });
  };

  // render activity
  _activityLog = () => {
    const { activityLog, closingDetails } = deepClone(this.state);

    return (
      activityLog?.length > 0 &&
      activityLog.map((item, key) => {
        return (
          <ListGroupItem
            key={key}
            style={{
              justifyContent: "flex-start",
              flexWrap: "wrap",
              fontWeight: 400,
              color: "#767676",
            }}
          >
            {item.activityType !== "ClosingAssigned"
              ? item.isDocumentCompleted
                ? null
                : item._user?.name?.full
              : "New Order"}{" "}
            {item.activityType === "Documents"
              ? item.isDocumentCompletedByAgent
                ? "Marked Documents"
                : item.isDocumentCompleted
                ? "Documents upload completed"
                : item.isDocumentVerified
                ? "has Verified"
                : "Uploaded"
              : item.activityType === "AppointmentStatus" ||
                item.activityType === "AppointmentDate" ||
                (item.activityType === "ClosingStatus" &&
                  item.to !== "Arrived") ||
                item.activityType === "LeftMessage"
              ? "Changed"
              : item.activityType === "ClosingStatus" && item.to === "Arrived"
              ? "Arrived at the location for the appointment"
              : item.activityType === "ClosingCreated"
              ? "Created the Closing"
              : item.activityType === "DocumentsDeleted"
              ? "Deleted the Document"
              : "Updated"}{" "}
            {item.activityType === "Notes"
              ? item.notesType.replace(/([A-Z])/g, " $1").trim()
              : item.activityType === "Documents"
              ? item.isDocumentCompleted
                ? "by"
                : item.isDocumentCompletedByAgent
                ? "as Scanback Upload Complete"
                : item.isDocumentVerified
                ? "Agent Document"
                : "a New File"
              : item.activityType === "ClosingCreated"
              ? ""
              : item.activityType === "LeftMessage"
              ? "Appointment Status"
              : item.activityType === "ClosingAssigned" ||
                (item.activityType === "ClosingStatus" && item.to === "Arrived")
              ? null
              : item.activityType === "DocumentsDeleted"
              ? item?.documents[0].title
              : item.activityType.replace(/([A-Z])/g, " $1").trim()}{" "}
            {item.activityType === "Documents"
              ? item.documents.map((each) => (
                  <Button color="link" className="showMap">
                    {each.title}
                  </Button>
                ))
              : null}{" "}
            {item.activityType === "ClosingStatus" && item.to === "Arrived"
              ? null
              : item.to
              ? `To ${
                  item.activityType === "AppointmentDate"
                    ? formatDateAsPerTimeZOne(
                        item.to,
                        closingDetails.closingAddress?.timeZone
                      )
                    : item.to
                }`
              : null}{" "}
            {item.activityType === "ClosingAssigned" ? (
              <>To {item._agent?.name?.full}</>
            ) : null}
            {item.activityType === "Documents" ? (
              <>{item._user?.name?.full}</>
            ) : null}
            {item.activityType === "LeftMessage" ? <>To Left Message </> : null}
            {item.text ? (
              item.activityType === "Notes" ? (
                <>
                  {" "}
                  To{" "}
                  <span
                    style={{
                      fontStyle: "italic",
                      marginLeft: 3,
                      marginRight: 5,
                    }}
                  >
                    "{item.text}"{" "}
                  </span>{" "}
                </>
              ) : null
            ) : null}{" "}
            | {formatDateHideYearIfSame(item.timeStamp)},{" "}
            {formatTime(item.timeStamp)}
          </ListGroupItem>
        );
      })
    );
  };

  _rowStyleFormat = (row) => {
    if (row?.isCreatedForAgent) {
      return { backgroundColor: "#eee" };
    } else if (row?._agent) {
      return { backgroundColor: "#cfe2ff" };
    }
    return null;
  };

  _onChipSelect = (chips, arrName = "chips") => {
    let isValid = chips.length
      ? this._isValidEmail(chips[chips.length - 1])
      : true;
    if (isValid) {
      this.setState({
        formFields: {
          ...this.state.formFields,
          personOfContactEmail: {
            ...this.state.formFields.personOfContactEmail,
            value: chips,
          },
        },
      });
    }
  };

  _isValidEmail = (value) => {
    if (RegexConfig.email.test(String(value).toLowerCase())) {
      return true;
    } else {
      showToast("Invalid Email", "error");

      return false;
    }
  };

  _onChangeFormFieldBorrower = (fieldName, value, subField, index) => {
    const { formFields } = deepClone(this.state);
    if (fieldName === "borrower") {
      // formFields[fieldName][index][subField].value = value;
      if (subField === "workPhone" || subField === "alternatePhone") {
        formFields[fieldName][index][subField].value = value.replace(/\D/g, "");
      } else {
        formFields[fieldName][index][subField].value = value;
      }
    }
    this.setState({ formFields });
  };

  _toggleEditOrderDetailsModal = (isOpen = false, data = null) => {
    this.setState({
      editOrderDetailsModal: {
        isOpen,
        data,
      },
    });
  };

  _isNumber = (e) => {
    var charCode = e.which ? e.which : e.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57))
      return e.preventDefault();
    return;
  };

  render() {
    const {
      closingDetails,
      loading,
      signerAvailabilityModal,
      signingStatusModal,
      documentViewModalData,
      editMode,
      formFields,
      closingExpenses,
      closingExpensesTotalAmount,
      editOrderDetailsModal,
      seed,
      downloadInvoiceModal,
      clients,
    } = this.state;
    console.log("state", this.state);
    return (
      <div id="scroll">
        <div className="content">
          {closingDetails && (
            <>
              <div className="responsiveTitle">
                <div className="d-flex align-items-center">
                  <Button
                    color="link"
                    className="backBtn"
                    onClick={() => this.props.history.push("/agent/dashboard")}
                  >
                    <img
                      src={require("../../../assets/img/arrowLeft.svg").default}
                      alt="backbutton"
                      height={14}
                    />
                  </Button>
                  <h2>
                    Order Details
                    <span>
                      Order No #{closingDetails.closingNumber || "N/A"}
                    </span>
                  </h2>
                </div>

                <div className="rightSide">
                  <Button
                    color="primary"
                    onClick={() =>
                      HeaderEventEmitter.dispatch("download-invoice")
                    }
                    disabled={
                      closingDetails?.status === "Cancelled" ||
                      closingDetails?.status === "CCA" ||
                      closingDetails?.status === "Arrived"
                    }
                  >
                    Invoice
                  </Button>
                  {this.props.userData.user.id ===
                  closingDetails?._createdBy?.id ? (
                    <Button
                      color="primary"
                      onClick={() =>
                        this.props.history.push(
                          `/agent/create-closing?${closingDetails.id}`
                        )
                      }
                    >
                      Clone
                    </Button>
                  ) : null}
                </div>
              </div>

              <div className="CardWrapper">
                <div className="CardTitle">
                  <h2 className="sectionTtile">
                    {" "}
                    Appointment{" "}
                    {closingDetails?.status !== "Closed" &&
                    closingDetails?.status !== "DNC" ? (
                      // only open if closing is create by Agent
                      closingDetails?._createdBy?._id ===
                        this.props.userData.user.id ||
                      // only open if closing is created for Agent
                      closingDetails?.isCreatedForAgent ? (
                        <>
                          <Button
                            color="link"
                            onClick={() =>
                              this._toggleEditOrderDetailsModal(
                                true,
                                closingDetails
                              )
                            }
                          >
                            <img
                              src={
                                require("../../../assets/img/pencil.svg")
                                  .default
                              }
                              alt="edit"
                            />
                          </Button>
                        </>
                      ) : null
                    ) : null}
                  </h2>
                </div>
              </div>

              <SignerAppointment
                closingDetails={closingDetails}
                updateClosingDetailsByIdStatus={(closingDetails, status) =>
                  this._updateClosingDetailsByIdStatus(closingDetails, status)
                }
                userType="agent"
              />

              <Row>
                <Col md={6} className="mb-4 mb-lg-0"></Col>
              </Row>

              <Row className="mt-4 mb-5">
                <Col xl={6} lg={6}>
                  <div className="CardWrapper h-100">
                    <div className="CardTitle">
                      <h2 className="sectionTtile"> Expense Details </h2>
                    </div>
                    {console.log("Hello")}
                    <Card className="h-100 mb-0">
                      <Suspense fallback={<></>}>
                        <MilageExpanceTabs
                          key={seed}
                          closingDetails={closingDetails}
                          closingExpenses={closingExpenses}
                          closingExpensesTotalAmount={
                            closingExpensesTotalAmount
                          }
                          feesOffered={formFields?.agentFee?.value}
                          addAgentExpenses={this._addAgentExpenses}
                          getAllAgentExpensesForClosing={
                            this._getAllAgentExpensesForClosing
                          }
                          mileageUpdate={(mileage) => {
                            const { closingDetails } = deepClone(this.state);
                            closingDetails["_mileage"] = mileage;
                            this.setState({ closingDetails });
                          }}
                          loadingData={this.props.loadingData}
                          // getClosingDetailsById={this._getClosingDetailsById}
                        />
                      </Suspense>
                    </Card>
                  </div>
                </Col>
                <Col xl={6} lg={6} className="mb-5 mb-lg-0">
                  <div className="CardWrapper h-100 mt-5 mt-lg-0">
                    <div className="CardTitle">
                      <h2 className="sectionTtile">Secure Documents </h2>
                    </div>
                    <Suspense fallback={<></>}>
                      <SecureDocumentCardComponent
                        closingDetails={closingDetails}
                        updateClosingState={(closingDetails) =>
                          this._updateClosingState(closingDetails)
                        }
                        loadingData={this.props.loadingData}
                      />
                    </Suspense>
                  </div>
                </Col>
              </Row>

              {/* chat log */}
              <Suspense fallback={<></>}>
                <ChatLogCardComponent
                  ref={this.child}
                  closingDetails={closingDetails}
                />
              </Suspense>

              {/* instructions */}
              <Suspense fallback={<></>}>
                <div className="CardWrapper">
                  <div className="CardTitle">
                    {console.log("editMode", editMode)}
                    <h2 className="sectionTtile">
                      Instructions
                      {closingDetails.isCreatedForAgent ? (
                        <div>
                          {editMode === "instructions" ? (
                            <>
                              <Button
                                color="link"
                                disabled={loading.cardUpdateLoading}
                                onClick={() =>
                                  this._onSaveClosing("instructions")
                                }
                              >
                                {loading.cardUpdateLoading ? (
                                  <i className="fa fa-spinner fa-spin" />
                                ) : null}{" "}
                                <img
                                  src={
                                    require("../../../assets/img/checkBlueIcon.svg")
                                      .default
                                  }
                                  alt="save"
                                />
                              </Button>
                              <Button
                                color="link"
                                onClick={() => this._onToggleEditMode()}
                              >
                                <img
                                  src={
                                    require("../../../assets/img/close.svg")
                                      .default
                                  }
                                  height={14}
                                  alt="save"
                                />
                              </Button>
                            </>
                          ) : (
                            <Button
                              color="link"
                              onClick={() =>
                                this._onToggleEditMode("instructions")
                              }
                            >
                              <img
                                src={
                                  require("../../../assets/img/pencil.svg")
                                    .default
                                }
                                alt="pencil"
                              />
                            </Button>
                          )}
                        </div>
                      ) : null}
                    </h2>
                  </div>

                  <Suspense fallback={<></>}>
                    <Instructions
                      instructionData={formFields.instructions.value}
                      onChangeFormField={this._onChangeFormField}
                      // signingInstruction={signingInstruction}
                      instructionIdData={formFields?.instructionId?.value}
                      closingDetails={closingDetails}
                      isScanBackNeededData={closingDetails.isScanBackNeeded}
                      hideInstructionId={true}
                      scanbackForDetailPage={true}
                      disableScanback={true}
                      userType="agent"
                    />
                  </Suspense>
                </div>
              </Suspense>
            </>
          )}
          {/* modal to view document */}
          <Suspense fallback={<></>}>
            <DocumentViewModal
              isOpen={documentViewModalData.isOpen}
              externalUrl={documentViewModalData.externalUrl}
              fileType={documentViewModalData.fileType}
              modalHeading={documentViewModalData.modalHeading}
              toggle={this._onToggleDocumentViewModal}
            />
          </Suspense>

          {/* change signing status  */}
          <Suspense fallback={<></>}>
            {signingStatusModal.isOpen ? (
              <SigningStatusModal
                isOpen={signingStatusModal.isOpen}
                data={signingStatusModal.data}
                status={signingStatusModal.status}
                updateClosingDetailsById={this._updateClosingDetailsById}
                resetDetails={this._getClosingDetailsById}
                toggle={this._onToggleSigningStatusModal}
              />
            ) : null}
          </Suspense>

          {/* appointment availability modal  */}
          <Suspense fallback={<></>}>
            <SignerAvailabilityModal
              isOpen={signerAvailabilityModal.isOpen}
              data={signerAvailabilityModal.data}
              updateClosingDetailsById={this._updateClosingDetailsById}
              resetDetails={this._getClosingDetailsById}
              toggle={this._onToggleSignerAvailabilityModal}
              refeshChatLog={() =>
                this.child.current._getAllComments({
                  closingId: closingDetails._id,
                })
              }
            />
          </Suspense>

          <Suspense fallback={<></>}>
            <EditOrderDetailsModal
              isOpen={editOrderDetailsModal.isOpen}
              data={editOrderDetailsModal.data}
              toggle={() => this._toggleEditOrderDetailsModal()}
              updateClosingDetailsById={this._updateClosingDetailsById}
              resetDetails={this._getClosingDetailsById}
              clients={clients}
              loanType={loanTypeConfig}
              forAgent={true}
            />
          </Suspense>

          <Suspense fallback={<></>}>
            <DownloadInvoiceModal
              isOpen={downloadInvoiceModal?.isOpen}
              data={downloadInvoiceModal?.data}
              toggle={this._onToggleDownloadInvoiceModal}
              type="agent"
              onSuccess={this._getClosingDetailsById}
            />
          </Suspense>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
    loadingData: state.loaderData ? state.loaderData : {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(ClosingDetails);
